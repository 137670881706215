
  import { Component, Watch, Prop, Vue } from 'vue-property-decorator';
  import { Getter, Action } from 'vuex-class';

  import Select from '../../types/app/generic/select';
  import Endpoint from '../../utils/endpoint';
  import Entity from '@/src/types/entities/entity';

  @Component({})
  export default class EntityTaxElectionComponent extends Vue {
    endpoint = new Endpoint();

    @Getter('getOriginalEntity', { namespace: 'entity' }) originalEntity!: Entity;
    @Getter('getEntity', { namespace: 'entity' }) entity!: Entity;

    @Prop({ type: Array })
    rules;

    @Prop({ type: Array })
    dateRules;

    taxElections: Array<Select> = [];

    async created() {
      await this.getEntityTaxElectionsAsync();
    }

    get originalStartDate(): string | null {
      if (this.originalEntity.entityTaxElection.effectiveStartDate !== null) {
        const dt = new Date(this.originalEntity.entityTaxElection.effectiveStartDate);
        return this.$formatDate(dt);
      }

      return null;
    }

    get startDate(): string | null {
      return this.$formatDate(this.entity.entityTaxElection.effectiveStartDate);
    }
    set startDate(value: string | null) {
      this.entity.entityTaxElection.effectiveStartDate = this.$parseDate(value);
    }

    async getEntityTaxElectionsAsync(): Promise<void> {
      const response = await this.$http.get(`${this.endpoint.general.getByTable}/taxelections/id/txname`);

      if (response.status === 200) {
        this.taxElections = response.data;
      }
    }
  }
