import { RouteConfig } from 'vue-router';
import Required from '../utils/required';

import Common from '../views/Chart/Common.vue';

const required = new Required();

const generalRoutes: Array<RouteConfig> = [
  {
    path: '/org-chart',
    name: 'OrgChart',
    meta: { parent: 'Org Chart', create: 'create' },
    component: Common,
    beforeEnter: required.requireAuth
  },
  {
    path: '/institutional',
    name: 'Institutional',
    meta: { parent: 'Org Chart', create: 'create' },
    component: Common,
    beforeEnter: required.requireAuth
  },
  {
    path: '/lc-us',
    name: 'LCUS',
    meta: { parent: 'Org Chart', create: 'create' },
    component: Common,
    beforeEnter: required.requireAuth
  },
  {
    path: '/lvg',
    name: 'LGV',
    meta: { parent: 'Org Chart', create: 'create' },
    component: Common,
    beforeEnter: required.requireAuth
  },
  {
    path: '/lpe',
    name: 'LPE',
    meta: { parent: 'Org Chart', create: 'create' },
    component: Common,
    beforeEnter: required.requireAuth
  },
  {
    path: '/leof',
    name: 'LEOF',
    meta: { parent: 'Org Chart', create: 'create' },
    component: Common,
    beforeEnter: required.requireAuth
  },
  {
    path: '/lrebr',
    name: 'LREBR',
    meta: { parent: 'Org Chart', create: 'create' },
    component: Common,
    beforeEnter: required.requireAuth
  },
  {
    path: '/llf',
    name: 'LLF',
    meta: { parent: 'Org Chart', create: 'create' },
    component: Common,
    beforeEnter: required.requireAuth
  },
  {
    path: '/lreus',
    name: 'LREUS',
    meta: { parent: 'Org Chart', create: 'create' },
    component: Common,
    beforeEnter: required.requireAuth
  },
  {
    path: '/linfra',
    name: 'LINFRA',
    meta: { parent: 'Org Chart', create: 'create' },
    component: Common,
    beforeEnter: required.requireAuth
  },
  {
    path: '/lcbr',
    name: 'LCBR',
    meta: { parent: 'Org Chart', create: 'create' },
    component: Common,
    beforeEnter: required.requireAuth
  },
  {
    path: '/lgi',
    name: 'LGI',
    meta: { parent: 'Org Chart', create: 'create' },
    component: Common,
    beforeEnter: required.requireAuth
  },
  {
    path: '/lfs',
    name: 'LFS',
    meta: { parent: 'Org Chart', create: 'create' },
    component: Common,
    beforeEnter: required.requireAuth
  },
  {
    path: '/lh',
    name: 'LH',
    meta: { parent: 'Org Chart', create: 'create' },
    component: Common,
    beforeEnter: required.requireAuth
  },
  {
    path: '/lsv',
    name: 'LSV',
    meta: { parent: 'Org Chart', create: 'create' },
    component: Common,
    beforeEnter: required.requireAuth
  },
  {
    path: '/lwm',
    name: 'LWM',
    meta: { parent: 'Org Chart', create: 'create' },
    component: Common,
    beforeEnter: required.requireAuth
  },
  {
    path: '/lra',
    name: 'LRA',
    meta: { parent: 'Org Chart', create: 'create' },
    component: Common,
    beforeEnter: required.requireAuth
  },
  {
    path: '/lcm',
    name: 'LCM',
    meta: { parent: 'Org Chart', create: 'create' },
    component: Common,
    beforeEnter: required.requireAuth
  }
];

export default generalRoutes;
