import { PluginObject, VueConstructor } from 'vue';
import axios, { AxiosInstance } from 'axios';
import { AuthService } from '../services/authService';

export default class Http implements PluginObject<void> {
  public readonly instance: AxiosInstance;
  authService = new AuthService();

  public constructor() {
    this.instance = axios.create();

    this.instance.interceptors.request.use(async resp => {
      const token = await this.authService.getTokenForRequiredAsync();

      if (token !== null) {
        if (token != null) {
          resp.headers['Authorization'] = `Bearer ${token}`;
        }
      }

      return resp;
    });

    this.instance.interceptors.response.use(
      async response => {
        return response;
      },
      async error => {
        console.log('resp', error);
        if (error.response.status !== 401) {
          return new Promise((resolve, reject) => {
            reject(error.response);
          });
        }

        const config = error.config;
        const token = await this.authService.getTokenForRequiredAsync();
        if (token !== null) {
          config.headers['Authorization'] = `Bearer ${token}`;

          return await axios.request(config);
        }
      }
    );
  }

  public install(Vue: VueConstructor) {
    Vue.prototype.$http = this.instance;
  }
}

declare module 'vue/types/vue' {
  interface Vue {
    $http: AxiosInstance;
  }
}
