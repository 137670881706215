
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { Getter, Action } from 'vuex-class';

@Component({})
export default class ModalDeleteSubTask extends Vue {
  @Getter('getDeleteSubTaskModal', { namespace: 'checklist' })
  deleteSubTask!: boolean;
  @Action('setDeleteSubTaskModal', { namespace: 'checklist' })
  setDeleteSubTaskModal!: any;

  @Getter('getSubTaskName', { namespace: 'checklist' })
  subTaskName!: boolean;
  @Action('setSubTaskName', { namespace: 'checklist' })
  setSubTaskName!: any;

  @Getter('getSubTaskKey', { namespace: 'checklist' })
  subTaskKey!: boolean;
  @Action('setSubTaskKey', { namespace: 'checklist' })
  setSubTaskKey!: any;

  @Getter('getChecklistEntityStageKey', { namespace: 'checklist' })
  checklistEntityStageKey!: string;

  @Prop(Boolean) readonly loading: boolean | undefined;

  @Watch('loading')
  handleReloadSubtaskData() {
    if (this.loading === false) {
      this.$emit('reloadSubtaskData');
    }
  }

  putDeleteSubTask() {
    this.$emit('putDeleteSubTask');
  }

  closeModal() {
    this.$emit('closeModal');
  }
}
