
  import { Component, Vue, Watch } from 'vue-property-decorator';
  import qs from 'qs';
  import { Getter, Action } from 'vuex-class';

  import Endpoint from '../../utils/endpoint';
  import Auth from '../../utils/auth';

  import Chart from '../../components/organization/Chart.vue';

  import Select from '@/src/types/app/generic/select';
  import Node from '@/src/types/organization/node';

  import { ConfigRelation } from '../../utils/configRelation';
  import NodeData from '@/src/types/organization/nodeData';
  import LinkData from '@/src/types/organization/linkData';
  import Area from '@/src/types/auth/area';

  @Component({
    components: {
      Chart: Chart
    }
  })
  export default class ChartCommon extends Vue {
    endpoint = new Endpoint();

    //@Getter('canCreate', { namespace: 'auth' }) canCreate!: boolean;
    @Getter('canCreate', { namespace: 'auth' }) canCreate!: boolean;
    @Getter('getAreas', { namespace: 'auth' }) areas!: Area[];
    @Getter('getFullScreen', { namespace: 'app' }) fullScreen!: boolean;
    @Getter('getNode', { namespace: 'organization' }) node!: Node;
    @Getter('getNodeData', { namespace: 'organization' }) nodeData!: NodeData[];
    @Getter('getLinkData', { namespace: 'organization' }) linkData!: LinkData[];

    @Action('setSnackbar', { namespace: 'app' }) setSnackbar!: any;
    @Action('setOverlay', { namespace: 'app' }) setOverlay!: any;
    @Action('setChartDataEmpty', { namespace: 'organization' }) setChartDataEmpty!: any;
    @Action('setNodeAndLinkDataEmpty', { namespace: 'organization' }) setNodeAndLinkDataEmpty!: any;
    @Action('setNode', { namespace: 'organization' }) setNode!: any;
    @Action('addNodeData', { namespace: 'organization' }) addNodeData!: any;
    @Action('addLinkData', { namespace: 'organization' }) addLinkData!: any;
    @Action('renewLinkData', { namespace: 'organization' }) renewLinkData!: any;
    @Action('removeNode', { namespace: 'organization' }) removeNode!: any;

    @Watch('$route.name')
    private async commonRoute(value) {
      this.filter = {
        skip: 0,
        take: 1000,
        ignoreNull: true,
        flgExternal: null,
        entityId: null,
        businessUnityIds: [],
        includeParent: true
      };

      //this.validatePermission();
      await this.initialize();
    }

    async created() {
      //this.validatePermission();
      await this.initialize();
    }

    async initialize(): Promise<void> {
      this.setOverlay(false);
      await this.getBusinessUnitisAsync();
      await this.getEntityTypesAsync();
      await this.getEntityRelationAsync();
      await this.getIndividualRelations();
      await this.getFilterAsync();
    }

    get lg(): string {
      return this.canCreate ? '9' : '12';
    }

    get xl(): string {
      return this.canCreate ? '10' : '12';
    }

    // canCreate = false;
    entitiesBusiness: Array<Select> = [];
    entityTypes: Array<Select> = [];
    entities: Array<Select> = [];
    entityRelations: Array<Select> = [];
    individualRelations: Array<Select> = [];

    grouper: any = {
      entityTypeIds: [] as Array<any>,
      entityRelationClassificationIds: [] as Array<any>
    };

    loadingBusiness = true;
    loadingEntityTypes = true;
    loadingEntities = false;
    loadingEntityRelations = true;
    loadingIndividualRelations = true;
    savingFilter = false;

    dialog = false;

    filterEmployee = {
      entityIds: [] as Array<any>,
      individualRelationIds: [] as Array<any>
    };

    filter = {
      skip: 0,
      take: 1000,
      entityId: null,
      ignoreNull: true,
      flgExternal: null,
      businessUnityIds: [],
      includeParent: true
    };

    // validatePermission(): void {
    //   if (this.areas != null) {
    //     setTimeout(() => {
    //       this.canCreate = Auth.verifyPermission(
    //         this.areas,
    //         this.$router.currentRoute.meta.parent,
    //         this.$router.currentRoute.name ?? '',
    //         'Create'
    //       );
    //       console.log('validando...', this.canCreate);
    //     }, 300);
    //   } else {
    //     this.canCreate = false;
    //   }
    // }

    //#region Business Units
    async getBusinessUnitisAsync(): Promise<void> {
      const response = await this.$http.get(`${this.endpoint.common.getByTable}/businessunities/id/txname`);

      if (response.status === 200) {
        this.entitiesBusiness = response.data;

        console.log('rota', this.$route.name);

        this.loadingBusiness = false;
      }
    }
    //#endregion

    //#region Entity Types
    get allEntityTypes(): boolean {
      return this.grouper.entityTypeIds.length === this.entityTypes.length;
    }

    get someEntityTypes(): boolean {
      return this.grouper.entityTypeIds.length > 0 && !this.allEntityTypes;
    }

    get iconEntityTypes(): string {
      if (this.allEntityTypes) return 'mdi-close-box';
      if (this.someEntityTypes) return 'mdi-minus-box';
      return 'mdi-checkbox-blank-outline';
    }

    async toggleEntityTypes(): Promise<void> {
      if (this.allEntityTypes) {
        this.grouper.entityTypeIds = [];
      } else {
        this.grouper.entityTypeIds = this.entityTypes.map(x => x.value).slice();
      }

      await this.getEntitiesAsync();
    }

    async getEntityTypesAsync(): Promise<void> {
      const response = await this.$http.get(`${this.endpoint.organization.getEntityTypes}`);

      if (response.status == 200) {
        this.entityTypes = [];

        response.data.forEach(entityType => {
          this.entityTypes.push({
            text: entityType.text,
            value: entityType.value
          });
        });

        this.grouper.entityTypeIds = this.entityTypes.map(x => x.value).slice();
      }

      this.loadingEntityTypes = false;
    }
    //#endregion

    //#region  Relations
    get allRelations(): boolean {
      return this.grouper.entityRelationClassificationIds.length === this.entityRelations.length;
    }

    get someRelations(): boolean {
      return this.grouper.entityRelationClassificationIds.length > 0 && !this.allRelations;
    }

    get iconRelations(): string {
      if (this.allRelations) return 'mdi-close-box';
      if (this.someRelations) return 'mdi-minus-box';
      return 'mdi-checkbox-blank-outline';
    }

    async toggleRelations(): Promise<void> {
      if (this.allRelations) {
        this.grouper.entityRelationClassificationIds = [];
      } else {
        this.grouper.entityRelationClassificationIds = this.entityRelations.map(x => x.value);
      }
    }
    //#endregion

    //#region  Entities
    async getEntitiesAsync(): Promise<void> {
      this.loadingEntities = true;

      this.cleanEntity();

      const response = await this.$http.get(`${this.endpoint.filter.entities}/?${qs.stringify(this.filter)}`);

      if (response !== null && response.data.data !== null) {
        this.entities = [];
        response.data.data.forEach(entity => {
          this.entities.push({
            text: entity.shortName,
            value: entity.id
          });
        });
      }

      this.loadingEntities = false;
    }

    async getEntityRelationAsync(): Promise<void> {
      const response = await this.$http.get(`${this.endpoint.organization.getRelationClassifications}`);

      if (response.status == 200) {
        this.entityRelations = [];
        response.data.forEach(relationClassification => {
          this.grouper.entityRelationClassificationIds.push(relationClassification.value);
          this.entityRelations.push({
            text: relationClassification.text,
            value: relationClassification.value,
            disabled: false
          });
        });
      }

      this.loadingEntityRelations = false;
    }
    //#endregion

    //#region Individual Relations
    get allIndividualRelations(): boolean {
      return this.filterEmployee.individualRelationIds.length === this.individualRelations.length;
    }

    get someIndividualEntityRelationClassifications(): boolean {
      return this.filterEmployee.individualRelationIds.length > 0 && !this.allIndividualRelations;
    }

    get iconIndividualEntityRelationClassifications(): string {
      if (this.allIndividualRelations) return 'mdi-close-box';
      if (this.someIndividualEntityRelationClassifications) return 'mdi-minus-box';
      return 'mdi-checkbox-blank-outline';
    }

    async toggleIndividualEntityRelationClassifications(): Promise<void> {
      if (this.allIndividualRelations) {
        this.filterEmployee.individualRelationIds = [];
      } else {
        this.filterEmployee.individualRelationIds = this.individualRelations.map(x => x.value);
      }
    }
    //#endregion

    async loadData(): Promise<void> {
      if (this.filter.entityId) {
        await this.getDataAsync();
      } else if (this.filter.businessUnityIds) {
        await this.getDataByBuAsync();
      }
    }

    async getDataByBuAsync(): Promise<void> {
      this.setOverlay(true);

      const response = await this.$http.get(
        `${this.endpoint.organization.getOrganizationsByBu}/${this.filter.businessUnityIds}/${this.filter.includeParent}`
      );

      if (response.status === 200) {
        this.setChartDataEmpty();
        this.setNodeAndLinkDataEmpty();

        response.data.forEach((node: Node) => {
          if (this.grouper.entityTypeIds.some(x => x === node.entityTypeId)) {
            if (!this.nodeData.some(x => x.key === node.key)) {
              this.addNodeData({
                key: node.key,
                text: node.name,
                type: 'e',
                color: '#013767',
                nodeInfo: node.nodeInfo
              });
            }

            if (node.childreen && node.childreen.length > 0) {
              node.childreen.forEach((child: Node) => {
                this.loadChartData(child);
              });
            }

            if (node.parents) {
              node.parents.forEach((parent: Node) => {
                this.loadParent(parent);
              });
            }
          }
        });

        if (this.filterEmployee.individualRelationIds.length > 0) {
          await this.getEmployees();
        }
      }

      (this.$refs['refChart'] as any).loadChart();
      this.setOverlay(false);
    }

    async getDataAsync(): Promise<void> {
      this.setOverlay(true);

      const resp: any = await this.$http.get(
        `${this.endpoint.organization.getOrganizations}/${this.filter.entityId}/${this.filter.includeParent}`
      );

      if (resp && resp.data) {
        this.setChartDataEmpty();
        this.setNodeAndLinkDataEmpty();

        if (!resp.data.hasStatusErrors) {
          if (this.grouper.entityTypeIds.some(x => x === resp.data.entityTypeId)) {
            this.setNode(resp.data);
            this.loadChartData(this.node);

            if (this.filterEmployee.individualRelationIds.length > 0) {
              await this.getEmployees();
            }
          } else {
            this.setOverlay(false);
          }

          (this.$refs['refChart'] as any).loadChart();
        } else {
          this.setOverlay(false);
          this.setSnackbar({
            show: true,
            message: `${resp.data.statusCode.item2} (${resp.data.statusCode.item1})`,
            color: 'warning'
          });
        }
      }
    }

    async getIndividualRelations(): Promise<void> {
      const response = await this.$http.get(this.endpoint.select.getIndividualEntityRelations);
      this.individualRelations = [];
      this.individualRelations = response.data;

      this.loadingIndividualRelations = false;
    }

    async getEmployees(): Promise<void> {
      this.filterEmployee.entityIds = [...new Set(this.nodeData.map(x => x.key))];

      let param = '';

      if (this.filterEmployee.entityIds.length > 0) {
        this.filterEmployee.entityIds.forEach((id, index) => {
          if (index === 0) {
            param += `entityIds=${id}`;
          } else {
            param += `&entityIds=${id}`;
          }
        });
      }

      if (this.filterEmployee.individualRelationIds.length > 0) {
        this.filterEmployee.individualRelationIds.forEach((id, index) => {
          if (index === 0 && param === '') {
            param += `individualRelationIds=${id}`;
          } else {
            param += `&individualRelationIds=${id}`;
          }
        });
      }

      const response = await this.$http.get(`${this.endpoint.organization.getEmployeesByEntity}?${param}`);

      if (response.data && response.data.length > 0) {
        response.data.forEach((node: Node) => {
          if (!this.nodeData.some(x => x.key == node.key)) {
            this.addNodeData({
              key: node.key,
              text: node.name,
              color: '#0874d4',
              nodeInfo: node.nodeInfo
            });
          }

          const linkData = {
            from: node.partner ? node.key : node.parentKey,
            to: node.partner ? node.parentKey : node.key,
            relationClassificationId: node.entityRelationClassificationId,
            dash: ConfigRelation.GetConfigProp(`${node.entityRelationClassificationId}_dash`),
            color: ConfigRelation.GetConfigProp(`${node.entityRelationClassificationId}_color`)
          };

          this.renewLinkData(linkData);
        });
      }

      this.setOverlay(false);
    }

    async saveFilterAsync(): Promise<void> {
      this.savingFilter = true;

      const { businessUnityIds } = this.filter;

      const response = await this.$http.post(this.endpoint.filter.createFilter, {
        name: this.$route.name,
        businessUnityIds,
        entityRelationClassificationIds: this.grouper.entityRelationClassificationIds,
        individualEntityRelationClassificationIds: this.filterEmployee.individualRelationIds,
        entityTypeIds: this.grouper.entityTypeIds,
        entityId: this.filter.entityId
      });

      this.savingFilter = false;

      this.setSnackbar({
        show: true,
        message: 'Filter saved with success',
        color: 'success'
      });
    }

    async getFilterAsync(): Promise<void> {
      this.savingFilter = true;
      this.setNodeAndLinkDataEmpty();
      this.setChartDataEmpty();

      (this.$refs['refChart'] as any).loadChart();

      const resp = await this.$http.get(`${this.endpoint.filter.getFilter}/${this.$route.name}`);

      if (resp.status == 200 && resp.data) {
        await this.getEntitiesAsync();

        this.filter.businessUnityIds = resp.data.businessUnityIds;
        this.grouper.entityRelationClassificationIds = resp.data.entityRelationClassificationIds;
        this.grouper.entityTypeIds = resp.data.entityTypeIds;
        this.filter.entityId = resp.data.entityId;

        this.filterEmployee.individualRelationIds = [...new Set(resp.data.individualEntityRelationClassificationIds)];

        await this.loadData();
      } else {
        this.cleanFilter();
      }

      this.savingFilter = false;
    }

    loadChartData(node: Node): void {
      if (
        (this.grouper.entityTypeIds.some(x => x === node.entityTypeId) &&
          this.grouper.entityRelationClassificationIds.some(x => x === node.entityRelationClassificationId)) ||
        !node.entityRelationClassificationId
      ) {
        if (!this.nodeData.some(x => x.key === node.key)) {
          this.addNodeData({
            key: node.key,
            text: node.name,
            type: 'e',
            color: '#013767',
            nodeInfo: node.nodeInfo,
            relationClassificationId: node.entityRelationClassificationId
          });
        }

        if (node.parents) {
          node.parents.forEach(parent => {
            this.renewLinkData({
              from: parent.key,
              to: node.key,
              text: null,
              entityTypeId: node.entityTypeId,
              relationClassificationId: node.entityRelationClassificationId,
              dash: ConfigRelation.GetConfigProp(`${node.entityRelationClassificationId}_dash`),
              color: ConfigRelation.GetConfigProp(`${node.entityRelationClassificationId}_color`),
              nodeInfo: node.nodeInfo
            });

            this.loadParent(parent);
          });
        } else if (node.parentKey) {
          const linkData = {
            from: node.partner ? node.key : node.parentKey,
            to: node.partner ? node.parentKey : node.key,
            relationClassificationId: node.entityRelationClassificationId,
            dash: ConfigRelation.GetConfigProp(`${node.entityRelationClassificationId}_dash`),
            color: ConfigRelation.GetConfigProp(`${node.entityRelationClassificationId}_color`),
            nodeInfo: node.nodeInfo
          };

          this.renewLinkData(linkData);
        }

        if (node.childreen && node.childreen.length > 0) {
          node.childreen.forEach((child: Node) => {
            this.loadChartData(child);
          });
        }
      }

      this.disabledRelations(false);
      this.setOverlay(false);
    }

    loadParent(parent: Node) {
      this.loadChartData(parent);

      if (parent.parents) {
        parent.parents.forEach(father => {
          this.loadParent(father);
        });
      }
    }

    loadDataByRelation(): void {
      this.setOverlay(true);
      this.disabledRelations(true);

      setTimeout(() => {
        this.setNodeAndLinkDataEmpty();
        this.filterEmployee.individualRelationIds = [];

        this.loadChartData(this.node);
      }, 500);
    }

    disabledRelations(disabled: boolean): void {
      this.entityRelations.forEach(rel => {
        rel.disabled = disabled;
      });
    }

    cleanEntity(): void {
      this.filter.entityId = null;
    }

    cleanSelect(): void {
      this.filter.businessUnityIds = [];
      // this.entityId = null;
      // this.entities = [];

      this.setChartDataEmpty();
      (this.$refs['refChart'] as any).loadChart();
    }

    cleanFilter(): void {
      this.filter = {
        skip: 0,
        take: 1000,
        ignoreNull: true,
        flgExternal: null,
        entityId: null,
        businessUnityIds: [],
        includeParent: true
      };
    }
  }
