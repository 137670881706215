export default interface SequenceSelected {
  id: string;
  name: string;
}

const sequenceSelectedInitialValue: SequenceSelected = {
  id: '',
  name: '',
};

export { sequenceSelectedInitialValue };
