
  import { Component, Ref, Vue } from 'vue-property-decorator';
  import { Getter } from 'vuex-class';

  import Endpoint from '../../utils/endpoint';

  import Select from '../../types/app/generic/select';
  import Individual from '@/src/types/individual/individual';

  @Component({})
  export default class PhoneComponent extends Vue {
    endpoint = new Endpoint();

    @Getter('getIndividual', { namespace: 'individual' }) individual!: Individual;
    @Getter('getOriginalIndividual', { namespace: 'individual' }) originalIndividual!: Individual;

    typePhones: Select[] = [];

    async created() {
      await this.getPhonesAsync();
    }

    async getPhonesAsync(): Promise<void> {
      const response = await this.$http.get(`${this.endpoint.common.getByTable}/phonetypes/id/txname`);

      if (response.status === 200) {
        this.typePhones = response.data;
      }
    }

    verifyEdition(index: number): void {
      if (
        this.individual.phones !== null &&
        this.originalIndividual.phones !== null &&
        this.individual.phones.length === this.originalIndividual.phones.length
      ) {
        this.individual.phones[index].phoneTypeId !== this.originalIndividual.phones[index].phoneTypeId;
      }
    }

    addPhone(): void {
      if (this.individual.phones === null) this.individual.phones = [];

      this.individual.phones.push({
        isEdited: false,
        phoneTypeId: '',
        number: '',
        extension: '',
        primary: this.individual.phones.length === 0
      });
    }

    removePhone(index: number): void {
      this.individual.phones.splice(index, 1);
    }

    setPhonePrimary(index: number) {
      for (let i = 0; i < this.individual.phones.length; i++) {
        this.individual.phones[i].primary = index === i;
      }
    }
  }
