
import { Component, Vue, Prop } from 'vue-property-decorator';
import { Getter, Action } from 'vuex-class';

import ChecklistInterface from '@/src/types/checklist/checklist';
import IntervalItems from '@/src/types/checklist/intervaltems';
import Header from '../../types/app/headers/header';
import ChecklistHeader from '../../types/app/headers/ChecklistHeader';
import InnerChecklistHeader from '../../types/app/headers/innerChecklistHeader';
import Entities from '../../types/checklist/entities';
import FormatDate from '../../utils/formatDate';

import ChecklistDisabledStatusModal from '../../components/checklist/Modais/ChecklistDisableStatusModal.vue';
import ChecklistEnabledStatusModal from '../../components/checklist/Modais/ChecklistEnableStatusModal.vue';
import IListCheckItem from '../../types/checklist/IListCheckItem';
import FeedbackInterface from '../../types/checklist/feedback';

import Endpoint from '../../utils/endpoint';
// import * as _ from 'lodash';
import { Watch } from 'vue-property-decorator';
import ChecklistTeams from '@/src/types/checklist/checklistTeams';
import ChecklistOwners from '@/src/types/checklist/checklistOwners';
import User from '../../types/auth/User';
import Errors from '../../types/checklist/errors/errors';

const ALL_INTERVAL = '';
const MONTHLY = 1;
const SEMESTER = 2;
const QUARTERLY = 3;
const YEARLY = 4;
const OTHER = 5;

@Component({
  components: {
    ChecklistDisabledStatusModal: ChecklistDisabledStatusModal,
    ChecklistEnabledStatusModal: ChecklistEnabledStatusModal,
  },
})
export default class Checklists extends Vue {
  endpoint = new Endpoint();
  headers: Array<Header> = ChecklistHeader.indexHeader;
  innerHeaders: Array<Header> = InnerChecklistHeader.innerIndexHeader;

  @Getter('getUser', { namespace: 'auth' }) user!: User;

  @Getter('getDisableModal', { namespace: 'checklist' })
  disableStatusModal!: boolean;
  @Action('setDisableModal', { namespace: 'checklist' }) setDisableModal!: any;

  @Getter('getEnableModal', { namespace: 'checklist' })
  enableStatusModal!: boolean;
  @Action('setEnableModal', { namespace: 'checklist' }) setEnableModal!: any;

  @Getter('getChecklistKeyId', { namespace: 'checklist' })
  checklistKeyId!: boolean;
  @Action('setChecklistKeyId', { namespace: 'checklist' })
  setChecklistKeyId!: any;

  @Getter('getFeedback', { namespace: 'checklist' })
  public feedback!: FeedbackInterface;
  @Action('setFeedback', { namespace: 'checklist' }) public setFeedback: any;

  @Watch('ownerSelected')
  @Watch('teamSelected')
  @Watch('intervalSelected')
  listChecklist() {
    this.listChecklistItem({
      interval: this.handleSetFilterValue(this.intervalSelected?.interval),
      owner: this.handleSetFilterValue(this.ownerSelected?.email),
      team: this.handleSetFilterValue(this.teamSelected?.name),
    });
  }

  search = '';
  items: ChecklistInterface[] = [];
  loading = false;
  expanded = [];
  singleExpand = true;
  isExpanded = false;
  tableExpanded = false;
  owners: ChecklistOwners[] = [];
  ownerSelected: ChecklistOwners = { name: '', email: '', department: '' };
  selectedItem = false;
  loadingStatus = false;

  teams: ChecklistTeams[] = [];
  teamSelected: ChecklistTeams = { name: '' };

  entitiesInner: ChecklistInterface[] = [];
  entityKeyStorage: ChecklistInterface[] = [];

  formatDate = FormatDate;

  showFilters = false;
  public intervalSelected: IntervalItems = {
    interval: '',
    number: '',
  };
  public intervalItems = [
    { interval: 'All', number: '' },
    { interval: 'Monthly', number: MONTHLY },
    { interval: 'Quarterly', number: QUARTERLY },
    { interval: 'Semester', number: SEMESTER },
    { interval: 'Yearly', number: YEARLY },
    { interval: 'Other', number: OTHER },
  ];

  public async listChecklistItem({ interval, owner, team }: IListCheckItem) {
    const params = {
      interval: interval === 'All' ? '' : interval,
      ownerKey: owner,
      teamKey: team,
      LoggedUserLogin: this.user.email,
    };
    this.loading = true;
    try {
      const checklist = await this.$http.get(this.endpoint.checklist.list, {
        params,
      });
      const items = checklist.data.data;
      this.items = items;
    } catch (error) {
      console.log('Ocorreu um erro ao listar checklists');
    }
    this.loading = false;
  }

  handleSetFilterValue(value: any) {
    return value === 0 || value === null ? '' : value;
  }

  async getTeams() {
    try {
      const response = await this.$http.get(this.endpoint.teams.list);
      const items = response.data.data;
      this.teams = items;
    } catch (error) {
      console.log('Erro em buscar o teams');
    }
  }

  async getOwners() {
    try {
      const response = await this.$http.get(this.endpoint.owners.list);
      const items = response.data.data;
      this.owners = items;
    } catch (error) {
      console.log('Erro em buscar os owners');
    }
  }

  getNameTeams(teamKey: string) {
    const team = this.teams.find((x) => x.name === teamKey) as ChecklistTeams;
    return team?.name;
  }

  getNameOwners(ownerKey: string) {
    const owner = this.owners.find(
      (x) => x.email === ownerKey
    ) as ChecklistOwners;
    return owner?.name;
  }

  goToEntities() {
    this.$router.push({
      path: '/entities',
    });
  }

  Detail(entity: Entities, checklist: ChecklistInterface) {
    this.$router.push({
      name: 'DetailChecklist',
      params: {
        id: checklist.checklistEntityKey,
      },
    });
  }

  public handleSetChecklistInner(entity: ChecklistInterface) {
    const entities = this.items.find(
      (item) => item.checklistKey === entity.checklistKey
    );
    this.entitiesInner = entities?.checklistEntities as any;
  }

  View(checklist: ChecklistInterface) {
    this.$router.push({
      name: 'ViewChecklist',
      params: { id: checklist.checklistKey },
    });
  }

  public async disableStatus(params: any) {
    this.loadingStatus = true;
    try {
      const response = await this.$http.put(
        this.endpoint.disable.status,
        params
      );
      if (response.data.success) this.listChecklist();
    } catch (error) {
      const responseError = error as Errors;
      console.log('Error no put do status Disable');
      this.setFeedback({
        feedback: true,
        color: 'red',
        text: responseError.data.errors[0].message,
      });
      setTimeout((): void => {
        this.setFeedback({
          feedback: false,
        });
      }, 6000);
    } finally {
      this.loadingStatus = false;
      this.closeModalDisable();
    }
  }

  closeModalDisable() {
    this.setDisableModal(false);
  }

  public showDisableModal() {
    this.setDisableModal(true);
  }

  public async enableStatus(params: any) {
    this.loadingStatus = true;
    try {
      const response = await this.$http.put(
        this.endpoint.enable.status,
        params
      );
      if (response.data.success) this.listChecklist();
    } catch (error) {
      const responseError = error as Errors;
      console.log('Error no put do status Enable');
      this.setFeedback({
        feedback: true,
        color: 'red',
        text: responseError.data.errors[0].message,
      });
      setTimeout((): void => {
        this.setFeedback({
          feedback: false,
        });
      }, 6000);
    }
    this.loadingStatus = false;
    this.closeModalEnable();
  }

  closeModalEnable() {
    this.setEnableModal(false);
  }

  public showEnableModal() {
    this.setEnableModal(true);
  }

  public selectItem(item: boolean) {
    this.selectedItem = item;
  }

  public unSelectItem() {
    this.selectedItem = false;
  }

  async mounted() {
    this.loading = true;
    await this.getTeams();
    this.getOwners();
    this.listChecklist();
  }
}
