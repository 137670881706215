
  import { Component, Prop, Vue } from 'vue-property-decorator';
  import { Getter } from 'vuex-class';

  import Endpoint from '../../../utils/endpoint';
  import SelectOther from '../../../types/app/generic/selectOther';
  import Entity from '@/src/types/entities/entity';

  @Component({})
  export default class EntityTypeComponent extends Vue {
    endpoint = new Endpoint();

    @Getter('getEntity', { namespace: 'entity' }) entity!: Entity;

    @Prop({ type: Array })
    rules;

    entityTypes: Array<SelectOther> = [];
    gettingEntityTypes = true;

    async created() {
      await this.getEntityTypeAsync();
    }

    get entityTypesFiltered(): Array<SelectOther> {
      const retVal: Array<SelectOther> = [];

      this.entityTypes.forEach(entityType => {
        retVal.push({
          text: entityType.flgExternal ? `${entityType.text} (ext)` : entityType.text,
          value: entityType.text,
          flgExternal: entityType.flgExternal
        });
      });

      console.log('externals', JSON.parse(JSON.stringify([...retVal.filter(x => x.flgExternal)])));

      return retVal;
    }

    async getEntityTypeAsync(): Promise<void> {
      const response = await this.$http.get(
        `${this.endpoint.general.getByTable}/entitytypes/id/txshortname/flgexternal`
      );

      if (response.status === 200) {
        this.entityTypes = [...response.data.filter(x => !x.flgExternal), ...response.data.filter(x => x.flgExternal)];
      }

      this.gettingEntityTypes = false;
    }
  }
