
  import { Component, Prop, Vue } from 'vue-property-decorator';

  @Component({})
  export default class RelationPercentComponent extends Vue {
    @Prop({ type: Object })
    entityRelation;

    @Prop({ type: Array })
    requiredRules;

    isPercentActive = false;

    get percent(): string | null {
      if (this.isPercentActive || !this.entityRelation.percent) {
        return `${this.entityRelation.percent}`;
      } else {
        return '% ' + this.entityRelation.percent.toFixed(0).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, '$1,');
      }
    }
    set percent(value: string | null) {
      if (!value) value = '0';

      let newValue = parseFloat(value.replace(/[^\d\\.]/g, ''));
      if (isNaN(newValue)) {
        newValue = 0;
      }

      this.entityRelation.percent = newValue > 100 ? 100 : newValue;
    }
  }
