import Header from './header';

export default class ChecklistHeader {
  public static indexHeader: Header[] = [
    {
      text: 'Entity',
      align: 'start',
      sortable: true,
      value: 'entitiyName',
      class: null,
      width: '12%'
    },
    {
      text: 'Entity Type',
      align: 'start',
      sortable: true,
      value: 'entityTypeName',
      class: null,
      width: '10%'
    },
    {
      text: 'Form Organization',
      align: 'start',
      sortable: true,
      value: 'formOrganizationName',
      class: null,
      width: '13%'
    },
    {
      text: 'Regulatory License',
      align: 'start',
      sortable: true,
      value: 'regulatoryLicenseName',
      class: null,
      width: '13%'
    },
    {
      text: 'Juridiction',
      align: 'start',
      sortable: true,
      value: 'jurisdictionsName',
      class: null,
      width: '10%'
    },
    {
      text: 'Checklists',
      align: 'start',
      value: 'totalChecklists',
      sortable: true,
      class: null,
      width: '8%'
    },
    {
    text: '',
    align: 'end',
    value: 'data-table-expand',
    sortable: false,
    class: null,
    width: '5%',
    },
  ];
}
