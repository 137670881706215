import LinkData from '@/src/types/organization/linkData';
import Node from '@/src/types/organization/node';
import NodeData from '@/src/types/organization/nodeData';
import NodeInfo from '@/src/types/organization/nodeInfo';
import { AppState } from './types';

export default {
  setNode(state: AppState, node: Node): void {
    state.node = node;
  },
  setNodeData(state: AppState, nodeData: NodeData[]): void {
    state.nodeData = nodeData;
  },
  setLinkData(state: AppState, linkData: LinkData[]): void {
    state.linkData = linkData;
  },
  addNodeData(state: AppState, nodeData: NodeData): void {
    state.nodeData.push(nodeData);
  },
  addLinkData(state: AppState, linkData: LinkData): void {
    state.linkData.push(linkData);
  },
  renewLinkData(state: AppState, linkData: LinkData): void {
    for (let i = 0; i < state.linkData.length; i++) {
      if (state.linkData[i].from === linkData.from && state.linkData[i].to === linkData.to) {
        state.linkData.splice(i, 1);
      }
    }

    state.linkData.push(linkData);
  },
  renewNodeData(state: AppState, nodeData: NodeData): void {
    for (let i = 0; i < state.nodeData.length; i++) {
      if (state.nodeData[i].key === nodeData.key) {
        state.nodeData.splice(i, 1);
      }
    }
    state.nodeData.push(nodeData);
  },
  removeNode(state: AppState, key: string) {
    for (let i = 0; i < state.nodeData.length; i++) {
      if (state.nodeData[i].key === key) {
        state.nodeData.splice(i, 1);
      }
    }
    for (let i = 0; i < state.linkData.length; i++) {
      if (state.linkData[i].from === key || state.linkData[i].to === key) {
        state.linkData.splice(i, 1);
      }
    }
  },
  setNodeInfo(state: AppState, nodeInfo: NodeInfo): void {
    state.nodeInfo = nodeInfo;
  }
};
