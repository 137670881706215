
import Vue from 'vue';
import Component from 'vue-class-component';
import { State, Action, Getter } from 'vuex-class';
import { TokenService } from '../../services/tokenService';
import { AuthService } from '../../services/authService';

import router from 'vue-router';

import Endpoint from '../../utils/endpoint';

@Component({})
export default class Login extends Vue {
  endpoint = new Endpoint();
  authService = new AuthService();

  @Action('setUser', { namespace: 'auth' }) setUser: any;
  @Action('setAreas', { namespace: 'auth' }) setAreas: any;

  dialog = false;
  loader = false;
  isLogged = false;

  async mounted() {
    await this.initialize();
  }

  async initialize(): Promise<void> {
    if (this.$route.name === 'Login') {
      try {
        const response = await this.authService.loginAsync();
        if (response !== null) {
          this.setUser(this.authService.getTokenData());
          this.isLogged = true;

          const redirectRoute = localStorage.getItem('route');

          (redirectRoute !== null || !!redirectRoute) &&
          (redirectRoute.includes('checklist') ||
            redirectRoute.includes('entities'))
            ? (window.location.href = redirectRoute)
            : (window.location.href = '/');
        } else {
          this.dialog = true;
        }
      } catch (error) {
        console.log('login: initialize()', error);
        this.setUser(null);
        if (error.errorCode === 'user_cancelled') {
          sessionStorage.clear();
          localStorage.clear();
          this.dialog = true;
        }
      }
    }
  }

  async tryLogin(): Promise<any> {
    try {
      const response = await this.authService.loginAsync();

      if (response !== null) {
        this.dialog = false;

        const userData = this.authService.getTokenData();
        this.setUser(userData);

        const redirectRoute = localStorage.getItem('route');

        (redirectRoute !== null || !!redirectRoute) &&
        (redirectRoute.includes('checklist') ||
          redirectRoute.includes('entities'))
          ? (window.location.href = redirectRoute)
          : (window.location.href = '/');
      } else {
        this.cancelLogin();
      }
    } catch (error) {
      console.log('login: tryLogin()', error);
      this.cancelLogin();
    }
  }

  cancelLogin(): void {
    this.dialog = false;
    sessionStorage.clear();
    localStorage.clear();
    this.$router.push({ name: 'Logout' });
  }
}
