import Header from './header';

export default class ChecklistHeaderDetails {
  public static indexHeaderDetails: Header[] = [
    {
      text: 'Name',
      align: 'start',
      sortable: true,
      value: 'name',
      class: null,
      width: '16%',
    },
    {
      text: 'Team',
      align: 'start',
      sortable: true,
      value: 'teamKey',
      class: null,
      width: '7%',
    },
    {
      text: 'Start at',
      align: 'start',
      value: 'startAt',
      sortable: true,
      class: null,
      width: '8%',
    },
    {
      text: 'Deadline',
      align: 'start',
      value: 'deadline',
      sortable: true,
      class: null,
      width: '8%',
    },
    {
      text: 'Expected deadline',
      align: 'start',
      value: 'expectedDate',
      sortable: true,
      class: null,
      width: '12%',
    },
    {
      text: 'Evidence',
      align: 'start',
      value: 'evidence',
      sortable: false,
      class: null,
      width: '6%',
    },
    {
      text: 'Predecessor',
      align: 'start',
      value: 'predecessor',
      sortable: false,
      class: null,
      width: '12%',
    },
    {
      text: 'Status',
      align: 'start',
      value: 'status',
      sortable: false,
      class: null,
      width: '8%',
    },
    {
      text: 'Actions',
      align: 'start',
      value: 'finished',
      sortable: false,
      class: null,
      width: '8%',
    },
    {
      text: 'Real Deadline',
      align: 'start',
      value: 'realDeadline',
      sortable: false,
      class: null,
      width: '10%',
    },
    {
      text: '',
      align: 'center',
      value: 'up',
      sortable: false,
      class: null,
      width: '3%',
    },
  ];
}
