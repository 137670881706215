
  import { Component, Ref, Vue } from 'vue-property-decorator';
  import { Getter } from 'vuex-class';

  import Endpoint from '../../utils/endpoint';

  import Entity from '@/src/types/entities/entity';
  import Select from '../../types/app/generic/select';

  @Component({})
  export default class EmailComponent extends Vue {
    endpoint = new Endpoint();
    @Getter('getEntity', { namespace: 'entity' }) entity!: Entity;

    typeEmails: Select[] = [];

    emailRules = [
      v => !!v || 'E-mail is required',
      v => /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(v) || 'E-mail must be valid'
    ];

    async created() {
      await this.getPhonesAsync();
    }

    async getPhonesAsync(): Promise<void> {
      const response = await this.$http.get(`${this.endpoint.common.getByTable}/emailtypes/id/txname`);

      if (response.status === 200) {
        this.typeEmails = response.data;
      }
    }

    addEmail(): void {
      if (this.entity.emails === null) this.entity.emails = [];

      this.entity.emails.push({
        typeEmailId: '',
        email: '',
        primary: this.entity.emails.length === 0
      });
    }

    removeEmail(index: number): void {
      this.entity.emails.splice(index, 1);
    }

    setEmailPrimary(index: number) {
      for (let i = 0; i < this.entity.emails.length; i++) {
        this.entity.emails[i].primary = index === i;
      }
    }
  }
