
  import { Component, Prop, Vue } from 'vue-property-decorator';
  import { Getter } from 'vuex-class';

  import Endpoint from '../../../utils/endpoint';
  import Entity from '@/src/types/entities/entity';
  import Select from '@/src/types/app/generic/select';

  @Component({})
  export default class CurrencyComponent extends Vue {
    endpoint = new Endpoint();

    @Getter('getEntity', { namespace: 'entity' }) entity!: Entity;

    @Prop({ type: Array })
    rules;

    currencys: Array<Select> = [];
    gettingCurrencys = true;

    async created() {
      await this.getEntityTypeAsync();
    }

    get currencysFiltered(): Array<Select> {
      const retVal: Array<Select> = [];

      this.currencys.forEach(currency => {
        retVal.push({
          text: currency.text,
          value: currency.text
        });
      });

      console.log('externals', JSON.parse(JSON.stringify([...retVal])));

      return retVal;
    }

    async getEntityTypeAsync(): Promise<void> {
      const response = await this.$http.get(`${this.endpoint.general.getByTable}/currency/id/txname`);

      if (response.status === 200) {
        this.currencys = [...response.data];
      }

      this.gettingCurrencys = false;
    }
  }
