import ChecklistIgnoredEntity from './checklistIgnoredEntity';
import ChecklistStage from './checklistStage';
import ChecklistTrigger from './checklistTrigger';

export default interface ChecklistPayLoad {
  id: number;
  checklistKey: string;
  name: string;
  teamKey: string;
  ownerKey: string;
  interval: string;
  active: boolean;
  startDate: '';
  realDeadLine: '';
  statusId: number;
  comment: string;
  userUpdatedLogin: string;
  checklistTriggers: ChecklistTrigger[];
  checklistStages: ChecklistStage[];
  checklistIgnoredEntities: ChecklistIgnoredEntity[];
}

const checklistPayLoadInitialValue: ChecklistPayLoad = {
  id: 0,
  checklistKey: '',
  name: '',
  teamKey: '',
  ownerKey: '',
  interval: '',
  active: true || false,
  startDate: '',
  realDeadLine: '',
  statusId: 0,
  comment: '',
  userUpdatedLogin: '',
  checklistTriggers: [],
  checklistStages: [],
  checklistIgnoredEntities: [],
};

export { checklistPayLoadInitialValue };
