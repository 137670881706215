
import Entity from '@/src/types/entities/entity';
import { Component, Ref, Vue } from 'vue-property-decorator';
import { Getter, Action } from 'vuex-class';

import Endpoint from '../../../utils/endpoint';

import EntityName from '../../../components/entities/EntityName.vue';
import RegulatoryLicenseType from '../../../components/entities/fields/RegulatoryLicenseType.vue';
import EntityUnityBusiness from '../../../components/entities/EntityUnityBusiness.vue';
import EntityStatus from '../../../components/entities/EntityStatus.vue';
import EntityOffice from '../../../components/entities/EntityOffice.vue';
import EntityPhone from '../../../components/entities/Phone.vue';
import EntityEmail from '../../../components/entities/Email.vue';
import TaxPayer from '../../../components/entities/TaxPayer.vue';
import EntityTaxElection from '../../../components/entities/EntityTaxElection.vue';
import EntityRelation from '../../../components/entities/EntityRelation.vue';
import IndividualRelation from '../../../components/entities/IndividualRelation.vue';

//fields
import Jurisdiction from '../../../components/entities/fields/Jurisdiction.vue';
import FormOrganization from '../../../components/entities/fields/FormOrganization.vue';
import LesteEntity from '@/src/types/entities/lesteEntity';
import EntityType from '../../../components/entities/fields/EntityType.vue';

import Giin from '../../../components/entities/fields/Giin.vue';

import Nif from '../../../components/entities/fields/Nif.vue';
import CorporateRegister from '../../../components/entities/fields/CorporateRegister.vue';
import LegalIdentifier from '../../../components/entities/fields/LegalIdentifier.vue';
import Cnpj from '../../../components/entities/fields/Cnpj.vue';
import Cnae from '../../../components/entities/fields/Cnae.vue';
import Ein from '../../../components/entities/fields/Ein.vue';
import FiscalYear from '../../../components/entities/fields/FiscalYear.vue';
import CommandsComponent from '../../../components/generics/Commands.vue';

// window.addEventListener('beforeunload', event => {
//   console.log('leave');

//   event.preventDefault();
//   event.returnValue = 'Unsaved modifications';
//   return event;
// });

@Component({
  components: {
    EntityName: EntityName,
    FormOrganization: FormOrganization,
    RegulatoryLicenseType: RegulatoryLicenseType,
    Jurisdiction: Jurisdiction,
    EntityUnityBusiness: EntityUnityBusiness,
    EntityStatus: EntityStatus,
    EntityOffice: EntityOffice,
    FiscalYear: FiscalYear,
    GIIN: Giin,
    Nif: Nif,
    CorporateRegister: CorporateRegister,
    LegalIdentifier: LegalIdentifier,
    Cnpj: Cnpj,
    Cnae: Cnae,
    Ein: Ein,
    EntityPhone: EntityPhone,
    EntityEmail: EntityEmail,
    TaxPayer: TaxPayer,
    EntityTaxElection: EntityTaxElection,
    EntityRelation: EntityRelation,
    EntityType: EntityType,
    CommandsComponent: CommandsComponent,
    IndividualRelation: IndividualRelation,
  },
})
export default class CreateCommon extends Vue {
  endpoint = new Endpoint();

  @Getter('getEntity', { namespace: 'entity' }) entity!: Entity;
  @Getter('getOriginalEntity', { namespace: 'entity' }) originalEntity!: Entity;
  @Getter('getLesteEntity', { namespace: 'entity' }) lesteEntity!: LesteEntity;
  @Getter('getConfigForm', { namespace: 'entity' }) configForm!: any;

  @Action('setSnackbar', { namespace: 'app' }) setSnackbar!: any;

  valid = null;
  saving = false;
  loading = false;
  rules = [v => !!v || 'This field is required'];
  dateRules = [
    (v: string) => {
      const message = 'Invalid date mm/dd/yyyy';

      if (v == null) return true;

      if (v.length < 10) return message;

      const [m, d, y] = v.split('/');

      const month = parseInt(m);
      const day = parseInt(d);
      const year = parseInt(y);

      if (day < 1 || day > 31 || day === 0) return message;

      if ((month < 1 && month > 12) || month === 0) return message;

      if (year < 1920 || year > 2100 || year === 0) return message;

      return true;
    },
  ];

  siteTitle = 'Corporative';

  get showEntityType(): boolean {
    return (
      this.$route.name === 'CreateOther' ||
      this.$route.name === 'EditOther' ||
      this.$route.name === 'CreateExternalOther' ||
      this.$route.name === 'DetailExternalOther'
    );
  }

  entityModel = {
    active: true,
    flgExternal: this.$route.meta?.external,
    lesteEntity: {},
    entity: {
      entityType: '',
    },
  };

  async save(): Promise<void> {
    Object.assign(this.entityModel.lesteEntity, this.lesteEntity);
    Object.assign(this.entityModel.entity, this.entity);

    if ((this.$refs.form as Vue & { validate: () => boolean }).validate()) {
      try {
        this.saving = true;

        const response = await this.$http.post(this.endpoint.entity.create, {
          request: this.entityModel,
        });

        this.saving = false;

        this.setSnackbar({
          show: true,
          message: `${this.$route.meta?.title} Success`,
          color: 'success',
        });

        this.$router.push({ name: this.$route.meta?.before });
      } catch (error) {
        console.log('error', error);
        this.saving = false;

        this.setSnackbar({
          show: true,
          message: 'Create Fail',
          color: 'error',
        });
      }
    }
  }

  goToIndex(): string {
    return this.$route.meta?.external
      ? `/external/${this.$route.meta?.index}`
      : `/${this.$route.meta?.index}`;
  }
}
