
  import { Component, Watch, Prop, Vue } from 'vue-property-decorator';
  import { Getter, Action } from 'vuex-class';

  import Endpoint from '../../utils/endpoint';
  import Select from '../../types/app/generic/select';
  import LesteEntity from '@/src/types/entities/lesteEntity';

  @Component({})
  export default class EntityUnityBusinessComponent extends Vue {
    endpoint = new Endpoint();

    @Getter('getOriginalLesteEntity', { namespace: 'entity' })
    originalLesteEntity!: LesteEntity;
    @Getter('getLesteEntity', { namespace: 'entity' })
    lesteEntity!: LesteEntity;

    @Prop({ type: Array })
    rules;

    entitiesStatus: Array<Select> = [];

    async created() {
      await this.getEntityEntityUnityBusinesssAsync();
    }

    get originalStartDate(): string | null {
      if (this.originalLesteEntity.entityStatus.effectiveStartDate !== null) {
        const dt = new Date(this.originalLesteEntity.entityStatus.effectiveStartDate);
        return this.$formatDate(dt);
      }

      return null;
    }

    get startDate(): string | null {
      return this.$formatDate(this.lesteEntity.entityStatus.effectiveStartDate);
    }
    set startDate(value: string | null) {
      this.lesteEntity.entityStatus.effectiveStartDate = this.$parseDate(value);
    }

    async getEntityEntityUnityBusinesssAsync(): Promise<void> {
      const response = await this.$http.get(`${this.endpoint.general.getByTable}/entitystatus/id/txname`);

      if (response.status === 200) {
        this.entitiesStatus = response.data;
      }
    }

    cleanSelect(): void {
      this.lesteEntity.entityStatus.entityStatusId = null;
    }
  }
