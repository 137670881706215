
  import { Component, Watch, Prop, Vue } from 'vue-property-decorator';
  import { Getter, Action } from 'vuex-class';

  import Endpoint from '../../utils/endpoint';

  import Individual from '@/src/types/individual/individual';
  import Employee from '@/src/types/individual/employee';
  import Select from '@/src/types/app/generic/select';

  @Component({})
  export default class CourseCertificationComponent extends Vue {
    endpoint = new Endpoint();

    @Getter('getEmployee', { namespace: 'individual' }) employee!: Employee;
    @Getter('getOriginalEmployee', { namespace: 'individual' })
    originalEmployee!: Employee;

    gettingCourseCertificationTypes = true;
    courseCertificationTypes: Array<Select> = [];

    async created() {
      await this.getCourseCertificationTypes();
    }

    get isChanged(): boolean {
      const changed = this.$toHash(JSON.stringify(this.employee.courseCertifications));
      const original = this.$toHash(JSON.stringify(this.originalEmployee.courseCertifications));

      return changed !== original;
    }

    async getCourseCertificationTypes(): Promise<void> {
      const response = await this.$http.get(
        `${this.endpoint.individual.getByTable}/CourseCertificationTypes/id/txname`
      );

      this.courseCertificationTypes = response.data;
      this.gettingCourseCertificationTypes = false;
    }

    get endYears(): any[] {
      const retVal: any[] = [];

      for (let index = 2030; index > 1989; index--) {
        retVal.push({ text: index, value: index });
      }

      return retVal;
    }

    removePhone(index: number): void {
      this.employee.courseCertifications.splice(index, 1);
    }

    addCourse(): void {
      if (this.employee.courseCertifications === null) this.employee.courseCertifications = [];

      this.employee.courseCertifications.push({
        isCompleted: false,
        school: null,
        degree: null,
        fieldStudy: null,
        endYear: null,
        courseCertificationTypeId: null
      });
    }

    cleanCourseSelect(i: number): void {
      this.employee.courseCertifications[i].courseCertificationTypeId = null;
    }

    cleanSelect(i: number): void {
      this.employee.courseCertifications[i].endYear = null;
    }
  }
