import ErrorData from '../types/checklist/errors/error';

enum ErrorType {
  'checklist' = 1,
  'attachment',
  'note',
  'link',
  'deleteLink',
  'subtask',
  'deleteSubtask',
}

function setReturnMessage(mode: string, action: string) {
  if (mode === ErrorType[1]) {
    return `An error has occured when ${action} a checklist`;
  }
  if (mode === ErrorType[2]) {
    return `An error has occured when upload an attachment`;
  }
  if (mode === ErrorType[3]) {
    return `An error has occured when create a note`;
  }
  if (mode === ErrorType[4]) {
    return `An error has occured when add a link`;
  }
  if (mode === ErrorType[5]) {
    return `An error has occured when delete a link`;
  }
  if (mode === ErrorType[6]) {
    return `An error has occured when ${action} a subTask`;
  }
  if (mode === ErrorType[7]) {
    return `An error has occured when delete a checklist`;
  }
}

export default function getErrorMessage(
  responseError: ErrorData[],
  isEdit: boolean,
  mode: string
) {
  console.log('responseError', responseError);

  const routeAction = isEdit ? 'edit' : 'create';
  if (responseError === undefined) return setReturnMessage(mode, routeAction);
  const error = responseError[0];
  const errorHasCode = error.code === undefined ? false : true;

  if (errorHasCode) {
    error.code === 500000 ? setReturnMessage(mode, routeAction) : error.message;
  }

  return setReturnMessage(mode, routeAction);
}
