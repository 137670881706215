
  import { Component, Watch, Prop, Vue, Ref } from 'vue-property-decorator';
  import { Getter, Action } from 'vuex-class';

  @Component({})
  export default class BusinessUnityDateComponent extends Vue {
    menuOne = false;
    menuTwo = false;

    @Prop({ type: Object })
    businessUnit;

    get effectiveStartDate(): string | null {
      return this.businessUnit.effectiveStartDate
        ? this.formatDate(new Date(this.businessUnit.effectiveStartDate).toISOString().substr(0, 10))
        : null;
    }
    set effectiveStartDate(value: string | null) {
      this.businessUnit.effectiveStartDate = this.parseDate(value);
    }

    get effectiveEndDate(): string | null {
      return this.businessUnit.effectiveEndDate
        ? this.formatDate(new Date(this.businessUnit.effectiveEndDate).toISOString().substr(0, 10))
        : null;
    }
    set effectiveEndDate(value: string | null) {
      this.businessUnit.effectiveEndDate = this.parseDate(value);
    }

    @Watch('menuOne')
    private async mnuOne(val) {
      val && setTimeout(() => ((this.$refs['pickerOne'] as any).activePicker = 'YEAR'));
    }

    @Watch('menuTwo')
    private async mnuTwo(val) {
      val && setTimeout(() => ((this.$refs['pickerTwo'] as any).activePicker = 'YEAR'));
    }

    formatDate(date): any {
      if (!date) return null;

      const [year, month, day] = date.split('-');
      return `${month}/${day}/${year}`;
    }

    parseDate(date): any {
      if (!date) return null;

      const [month, day, year] = date.split('/');
      return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`;
    }
  }
