export default {
  type: '',
  client: {
    typeClient: 0,
    active: true,
    individual: {
      name: null,
      socialName: null,
      bornDate: null,
      gender: null,
      naturalness: null,
      nationality: null,
      documents: [],
      addresses: [],
      emails: [],
      phones: []
    }
  },
  originalClient: {
    typeClient: 0,
    active: true,
    individual: {
      name: null,
      socialName: null,
      bornDate: null,
      gender: null,
      naturalness: null,
      nationality: null,
      documents: [],
      addresses: [],
      emails: [],
      phones: []
    }
  },
  individual: {
    name: null,
    socialName: null,
    bornDate: null,
    gender: null,
    naturalness: null,
    nationality: null,
    documents: [],
    accounts: [],
    addresses: [],
    emails: [],
    phones: [],
    individualRelations: [],
    individualIndividualRelations: []
  },
  employee: {
    nickName: null,
    admission: null,
    demission: null,
    fatherName: null,
    motherName: null,
    miniCurriculum: null,
    maritalStatusId: null,
    dependents: [],
    courseCertifications: [],
    individualUnityBusiness: []
  },
  originalIndividual: {
    name: null,
    socialName: null,
    bornDate: null,
    gender: null,
    naturalness: null,
    nationality: null,
    documents: [],
    accounts: [],
    addresses: [],
    emails: [],
    phones: [],
    individualRelations: [],
    individualIndividualRelations: []
  },
  originalEmployee: {
    nickName: null,
    admission: null,
    demission: null,
    fatherName: null,
    motherName: null,
    miniCurriculum: null,
    maritalStatusId: null,
    dependents: [],
    courseCertifications: [],
    individualUnityBusiness: []
  }
};
