import ConfigForm from '@/src/types/entities/configForm';
import Entity from '@/src/types/entities/entity';
import EntityData from '@/src/types/entities/entityData';
import FilterEntity from '@/src/types/entities/filterEntity';
import LesteEntity from '@/src/types/entities/lesteEntity';
import Office from '@/src/types/entities/office';
import { ActionContext } from 'vuex';
import { AppState } from './types';

export default {
  setOffice(store: ActionContext<AppState, any>, office: Office): void {
    store.commit('setOffice', office);
  },
  setEntityType(store: ActionContext<AppState, any>, entityType: string): void {
    store.commit('setEntityType', entityType);
    store.commit('setOriginalEntityType', entityType);
  },
  setEntityData(store: ActionContext<AppState, any>, entityData: EntityData): void {
    store.commit('setEntityData', entityData);
  },
  setFilterEntityType(store: ActionContext<AppState, any>, entityType: string): void {
    store.commit('setFilterEntityType', entityType);
  },
  setConfigForm(store: ActionContext<AppState, any>, configForm: ConfigForm): void {
    store.commit('setConfigForm', configForm);
  },
  setFilter(store: ActionContext<AppState, any>, filter: FilterEntity): void {
    store.commit('setFilter', filter);
  },
  setFilterSkip(store: ActionContext<AppState, any>, skip: number): void {
    store.commit('setFilterSkip', skip);
  },
  setEntity(store: ActionContext<AppState, any>, entity: Entity) {
    store.commit('setEntity', entity);
    store.commit('setOriginalEntity', JSON.parse(JSON.stringify(entity)));
  },
  setLesteEntity(store: ActionContext<AppState, any>, lesteEntity: LesteEntity) {
    store.commit('setLesteEntity', lesteEntity);
    store.commit('setOriginalLesteEntity', JSON.parse(JSON.stringify(lesteEntity)));
  },
  setNewEntity(store: ActionContext<AppState, any>) {
    //#region  Entity
    store.commit('setEntityEmpty', {
      entityType: null,
      entityTypeId: null,
      currencyId: null,
      flgOpen: false,
      name: null,
      shortName: null,
      purpose: null,
      webSite: null,
      socialMedia: null,
      giin: null,
      nif: null,
      corporateRegister: null,
      legalEntityIdentifier: null,
      ein: null,
      cnpj: null,
      branchCnpj: null,
      cnae: null,
      endFiscalYear: null,
      formOrganizationId: null,
      taxPayer: {
        taxTypeId: null,
        effectiveStartDate: null
      },
      entityTaxElection: {
        taxElectionId: null,
        effectiveStartDate: null
      },
      entityRelation: [],
      individualEntityRelation: [],
      phones: [],
      emails: []
    });
    //#endregion

    //#region Leste Entity
    store.commit('setLesteEntityEmpty', {
      jurisdictionId: null,
      regulatoryLicenseTypeId: null,
      entityBusinessUnity: {
        businessUnityId: null,
        effectiveStartDate: null
      },
      entityStatus: {
        entityStatusId: null,
        effectiveStartDate: null
      },
      entityOffice: {
        entityOfficeId: null,
        effectiveStartDate: null
      }
    });
    //#endregion

    //#region Original Entity
    store.commit('setOriginalEntityEmpty', {
      entityType: null,
      entityTypeId: null,
      currencyId: null,
      flgOpen: false,
      name: null,
      shortName: null,
      purpose: null,
      webSite: null,
      socialMedia: null,
      giin: null,
      nif: null,
      corporateRegister: null,
      legalEntityIdentifier: null,
      ein: null,
      cnpj: null,
      branchCnpj: null,
      cnae: null,
      endFiscalYear: null,
      formOrganizationId: null,
      taxPayer: {
        taxTypeId: null,
        effectiveStartDate: null
      },

      entityTaxElection: {
        taxElectionId: null,
        effectiveStartDate: null
      },
      entityRelation: [],
      individualEntityRelation: [],
      phones: [],
      emails: []
    });
    //#endregion

    //#region Original Leste Entity
    store.commit('setOriginalLesteEntityEmpty', {
      jurisdictionId: null,
      regulatoryLicenseTypeId: null,
      entityBusinessUnity: {
        businessUnityId: null,
        effectiveStartDate: null
      },
      entityStatus: {
        entityStatusId: null,
        effectiveStartDate: null
      },
      entityOffice: {
        entityOfficeId: null,
        effectiveStartDate: null
      }
    });
    //#endregion
  }
};
