import Header from './header';

export default class ChecklistStageHeader {
  public static indexHeader: Header[] = [
    {
      text: 'Name',
      align: 'start',
      sortable: false,
      value: 'name',
      class: 'truncate',
      width: '30%'
    },
    {
      text: 'Sequence',
      align: 'center',
      sortable: false,
      value: 'sequence',
      class: 'truncate',
      width: '10%'
    },
    {
      text: 'Calendar Days',
      align: 'center',
      sortable: false,
      value: 'estimatedDays',
      class: 'truncate',
      width: '15%'
    },
    {
      text: 'Estimate Dead Line',
      align: 'center',
      sortable: false,
      value: 'estimateDeadLine',
      class: 'truncate',
      width: '15%'
    },
    {
      text: 'Real Dead Line',
      align: 'center',
      sortable: false,
      value: 'realDeadLine',
      class: 'truncate',
      width: '15%'
    },
    {
      text: 'Percent Completed',
      align: 'center',
      sortable: false,
      value: 'percentCompleted',
      class: 'truncate',
      width: '15%'
    },
    {
      text: 'Tasks',
      align: 'right',
      value: 'tasks',
      sortable: false,
      class: null,
      width: '10%'
    },
    {
      text: 'Edit',
      align: 'right',
      value: 'actions',
      sortable: false,
      class: null,
      width: ''
    }
  ];
}
