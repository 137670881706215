
import { Component, Watch, Prop, Vue } from 'vue-property-decorator';
import { Getter } from 'vuex-class';

import Endpoint from '../../utils/endpoint';
import Select from '@/src/types/app/generic/select';
import Entity from '@/src/types/entities/entity';

import RelationDateComponent from '../../components/entities/fields/RelationDate.vue';
import RelationValueComponent from '../../components/entities/fields/RelationValue.vue';
import RelationPercentComponent from '../../components/entities/fields/RelationPercent.vue';

@Component({
  components: {
    RelationDateComponent: RelationDateComponent,
    RelationValueComponent: RelationValueComponent,
    RelationPercentComponent: RelationPercentComponent,
  },
})
export default class EntityRelationComponent extends Vue {
  endpoint = new Endpoint();

  @Getter('getOriginalEntity', { namespace: 'entity' }) originalEntity!: Entity;
  @Getter('getEntity', { namespace: 'entity' })
  entity!: Entity;

  @Prop({ type: Boolean })
  parent;

  @Prop({ type: Array })
  dateRules;

  requiredRules = [
    // (v: string) => {
    //   if (
    //     typeof this.entity !== 'undefined' &&
    //     this.entity.entityRelation.entityId !== null &&
    //     v === null
    //   ) {
    //     return 'This field is required';
    //   }
    //   return true;
    // }
  ];

  entityRelationClassifications: Array<Select> = [];
  entities: Array<Select> = [];

  isValueActive = false;
  isPercentActive = false;

  async created() {
    if (this.entity.entityRelation === null) this.entity.entityRelation = [];

    await this.getEntityRelationsAsync();
    await this.getEntitiesAsync();
  }

  async getEntityRelationsAsync(): Promise<void> {
    const response = await this.$http.get(
      `${this.endpoint.general.getByTable}/entityrelationclassifications/id/txname`
    );

    if (response.status === 200) {
      this.entityRelationClassifications = response.data;
    }
  }

  async getEntitiesAsync(): Promise<void> {
    const response = await this.$http.get(
      `${this.endpoint.general.getByTable}/entities/id/txname`
    );

    if (response.status === 200) {
      this.entities = response.data;
    }
  }

  addPortifolio(): void {
    console.log('paren?', this.parent);

    if (this.entity.entityRelation === null) this.entity.entityRelation = [];

    this.entity.entityRelation.push({
      entityId: null,
      entityRelationClassificationId: null,
      effectiveStartDate: null,
      effectiveEndDate: null,
      partner: false,
      percent: 0,
      value: 0,
      parent: this.parent,
    });
  }

  removeRelation(index: number): void {
    this.entity.entityRelation.splice(index, 1);
  }
}
