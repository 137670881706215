
  import Select from '@/src/types/app/generic/select';
  import { Component, Ref, Prop, Vue, Watch } from 'vue-property-decorator';
  import { Getter, Action } from 'vuex-class';

  import LesteEntity from '@/src/types/entities/lesteEntity';
  import Office from '../../types/entities/office';
  import Endpoint from '../../utils/endpoint';

  @Component({})
  export default class Officeform extends Vue {
    endpoint = new Endpoint();

    @Getter('getLesteEntity', { namespace: 'entity' }) lesteEntity!: LesteEntity;
    @Getter('getOffice', { namespace: 'entity' }) office!: Office;

    @Action('setSnackbar', { namespace: 'app' }) setSnackbar!: any;

    @Prop({ type: Function })
    save;

    @Prop({ type: Function })
    cancel;

    @Prop({ type: String })
    mode;

    @Prop({ type: Boolean })
    saving;

    valid = null;
    loading = false;
    rules = [v => !!v || 'This field is required'];
    isCreate = true;

    gettingCities = true;
    cities: Array<Select> = [];

    async created() {
      await this.getCitiesAsync();
    }

    // D = Detail, C = Create
    get title(): string {
      return this.mode === 'C' ? 'Create Office' : 'Detail Office';
    }

    async getCitiesAsync(): Promise<void> {
      try {
        const response = await this.$http.get(`${this.endpoint.common.getByTable}/cities/id/txname`);

        this.cities = response.data;

        this.gettingCities = false;
      } catch (error) {
        this.gettingCities = false;
        console.log('error', error);
      }
    }
  }
