
  import { Component, Vue } from 'vue-property-decorator';
  import { Getter } from 'vuex-class';

  import Header from '../../types/app/headers/header';
  import ClientHeader from '../../types/app/headers/clientHeader';

  import Endpoint from '../../utils/endpoint';
  import { debounce } from 'lodash';
  import { Watch } from 'vue-property-decorator';

  @Component({
    components: {}
  })
  export default class Currencys extends Vue {
    endpoint = new Endpoint();
    headers: Array<Header> = ClientHeader.header;

    @Getter('canCreate', { namespace: 'auth' }) canCreate!: boolean;
    @Getter('canRead', { namespace: 'auth' }) canRead!: boolean;

    debounceSearch: any = null;
    loading = false;
    loadingMore = false;
    hasMore = true;
    typesClient = [
      {
        text: 'Physical',
        value: 1
      },
      {
        text: 'Legal',
        value: 2
      }
    ];
    filter = {
      skip: 0,
      take: 10,
      search: '',
      type: null
    };

    clients: any[] = [];

    created() {
      this.debounceSearch = debounce(this.getClients, 1000);
      this.getClients();
    }

    @Watch('filter.search')
    private async filtering() {
      if (this.filter.search != null) {
        this.filter.skip = 0;
        this.clients = [];
        this.debounceSearch();
      }
    }

    async initialize(): Promise<void> {
      this.filter.skip = 0;
      this.clients = [];

      await this.getClients();
    }

    getByFilter() {
      this.clients = [];
      this.getClients();
    }

    cleanFilter() {
      this.filter.search = '';
      this.filter.skip = 0;
      this.clients = [];
      this.getClients();
    }

    getClients(): void {
      this.loading = true;

      this.$http
        .get(`${this.endpoint.client.getByFilter}?${this.$to_qs(this.filter)}`)
        .then(res => {
          console.log('res', res);

          res.data.data.data.forEach(client => {
            this.clients.push(client);
          });

          this.hasMore = res.data.data.length >= 10;
          this.loading = false;
        })
        .catch(err => {
          this.loading = false;
          console.log('error response', err);
        });
    }

    getMore(): void {
      console.log('getmore');
      const skip = (this.filter.skip += 1);
      this.getClients();
    }

    goToDetail(item: any): void {
      this.$router.push({ name: 'ClientForm', params: { id: item.id } });
    }

    async cleanAll(): Promise<void> {
      localStorage.removeItem(`${this.$route.name}`);

      this.filter = {
        skip: 0,
        take: 10,
        search: '',
        type: null
      };

      await this.initialize();
    }
  }
