import ChecklistEntitySubTask from "./checklistEntitySubTask";
import ChecklistStageEntities from "./checklistStageEntities";
export default interface ChecklistSubTask {
    name: string;
    checklistStageKey: string;
    checklistEntityStageKey: string;
    checklistSubtaskKey: string;
    subtaskKey: string;
    comment: string;
    teamKey: string;
    userUpdatedLogin: string;
    entities: ChecklistStageEntities[];
}

const checklistSubTaskInitialValue: ChecklistSubTask = {
    name: '',
    checklistStageKey: '',
    checklistEntityStageKey: '',
    checklistSubtaskKey: '',
    subtaskKey: '',
    comment: '',
    teamKey: '',
    userUpdatedLogin: '',
    entities: []
}

export { checklistSubTaskInitialValue }