
  import { Component, Ref, Vue } from 'vue-property-decorator';
  import { Action } from 'vuex-class';

  import OrgaizationForm from '../../types/entities/organizationForm';
  import Endpoint from '../../utils/endpoint';

  @Component({})
  export default class FormOrganization extends Vue {
    endpoint = new Endpoint();
    @Action('setSnackbar', { namespace: 'app' }) setSnackbar!: any;

    valid = null;
    saving = false;
    loading = false;
    rules = [v => !!v || 'This field is required'];
    isCreate = true;

    formOrganization: OrgaizationForm = {
      id: null,
      name: '',
      shortName: '',
      active: true,
      requiredFields: {
        giin: false,
        nif: false,
        corporateRegister: false,
        legalIdentifier: false,
        ein: false,
        cnpj: false,
        cnae: false
      }
    };

    async created() {
      this.isCreate = !this.$route.params.id;
      if (!this.isCreate) {
        this.loading = true;
        await this.getFormOrganization();
      }
    }

    get title(): string {
      return this.isCreate ? 'Create Form Organization' : 'Form Organization Detail';
    }

    async getFormOrganization(): Promise<void> {
      this.loading = true;
      const response = await this.$http.get(`${this.endpoint.formOrganization.getById}/${this.$route.params.id}`);

      if (response.status == 200) {
        this.formOrganization = response.data.data;
        this.loading = false;
      }
    }

    async save(): Promise<void> {
      if ((this.$refs.form as Vue & { validate: () => boolean }).validate()) {
        if (this.isCreate) {
          await this.create();
        } else {
          await this.update();
        }
      }
    }

    async update(): Promise<void> {
      this.saving = true;

      this.formOrganization.id = this.$route.params.id;

      try {
        const response = await this.$http.put(this.endpoint.formOrganization.update, {
          request: this.formOrganization
        });

        this.saving = false;

        this.setSnackbar({
          show: true,
          message: 'Form Organization update',
          color: 'success'
        });

        this.$router.push({ name: 'IndexFormOrganization' });
      } catch (error) {
        this.saving = false;
        console.log('erro...', error);
      }
    }

    async create(): Promise<void> {
      this.saving = true;

      try {
        const response = await this.$http.post(this.endpoint.formOrganization.create, {
          request: this.formOrganization
        });

        this.saving = false;

        this.setSnackbar({
          show: true,
          message: 'Form Organization saved',
          color: 'success'
        });

        this.$router.push({ name: 'IndexFormOrganization' });
      } catch (error) {
        this.saving = false;
      }
    }
  }
