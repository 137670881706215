export default {
  fullScreen: false,
  overlay: false,
  snackbar: {
    show: false,
    color: '',
    message: '',
    multiline: false
  },
  filter: {
    skip: 0,
    take: 10,
    search: null,
    active: null
  }
};
