export class ConfigRelation {
  private static readonly configs = {
    'ed5dde69-f0ff-4ae1-8ece-ffee796b0509_color': '#56585c', //INVESTOR
    '9dee69d4-1fdc-4082-b443-c9198cb6f5bb_color': '#56585c', //PARTNER
    '0c0a1223-91e7-42c1-ac6a-5a62fc5dc413_color': '#000000', //SHAREH
    'cedfbcd0-6fca-49bf-b894-1ac0fff17260_color': '#000000', //OWNER
    'c1746237-a4d3-4890-93db-b4055827f72b_color': '#000000', //MEMBER
    '7e287fd0-9575-4d0b-8625-91715b38273e_color': '#000000', //MANAG-MEM
    '1937cf3c-d2cb-4992-8b1c-0bd6015cd33e_color': '#000000', //QUOTAHOLDER
    '889c2e0b-b14f-4d78-8a17-2aaa3a7914ad_color': '#000000', //SOLE MEMBER
    '4264fb76-9ad9-4382-96ea-262999f5b5c2_color': '#3156eb', //INV ADVISOR
    '4264fb76-9ad9-4382-96ea-262999f5b5c2_dash': [3, 2], //INV ADVISOR
    '93fb294d-74a3-4a97-ab0f-913e5d197700_color': '#3156eb', //CLIENT
    '93fb294d-74a3-4a97-ab0f-913e5d197700_dash': [3, 2], //CLIENT
    '8aeb0b9d-6e61-411a-b2b3-a2adec045bb7_color': '#3156eb', //GEST-F
    '8aeb0b9d-6e61-411a-b2b3-a2adec045bb7_dash': [3, 2], //GEST-F
    '54a5bbc0-daf4-4a47-96a2-edf98d91590d_color': '#b6b6b8', //SERVPRO
    '54a5bbc0-daf4-4a47-96a2-edf98d91590d_dash': [3, 2] //SERVPRO
  };

  public static GetConfigProp(prop: string): string {
    if (ConfigRelation.configs[prop] === undefined) {
      return '#000000';
    }
    return ConfigRelation.configs[prop];
  }
}
