
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { Getter, Action } from 'vuex-class';
import 'vue-group-avatar/dist/vue-group-avatar.css';
import VueSkeletonLoader from 'skeleton-loader-vue';
import { GroupAvatar } from 'vue-group-avatar';
import RadialProgressBar from 'vue-radial-progress';

import Endpoint from '../../utils/endpoint';

import Header from '../../types/app/headers/header';
import ChecklistHeaderDetails from '../../types/app/headers/ChecklistHeaderDetails';
import SubTaskHeader from '../../types/app/headers/subTaskHeader';
import ChecklistFilters from '../../types/checklist/checklistFilters';
import FormatDate from '../../utils/formatDate';
import SetDate from '../../utils/setDate';
import Entities from '../../types/checklist/entities';
import Teams from '../../types/checklist/teams';
import Owners from '../../types/checklist/owners';
import ChecklistFinishTask from '../../components/checklist/Modais/ChecklistFinishTask.vue';
import ChecklistTaskModal from '../../components/checklist/Modais/ChecklistTaskModal.vue';
import ChecklistModalSubTask from '../../components/checklist/Modais/ChecklistModalSubTask.vue';
import Errors from '../../types/checklist/errors/errors';
import FeedbackInterface from '../../types/checklist/feedback';
import ChecklistTeams from '@/src/types/checklist/checklistTeams';
import ChecklistOwners from '@/src/types/checklist/checklistOwners';
import User from '../../types/auth/User';
import ChecklistStage from '@/src/types/checklist/checklistStage';
import ChecklistSubTask, {
  checklistSubTaskInitialValue,
} from '../../types/checklist/checklistSubTask';

import GetSubtaskPayload from '../../types/checklist/getSubTaskPayload';

@Component({
  components: {
    ChecklistTaskModal,
    ChecklistFinishTask,
    ChecklistModalSubTask,
    VueSkeletonLoader,
    RadialProgressBar,
    GroupAvatar,
  },
})
export default class DetailChecklist extends Vue {
  private endpoint = new Endpoint();
  headers: Array<Header> = ChecklistHeaderDetails.indexHeaderDetails;
  subTaskHeader: Array<Header> = SubTaskHeader.indexHeaderDetailsSubTask;

  @Getter('getModalTasks', { namespace: 'checklist' }) modalTasks!: boolean;
  @Action('setModalTasks', { namespace: 'checklist' }) setModalTasks!: any;

  @Getter('getModalSubTask', { namespace: 'checklist' })
  subTaskModal!: boolean;
  @Action('setModalSubTask', { namespace: 'checklist' })
  setModalSubTask!: any;

  @Getter('getSubTaskName', { namespace: 'checklist' })
  subTaskName!: boolean;
  @Action('setSubTaskName', { namespace: 'checklist' })
  setSubTaskName!: any;

  @Getter('getSubTaskOrTask', { namespace: 'checklist' })
  subTaskOrTask!: string;
  @Action('setSubTaskOrTask', { namespace: 'checklist' })
  setSubTaskOrTask!: any;

  @Getter('getChecklistSubTaskKey', { namespace: 'checklist' })
  checklistSubTaskKey!: boolean;
  @Action('setChecklistSubTaskKey', { namespace: 'checklist' })
  setChecklistSubTaskKey!: any;

  @Getter('getUser', { namespace: 'auth' }) user!: User;

  @Getter('getChecklistEntityStageKey', { namespace: 'checklist' })
  checklistEntityStageKey!: string;
  @Action('setChecklistEntityStageKey', { namespace: 'checklist' })
  setChecklistEntityStageKey!: any;

  @Getter('getTaskKey', { namespace: 'checklist' })
  taskKey!: string;
  @Action('setTaskKey', { namespace: 'checklist' })
  setTaskKey!: any;

  @Getter('getChecklistNameTask', { namespace: 'checklist' })
  checklistNameTask!: string;
  @Action('setChecklistNameTask', { namespace: 'checklist' })
  setChecklistNameTask!: any;

  @Getter('getFinishModal', { namespace: 'checklist' })
  saveFinishModal!: boolean;
  @Action('setFinishModal', { namespace: 'checklist' }) setFinishModal!: any;

  @Getter('getFeedback', { namespace: 'checklist' })
  public feedback!: FeedbackInterface;
  @Action('setFeedback', { namespace: 'checklist' }) public setFeedback: any;

  @Getter('getNote', { namespace: 'checklist' })
  public note!: string;
  @Action('setNote', { namespace: 'checklist' }) public setNote!: string;

  // for subTask
  // @Action('setEntityStageKeyForSubTaskReload', { namespace: 'checklist' })
  // public setEntityStageKeyForSubTaskReload: any;
  // @Action('setStageKeyForSubTaskReload', { namespace: 'checklist' })
  // public setStageKeyForSubTaskReload: any;

  @Prop(String) readonly id!: string;

  @Watch('saveFinishModal')
  handleReloadEntities() {
    this.getEntitiesById(this.checklistEntityKey);
  }

  @Watch('checklistFilterSelected')
  handleGetChecklistEntity() {
    this.checklistEntityKey = this.checklistFilterSelected.checklistEntityKey;
    this.getEntitiesById(this.checklistEntityKey);
  }

  formatDate = FormatDate;
  setDate = SetDate;

  checklists: any[] = [];
  checklistSubTasks: ChecklistSubTask[] = [];
  subTasksData: ChecklistSubTask[] = [];
  item: Entities = new Entities();
  entitiesStages: ChecklistStage[] = [];
  entities: Entities[] = [];
  stages: any[] = [];
  loading = false;
  checklistFilter: ChecklistFilters[] = [];
  checklistFilterSelected: ChecklistFilters = new ChecklistFilters();
  showSkeleton = true;
  checklistEntityKey: string | null = '';
  loadingFinish = false;
  log: any = [];
  notes: any = [];
  loadingNote = false;
  userCanInteractModal = false;
  checklistId = 0;
  expectedDeadline = null;
  expectedDate = null;
  expectedDeadlineDate = '';
  isExpanded = false;
  loadingSubTask = false;

  ALL_INTERVAL = 0;
  MONTHLY = 1;
  SEMESTER = 2;
  QUARTERLY = 3;
  YEARLY = 4;
  OTHER = 5;

  subTasks: any[] = [
    { subName: 'Nome da subtask 2', teamKey: 'Administrative' },
  ];

  teams: ChecklistTeams[] = [];
  owners: ChecklistOwners[] = [];
  checklistStagesUser: ChecklistStage[] = [];

  public nameSelected = { name: 'All Checklist', number: this.ALL_INTERVAL };
  public nameItems = [
    {
      name: 'All Checklist',
      number: this.ALL_INTERVAL,
    },
    {
      name: 'It Security Assessment',
      number: this.MONTHLY,
    },
    {
      name: 'Audit OnShore',
      number: this.QUARTERLY,
    },
    {
      name: 'Compliance Document',
      number: this.SEMESTER,
    },
  ];

  public async showModalTask(
    checklistEntityStageKey: string,
    nameTask: string,
    taskKey: string
  ) {
    this.setChecklistEntityStageKey(checklistEntityStageKey);
    this.setTaskKey(taskKey);
    await this.getSubTaskModal(checklistEntityStageKey, taskKey);
    this.setModalTasks(true);
    this.setChecklistNameTask(nameTask);
  }

  public showSubTaskModal(
    item: string,
    checklist: string,
    name: string,
    stageKey: string
  ) {
    this.setChecklistSubTaskKey(item);
    this.setChecklistEntityStageKey(checklist);
    this.setSubTaskName(name);
    this.setModalSubTask(true);
  }

  public showFinishModal(checklistEntityStageKey: string) {
    if (this.subTaskOrTask === 'Task') {
      this.setFinishModal(true);
      this.saveChecklistEntitiesStageKey(checklistEntityStageKey);
    }

    if (this.subTaskOrTask === 'SubTask') {
      this.setChecklistSubTaskKey(checklistEntityStageKey);
      this.setFinishModal(true);
    }
  }

  public closeModal() {
    this.setFinishModal(false);
  }

  public saveChecklistEntitiesStageKey(key: string) {
    this.setChecklistEntityStageKey(key);
  }

  public setChecklistId(id: any) {
    const check = this.item.checklists.find((item) => {
      item.checklistEntityKey === id;
    });

    return id.checklistEntityKey;
  }

  public async putExpectedDate(item: string, expected: string) {
    this.loading = true;
    const params = {
      checklistEntityStageKey: item,
      expectedDeadline: this.setDate(expected),
      loggedUserEmail: this.user.email,
    };
    try {
      const response = await this.$http.put(
        this.endpoint.setExpected.expected,
        params
      );
      if (response.data.success) this.getEntitiesById();
    } catch (error) {
      console.log('Error no put do expected deadline');
    }
    this.loading = false;
  }

  public async getEntitiesById(
    checklistEntityKey: string | null = this.id
  ): Promise<void> {
    const params = {
      LoggedUserEmail: this.user.email,
    };
    const items = await this.$http.get(
      this.endpoint.entities.list + `/${checklistEntityKey}`,
      {
        params,
      }
    );
    const entities = items.data.data as Entities;
    this.item = entities;
    this.checklistStagesUser = this.item.stages;
    this.checklistFilter = entities.checklists;
    const checklistId = this.item.checklistStatusId;
    this.checklistId = checklistId;
    this.showSkeleton = false;
    this.getSubTasksData(this.item.stages);
  }

  public async getSubTasksData(
    checklist: ChecklistStage[] = this.checklistStagesUser
  ) {
    const subtasks = await Promise.all(
      checklist.map(async (item) => {
        const response = await this.$http.get(
          this.endpoint.subTask.list +
            `/${item.checklistEntityStageKey}/${item.taskKey}/${this.user.email}`
        );

        return response.data.data;
      })
    );
    this.subTasksData = subtasks.flatMap((item) => item);
  }

  public subTaskForList(subTask: ChecklistSubTask) {
    return this.subTasksData.filter(
      (item) => item.checklistEntityStageKey === subTask.checklistEntityStageKey
    );
  }

  public async getSubTaskModal(
    checklistEntityStageKey: string = this.checklistEntityStageKey,
    taskKey: string = this.taskKey
  ) {
    this.loadingSubTask = true;
    try {
      const response = await this.$http.get(
        this.endpoint.subTask.list +
          `/${checklistEntityStageKey}/${taskKey}/${this.user.email}`
      );
      this.checklistSubTasks = response.data.data as ChecklistSubTask[];
      this.getSubTasksData();
    } catch (error) {
      console.log('Error no get subtask na listagem');
    }
    this.loadingSubTask = false;
  }

  async getTeams() {
    try {
      const response = await this.$http.get(this.endpoint.teams.list);
      const items = response.data.data;
      this.teams = items;
    } catch (error) {
      console.log('Erro em buscar o teams');
    }
  }

  handleSetTeamById(teamId: string) {
    const team = this.teams.find((team) => team.name === teamId);
    return team?.name;
  }

  async getOwners() {
    try {
      const response = await this.$http.get(this.endpoint.owners.list);
      const items = response.data.data;
      this.owners = items;
    } catch (error) {
      console.log('Erro em buscar os owners');
    }
  }

  handleSetOwnerById(ownerId: string) {
    const owner = this.owners.find((owner) => owner.email === ownerId);
    return owner?.name;
  }

  handleSetIsEvidenceValue(evidence: boolean): string {
    return evidence ? 'Yes' : 'No';
  }

  async mounted() {
    await this.getEntitiesById();
    await this.getTeams();
    await this.getOwners();
    this.checklistFilterSelected = this.checklistFilter.find(
      (item) => item.name === this.item.checklistName
    ) as ChecklistFilters;
  }

  // created() {
  //   this.checklistEntityKey = localStorage.getItem('checklistEntityKey');
  // }

  setPredecessorName(id: string): string {
    const predecessor = this.item.stages.find((item) => item.taskKey === id);

    return predecessor?.name === undefined ? 'No' : predecessor?.name;
  }

  public async putTaskFinishModal(params: any) {
    this.loadingFinish = true;
    try {
      await this.$http.put(
        this.endpoint.putFinish.finish +
          `/${this.checklistEntityStageKey}/${this.user.email}`,
        params
      );
    } catch (error) {
      console.log('Erro no put do finish');
      const responseError = error as Errors;
      console.log('Error no put do status Disable');
      this.setFeedback({
        feedback: true,
        color: 'red',
        text: responseError.data.errors[0].message,
      });
      setTimeout((): void => {
        this.setFeedback({
          feedback: false,
        });
      }, 6000);
    } finally {
      this.loadingFinish = false;
      this.closeModal();
    }
  }

  public async putSubTaskFinishModal(item: any) {
    this.loadingFinish = true;
    try {
      const response = await this.$http.put(
        this.endpoint.subTaskFinish.finish + `/${item}/${this.user.email}`
      );
    } catch (error) {
      console.log('Erro no put do finish');
      const responseError = error as Errors;
      console.log('Error no put do status Disable');
      this.setFeedback({
        feedback: true,
        color: 'red',
        text: responseError.data.errors[0].message,
      });
      setTimeout((): void => {
        this.setFeedback({
          feedback: false,
        });
      }, 6000);
    } finally {
      this.loadingFinish = false;
      this.closeModal();
    }
  }

  public async getNotesModal() {
    this.loadingNote = true;
    try {
      const response = await this.$http.get(
        this.endpoint.note.stageNote + `/${this.checklistEntityStageKey}`
      );
      this.notes = response.data.data;
    } catch (error) {
      console.log('Erro no Note');
    }
    this.loadingNote = false;
  }

  public async getActivityLogModal() {
    try {
      const response = await this.$http.get(
        this.endpoint.activyLogList.log + `/${this.checklistEntityStageKey}`
      );
      this.log = response.data.data;
      if (response.data.success)
        await this.getEntitiesById(this.checklistEntityKey);
    } catch (error) {
      console.log('Erro no get do activy log');
    }
  }

  back() {
    this.$router.push({ path: '/checklist' });
  }
}
