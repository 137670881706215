import { RouteConfig } from 'vue-router';
import Required from '../utils/required';

import IndexExternalAdvisor from '../views/Entities/Common/Index.vue';
import CreateExternalAdvisor from '../views/Entities/Common/Create.vue';
import DetailExternalAdvisor from '../views/Entities/Common/Detail.vue';

import IndexExternalFeeder from '../views/Entities/Common/Index.vue';
import CreateExternalFeeder from '../views/Entities/Common/Create.vue';
import DetailExternalFeeder from '../views/Entities/Common/Detail.vue';

import IndexExternalPortfolio from '../views/Entities/Common/Index.vue';
import CreateExternalPortfolio from '../views/Entities/Common/Create.vue';
import DetailExternalPortfolio from '../views/Entities/Common/Detail.vue';

import IndexExternalVehicle from '../views/Entities/Common/Index.vue';
import CreateExternalVehicle from '../views/Entities/Common/Create.vue';
import DetailExternalVehicle from '../views/Entities/Common/Detail.vue';

import IndexExternalOther from '../views/Entities/Common/Index.vue';
import CreateExternalOther from '../views/Entities/Common/Create.vue';
import DetailExternalOther from '../views/Entities/Common/Detail.vue';

import IndexSupplier from '../views/Entities/Common/Index.vue';
import CreateSupplier from '../views/Entities/Common/Create.vue';
import DetailSupplier from '../views/Entities/Common/Detail.vue';

const required = new Required();

const externalEntityRoutes: Array<RouteConfig> = [
  {
    path: '/external/advisors',
    name: 'IndexExternalAdvisor',
    meta: {
      parent: 'Ext. Entity',
      create: 'CreateExternalAdvisor',
      edit: 'DetailExternalAdvisor',
      title: 'External Advisors',
      type: 'advisor',
      external: true
    },
    component: IndexExternalAdvisor,
    beforeEnter: required.requireAuth
  },
  {
    path: '/external/advisor',
    name: 'CreateExternalAdvisor',
    meta: {
      parent: 'Ext. Entity',
      before: 'IndexExternalAdvisor',
      index: 'advisors',
      title: 'Create External Advisor',
      type: 'advisor',
      external: true
    },
    component: CreateExternalAdvisor,
    beforeEnter: required.requireAuth
  },
  {
    path: '/external/advisor/:id?',
    name: 'DetailExternalAdvisor',
    meta: {
      parent: 'Ext. Entity',
      before: 'IndexExternalAdvisor',
      index: 'advisors',
      title: 'Edit External Advisor',
      type: 'advisor',
      external: true
    },
    component: DetailExternalAdvisor,
    beforeEnter: required.requireAuth
  },
  {
    path: '/external/feeders',
    name: 'IndexExternalFeeder',
    meta: {
      parent: 'Ext. Entity',
      create: 'CreateExternalFeeder',
      edit: 'DetailExternalFeeder',
      title: 'External Feeders',
      type: 'feeder',
      external: true
    },
    component: IndexExternalFeeder,
    beforeEnter: required.requireAuth
  },
  {
    path: '/external/feeder',
    name: 'CreateExternalFeeder',
    meta: {
      parent: 'Ext. Entity',
      before: 'IndexExternalFeeder',
      index: 'feeders',
      title: 'Create External Feeder',
      type: 'feeder',
      external: true
    },
    component: CreateExternalFeeder,
    beforeEnter: required.requireAuth
  },
  {
    path: '/external/feeder/:id?',
    name: 'DetailExternalFeeder',
    meta: {
      parent: 'Ext. Entity',
      before: 'IndexExternalFeeder',
      index: 'feeders',
      title: 'Edit External Feeder',
      type: 'feeder',
      external: true
    },
    component: DetailExternalFeeder,
    beforeEnter: required.requireAuth
  },
  {
    path: '/external/portfolios',
    name: 'IndexExternalPortfolio',
    meta: {
      parent: 'Ext. Entity',
      create: 'CreateExternalPortfolio',
      edit: 'DetailExternalPortfolio',
      title: 'External Portfolios',
      type: 'portfolio',
      external: true
    },
    component: IndexExternalPortfolio,
    beforeEnter: required.requireAuth
  },
  {
    path: '/external/portfolio',
    name: 'CreateExternalPortfolio',
    meta: {
      parent: 'Ext. Entity',
      before: 'IndexExternalPortfolio',
      index: 'portfolios',
      title: 'Create External Portfolio',
      type: 'portfolio',
      external: true
    },
    component: CreateExternalPortfolio,
    beforeEnter: required.requireAuth
  },
  {
    path: '/external/portfolio/:id?',
    name: 'DetailExternalPortfolio',
    meta: {
      parent: 'Ext. Entity',
      before: 'IndexPortfolio',
      index: 'portfolios',
      title: 'Edit External Portfolio',
      type: 'portfolio',
      external: true
    },
    component: DetailExternalPortfolio,
    beforeEnter: required.requireAuth
  },
  {
    path: '/external/vehicles',
    name: 'IndexExternalVehicle',
    meta: {
      parent: 'Ext. Entity',
      create: 'CreateExternalVehicle',
      edit: 'DetailExternalVehicle',
      title: 'External Vehicles',
      type: 'vehicle',
      external: true
    },
    component: IndexExternalVehicle,
    beforeEnter: required.requireAuth
  },
  {
    path: '/external/vehicle',
    name: 'CreateExternalVehicle',
    meta: {
      parent: 'Ext. Entity',
      before: 'IndexExternalVehicle',
      index: 'vehicles',
      title: 'Create External Vehicle',
      type: 'vehicle',
      external: true
    },
    component: CreateExternalVehicle,
    beforeEnter: required.requireAuth
  },
  {
    path: '/external/vehicle/:id?',
    name: 'DetailExternalVehicle',
    meta: {
      parent: 'Ext. Entity',
      before: 'IndexExternalVehicle',
      index: 'vehicles',
      title: 'Edit External Vehicle',
      type: 'vehicle',
      external: true
    },
    component: DetailExternalVehicle,
    beforeEnter: required.requireAuth
  },
  {
    path: '/external/others',
    name: 'IndexExternalOther',
    meta: {
      parent: 'Ext. Entity',
      create: 'CreateExternalOther',
      edit: 'DetailExternalOther',
      title: 'External Others',
      type: 'other',
      external: true
    },
    component: IndexExternalOther,
    beforeEnter: required.requireAuth
  },
  {
    path: '/external/other',
    name: 'CreateExternalOther',
    meta: {
      parent: 'Ext. Entity',
      before: 'IndexExternalOther',
      index: 'others',
      title: 'Create External Other',
      type: 'other',
      external: true
    },
    component: CreateExternalOther,
    beforeEnter: required.requireAuth
  },
  {
    path: '/external/other/:id?',
    name: 'DetailExternalOther',
    meta: {
      parent: 'Ext. Entity',
      before: 'IndexExternalOther',
      index: 'others',
      title: 'Edit External Other',
      type: 'other',
      external: true
    },
    component: DetailExternalOther,
    beforeEnter: required.requireAuth
  },
  {
    path: '/suppliers',
    name: 'IndexSupplier',
    meta: {
      parent: 'Ext. Entity',
      create: 'CreateSupplier',
      edit: 'DetailSupplier',
      title: 'Suppliers',
      type: 'supplier',
      external: true
    },
    component: IndexSupplier,
    beforeEnter: required.requireAuth
  },
  {
    path: '/supplier',
    name: 'CreateSupplier',
    meta: {
      parent: 'Ext. Entity',
      before: 'IndexSupplier',
      index: 'suppliers',
      title: 'Create Supplier',
      type: 'supplier',
      external: true
    },
    component: CreateSupplier,
    beforeEnter: required.requireAuth
  },
  {
    path: '/supplier/:id?',
    name: 'DetailSupplier',
    meta: {
      parent: 'Ext. Entity',
      before: 'IndexSupplier',
      index: 'suppliers',
      title: 'Edit Supplier',
      type: 'supplier',
      external: true
    },
    component: DetailSupplier,
    beforeEnter: required.requireAuth
  }
];

export default externalEntityRoutes;
