
  import { Component, Watch, Prop, Vue } from 'vue-property-decorator';
  import { Getter, Action } from 'vuex-class';

  import Individual from '@/src/types/individual/individual';
  import Employee from '@/src/types/individual/employee';

  @Component({})
  export default class ParentNameComponent extends Vue {
    @Getter('getEmployee', { namespace: 'individual' }) employee!: Employee;
    @Getter('getOriginalEmployee', { namespace: 'individual' })
    originalEmployee!: Employee;
  }
