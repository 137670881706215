import Checklist from "./checklist";
import ChecklistStage from "./checklistStage";
import ChecklistStageTask from "./checklistStageTask";

export default class Entities {
    entityKey = '';
    checklistName = '';
    checklistStatusId = 0;
    checklistEntitykey = '';
    entity = '';
    entitityTypeId = '';
    onCreation = '';
    formOrganizationId = '';
    formOrganization = '';
    regulatoryLicenseId = '';
    regulatoryLicense = '';
    checklists: Checklist[] = [];
    stages: ChecklistStage[] = [];
    checklistEntitiesStages: ChecklistStageTask[] = [];
    createdOn = '';
    lastModifiedOn = '';
}