import moment from "moment";

export default function handleFormatDate(date: string) {
    const dateFormatted = moment(date).format('DD/MMMM/YYYY');
    const dateSeparated = dateFormatted.split('/');
    const monthFormatted = dateSeparated[1]?.substring(0, 3);
    const dateResult =
      dateSeparated[0] + '/' + monthFormatted + '/' + dateSeparated[2];

    return date ? dateResult : '--';
  }

