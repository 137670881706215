import TeamSelected, { teamSelectedInitialValue } from './stages/teamSelected';
import PredecessorSelected, {
  predecessorSelectedInitialValue,
} from './stages/predecessorSelected';
import SequenceSelected, {
  sequenceSelectedInitialValue,
} from './stages/sequenceSeleted';
import ChecklistTeams from './checklistTeams';
import Predecessor from './predecessor';

export default class ChecklistTask {
  id = 0;
  checklistStageKey = '';
  checklistKey = '';
  name = '';
  teamKey = '';
  sequence = '';
  teamSelected = new ChecklistTeams();
  predecessorSelected = new Predecessor();
  sequenceSelected: SequenceSelected = sequenceSelectedInitialValue;
  predecessor = '';
  isEvidence = 'false';
  estimatedDays = '';
  estimateDeadLine = '';
  realDeadLine = null;
  expectedDeadline = null;
  deadlineDate = '';
  expectedDeadlineDate = '';
  predecessorName = '';
  predecessorChecklistStage = '';
  comment = '';
  userUpdatedLogin = '';
  isDeleted = true || false;
}
