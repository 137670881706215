
import { Component, Vue, Prop } from 'vue-property-decorator';
import { Getter, Action } from 'vuex-class';
import { Watch } from 'vue-property-decorator';

import Endpoint from '../../../utils/endpoint';

import Header from '../../../types/app/headers/header';
import ChecklistEntitiesModal from '../../../types/app/headers/ChecklistEntitiesModal';

import TriggerPayload from '../../../types/checklist/triggers/checklistTriggerPayload';
import ChecklistStageEntities from '../../../types/checklist/checklistStageEntities';
import User from '../../../types/auth/User';

import SubTasksCompanies from '../../../types/checklist/checklistStageEntities';

@Component({})
export default class ModalEntities extends Vue {
  private endpoint = new Endpoint();
  headers: Array<Header> = ChecklistEntitiesModal.indexHeader;

  @Getter('getCompaniesSubTaskModal', { namespace: 'checklist' })
  companiesSubTaskModal!: boolean;
  @Action('setCompaniesSubTaskModal', { namespace: 'checklist' })
  setCompaniesSubTaskModal!: any;

  @Getter('getUser', { namespace: 'auth' }) user!: User;

  @Getter('getIsEditSubTask', { namespace: 'checklist' })
  isEditSubTask!: boolean;
  @Action('setIsEditSubTask', { namespace: 'checklist' })
  setIsEditSubTask!: any;

  @Prop(Array) companies!: SubTasksCompanies[];
  @Prop(Array) companySelected!: SubTasksCompanies[];
  @Prop(Array) companiesSelectedEdit!: SubTasksCompanies[];
  @Prop(Boolean) readonly loading!: boolean;

  @Watch('companiesSubTaskModal')
  handlePostEntities() {
    if (this.companiesSubTaskModal === true) this.handleSetSelectedCompanies();
  }

  handleSetSelectedCompanies() {
    if (this.isEditSubTask) {
      this.companiesSelected = this.companiesSelectedEdit;
    } else {
      this.companiesSelected = this.companySelected;
    }
  }

  companiesSelected: SubTasksCompanies[] = [];
  entitiesTriggers: any[] = [];

  emptyVariables() {
    this.entitiesTriggers = [];
    this.companies = [];
    this.companiesSelected = [];
  }

  returnCompaniesSelecteds() {
    this.$emit('setCompaniesSelecteds', this.companiesSelected);
    this.closeModal();
  }

  closeModal() {
    this.setCompaniesSubTaskModal(false);
  }
}
