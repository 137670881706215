
import Vue from 'vue';
import Component from 'vue-class-component';
import { State, Action, Getter } from 'vuex-class';
import { AuthService } from '../../services/authService';

@Component({})
export default class Login extends Vue {
  authService = new AuthService();
  @Action('setUser', { namespace: 'auth' }) setUser: any;

  async created() {
    this.setUser(null);
    localStorage.clear();
    const accounts = await this.authService.getAllAccountsAsync();
    if (accounts.length > 0) {
      await this.authService.logoutAsync();
    }
  }
}
