export default class Auth {
  static verifyPermission(areas: Array<any>, area: string | null, menu: string | null, action: string): boolean {
    if (area && menu) {
      return areas.some(x => {
        return (
          x.title === area &&
          x.menus.some(y => {
            return (
              (y.index === menu || y.detail === menu || y.create === menu) &&
              y.permissions.some(z => z.action === action && z.active)
            );
          })
        );
      });
    } else {
      return false;
    }
  }
}
