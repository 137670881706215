
import { Component, Vue, Prop } from 'vue-property-decorator';
import { Getter, Action } from 'vuex-class';

@Component({})
export default class ModalSaveFinished extends Vue {
  @Getter('getFinishModal', { namespace: 'checklist' })
  saveFinishModal!: boolean;
  @Action('setFinishModal', { namespace: 'checklist' }) setFinishModal!: any;

  @Getter('getSubTaskOrTask', { namespace: 'checklist' })
  subTaskOrTask!: string;
  @Action('setSubTaskOrTask', { namespace: 'checklist' })
  setSubTaskOrTask!: any;

  @Getter('getChecklistSubTaskKey', { namespace: 'checklist' })
  checklistSubTaskKey!: boolean;
  @Action('setChecklistSubTaskKey', { namespace: 'checklist' })
  setChecklistSubTaskKey!: any;

  @Getter('getChecklistEntityStageKey', { namespace: 'checklist' })
  checklistEntityStageKey!: string;

  @Prop(Boolean) readonly loading: boolean | undefined;

  public async putTaskFinish() {
    const params = {
      checklistEntityStageKey: this.checklistEntityStageKey,
    };
    this.$emit('putTaskFinish', params);
  }

  public async putSubTaskFinish() {
    this.$emit('putSubTaskFinish', this.checklistSubTaskKey);
  }

  public putSubTaskOrTask() {
    if (this.subTaskOrTask === 'Task') {
      this.putTaskFinish();
    }

    if (this.subTaskOrTask === 'SubTask') {
      this.putSubTaskFinish();
    }
  }

  closeModal() {
    this.setFinishModal(false);
  }
}
