
import { Component, Prop, Watch, Vue } from 'vue-property-decorator';
import { Getter, Action } from 'vuex-class';

import Header from '@/src/types/app/headers/header';
import EntityHeader from '../../types/app/headers/EntityHeader';
import EntityFilter from '../../components/entities/filters/EntityFilter.vue';
import Endpoint from '../../utils/endpoint';

import { debounce, isArray, isObject } from 'lodash';
import qs from 'qs';
import XLSX from 'xlsx';
import SelectBoolean from '@/src/types/app/generic/selectBoolean';
import Select from '@/src/types/app/generic/select';

@Component({
  components: {
    EntityFilter: EntityFilter,
  },
})
export default class DataTable extends Vue {
  entityEndpoint = new Endpoint();
  headers: Array<Header> = EntityHeader.header;

  @Getter('canCreate', { namespace: 'auth' }) canCreate!: boolean;
  @Getter('canRead', { namespace: 'auth' }) canRead!: boolean;

  @Action('setFilter', { namespace: 'entity' }) setFilter!: any;
  @Action('setFilterSkip', { namespace: 'entity' }) setFilterSkip!: any;
  @Action('setFilterEntityType', { namespace: 'entity' })
  setFilterEntityType!: any;

  debounceSearch: any = null;
  loading = false;
  loadingMore = false;
  loadingBusinessUnitIds = false;
  loadingEntityStatus = false;
  hasMore = true;
  generatingXlsx = false;
  total = 0;
  stopWatch = true;
  data: Array<any> = [];
  actives: Array<SelectBoolean> = [];
  entitiesBusiness: Array<Select> = [];
  businessUnitIds: Array<Select> = [];
  entitiesStatus: Array<Select> = [];

  @Prop({ type: String })
  title;

  @Prop({ type: String, required: false })
  entityType;

  @Prop({ type: String })
  endpoint;

  @Prop({ type: Boolean, required: false })
  isEntityTypeId;

  @Prop({ type: Function })
  goToDetail;

  @Prop({ type: Function })
  goToAdd;

  filter = {
    skip: 0,
    take: 10,
    search: '',
    entityType: '',
    actives: [true],
    businessUnityIds: [],
    ignoreNull: true,
    flgExternal: false,
  };

  async mounted() {
    this.debounceSearch = debounce(this.getEntitiesAsync, 1000);

    this.loadFilter();
    await this.initialize();
  }

  @Watch('$route')
  private async route() {
    this.loadFilter();
    await this.initialize();
  }

  async saveFilter(): Promise<void> {
    localStorage.setItem(`${this.$route.name}`, JSON.stringify(this.filter));
    await this.initialize();
  }

  async cleanAll(): Promise<void> {
    localStorage.removeItem(`${this.$route.name}`);

    this.filter = {
      skip: 0,
      take: 10,
      search: '',
      entityType: '',
      actives: [true],
      businessUnityIds: [],
      ignoreNull: true,
      flgExternal: false,
    };

    await this.initialize();
  }

  loadFilter(): void {
    const ftr = localStorage.getItem(`${this.$route.name}`);

    if (ftr) {
      this.filter = JSON.parse(ftr);
    } else {
      this.filter = {
        skip: 0,
        take: 10,
        search: '',
        entityType: '',
        actives: [true],
        businessUnityIds: [],
        ignoreNull: true,
        flgExternal: false,
      };
    }
  }

  async initialize(): Promise<void> {
    this.filter.skip = 0;
    this.data = [];

    await this.getEntitiesAsync();

    await this.getStates();
    await this.getBusinesUnitIds();
    await this.getBusinessUnitiesAsync();
    await this.getEntityStatusAsync();
  }

  @Watch('filter.search')
  private async debounce() {
    if (this.filter.search.length > 3 || this.filter.search.length === 0) {
      this.loading = true;
      this.filter.skip = 0;
      this.data = [];

      this.debounceSearch();

      await this.getStates();
      await this.getBusinessUnitiesAsync();
      await this.getBusinesUnitIds();
      await this.getEntityStatusAsync();
    }
  }

  get last(): number {
    let retVal = 0;
    const last = this.total - this.data.length;

    if (last < 10) {
      retVal = last;
    }

    if (last >= 10) {
      retVal = 10;
    }

    return retVal;
  }

  get entitiesBusinessFiltered(): Select[] {
    const retVal: Select[] = [];

    this.entitiesBusiness.forEach(businessUnit => {
      if (this.businessUnitIds.some(x => x.value === businessUnit.value)) {
        retVal.push({
          value: businessUnit.value,
          text: `${businessUnit.text} (${
            this.businessUnitIds.filter(x => x.value === businessUnit.value)
              .length
          })`,
        });
      }
    });

    return retVal;
  }

  async getEntitiesAsync(): Promise<void> {
    try {
      this.filter.entityType = this.entityType;
      this.filter.flgExternal = this.$route.meta?.external;
      this.loading = true;

      const response = await this.$http.get(
        `${this.endpoint}/?${qs.stringify(this.filter)}`
      );

      if (
        response.status == 200 &&
        response !== null &&
        response.data !== null &&
        response.data.data !== null
      ) {
        this.total = response.data.total;
        this.hasMore = response.data.data.length == 10;

        response.data.data.forEach(entity => {
          this.data.push(entity);
        });
      }

      this.loading = false;
    } catch (error) {
      console.log('error', error);
      this.loading = false;
    }
  }

  async getStates(): Promise<void> {
    this.filter.entityType = this.entityType;

    const resp = await this.$http.get(
      `${this.entityEndpoint.filter.states}/?${qs.stringify(this.filter)}`
    );

    if (resp.status === 200) {
      this.actives = resp.data;
    }
  }

  async getBusinesUnitIds(): Promise<void> {
    this.filter.entityType = this.entityType;
    this.loadingBusinessUnitIds = true;

    const resp = await this.$http.get(
      `${this.entityEndpoint.filter.businessUnitIds}/?${qs.stringify(
        this.filter
      )}`
    );

    if (resp.status === 200) {
      this.businessUnitIds = resp.data;
    }

    this.loadingBusinessUnitIds = false;
  }

  async getEntityStatusAsync(): Promise<void> {
    this.filter.entityType = this.entityType;
    this.loadingEntityStatus = true;

    const resp = await this.$http.get(
      `${this.entityEndpoint.filter.entityStatus}/?${qs.stringify(this.filter)}`
    );

    if (resp.status === 200) {
      this.entitiesStatus = resp.data;
    }

    this.loadingEntityStatus = false;
  }

  async getBusinessUnitiesAsync(): Promise<void> {
    const response = await this.$http.get(
      `${this.entityEndpoint.common.getByTable}/businessunities/id/txshortname`
    );

    this.entitiesBusiness = response.data;
  }

  async getMore(): Promise<void> {
    this.stopWatch = true;
    const skip = (this.filter.skip += 1);

    await this.getEntitiesAsync();
  }

  async downloadData(filtered: boolean): Promise<void> {
    this.generatingXlsx = true;

    const newFilter = Object.assign({}, this.filter);

    newFilter.skip = 0;

    if (filtered) {
      newFilter.take = this.data.length;
    } else {
      newFilter.take = 1000;
    }

    const query = this.to_qs(newFilter);
    const response = await this.$http.get(
      `${this.entityEndpoint.entity.getForXlsx}/?${query}`
    );

    const values = response.data.data.data.map(x => {
      return Object.values(x);
    });

    values.unshift(response.data.data.headers);

    const wscols: Array<any> = [];

    for (let i = 0; i < response.data.data.headers.length; i++) {
      wscols.push({ wch: response.data.data.headers[i].length + 30 });
    }

    const data = XLSX.utils.json_to_sheet(values, { skipHeader: true });

    data['!cols'] = wscols;

    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, data, 'Entities');
    XLSX.writeFile(wb, 'entities.xlsx');

    this.generatingXlsx = false;
  }

  format(k, v): any {
    return v !== null ? `${k}=${encodeURIComponent(v)}` : '';
  }

  to_qs = (obj: any) => {
    return []
      .concat(
        ...Object.entries(obj).map(([k, v]) =>
          Array.isArray(v)
            ? v.map(arr => this.to_qs({ [k]: arr }))
            : this.format(k, v)
        )
      )
      .filter(x => x)
      .join('&');
  };

  async cleanSelectBusinessUnitIds(): Promise<void> {
    this.filter.businessUnityIds = [];
    await this.initialize();
  }
}
