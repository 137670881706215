import Header from './header';

export default class EntityHeader {
  public static header: Header[] = [
    {
      text: 'Name',
      align: 'start',
      sortable: true,
      value: 'name',
      class: 'truncate',
      width: '30%'
    },
    {
      text: 'Short Name',
      align: 'start',
      sortable: true,
      value: 'shortName',
      class: 'truncate',
      width: '20%'
    },
    {
      text: 'Form Organization',
      align: 'start',
      sortable: true,
      value: 'formOrganization',
      class: 'truncate',
      width: '15%'
    },
    {
      text: 'CNPJ',
      align: 'start',
      sortable: true,
      value: 'cnpj',
      class: 'truncate',
      width: '20%'
    },
    {
      text: 'Edit',
      align: 'right',
      value: 'actions',
      sortable: false,
      class: null,
      width: '5%'
    }
  ];
}
