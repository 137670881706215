import Header from './header';

export default class InnerEntitiesChecklistHeader {
    public static innerIndexHeader: Header[] = [
        {
            text: 'Checklists',
            align: 'start',
            value: 'name',
            sortable: true,
            class: null,
            width: '25%'
        },
        {
            text: 'Interval',
            align: 'start',
            value: 'interval',
            sortable: true,
            class: null,
            width: '10%'
        },
        {
            text: 'Start At',
            align: 'start',
            value: 'startDate',
            sortable: true,
            class: null,
            width: '10%'
        },
        {
            text: 'Deadline',
            align: 'start',
            value: 'deadline',
            sortable: true,
            class: null,
            width: '10%'
        },
        {
            text: 'Task in Progress',
            align: 'start',
            value: 'task',
            sortable: true,
            class: null,
            width: '12%'
        },
        {
            text: 'Status',
            align: 'start',
            value: 'status',
            sortable: true,
            class: null,
            width: '10%'
        },
    ]
    }