const apiSecurity = process.env.VUE_APP_URL_SECURITY_API;
const api = process.env.VUE_APP_URL_CORPORATIVE_API;
const checklistApi = process.env.VUE_APP_URL_CHECKLIST_API;

export default class Endpoint {
  public apis = {
    security: apiSecurity,
    corporative: api,
  };

  public jurisdiction = {
    create: `${api}/api/jurisdiction`,
    update: `${api}/api/jurisdiction`,
    getById: `${api}/api/jurisdiction/getbyid`,
    getByFilter: `${api}/api/jurisdiction/filter`,
  };

  public businessUnit = {
    create: `${api}/api/businessUnit`,
    update: `${api}/api/businessUnit`,
    getById: `${api}/api/businessUnit/getbyid`,
    getByFilter: `${api}/api/businessUnit/filter`,
    upload: `${api}/api/businessUnit/upload`,
    progress: `${api}/api/businessUnit/upload/progress`,
  };

  public licenseType = {
    create: `${api}/api/regulatorylicensetype`,
    update: `${api}/api/regulatorylicensetype`,
    getById: `${api}/api/regulatorylicensetype/getbyid`,
    getByFilter: `${api}/api/regulatorylicensetype/filter`,
  };

  public entityType = {
    create: `${api}/api/entitytype`,
    update: `${api}/api/entitytype`,
    getById: `${api}/api/entitytype/getbyid`,
    getByFilter: `${api}/api/entitytype/filter`,
  };

  public currency = {
    create: `${api}/api/currency`,
    update: `${api}/api/currency`,
    getById: `${api}/api/currency/getbyid`,
    getByFilter: `${api}/api/currency/filter`,
  };

  public formOrganization = {
    create: `${api}/api/formorganization`,
    update: `${api}/api/formorganization`,
    getById: `${api}/api/formorganization/getbyid`,
    getByFilter: `${api}/api/formorganization/filter`,
  };

  public taxElection = {
    create: `${api}/api/taxelection`,
    update: `${api}/api/taxelection`,
    getById: `${api}/api/taxelection/getbyid`,
    getByFilter: `${api}/api/taxelection/filter`,
  };

  public taxType = {
    create: `${api}/api/taxtype`,
    update: `${api}/api/taxtype`,
    getById: `${api}/api/taxtype/getbyid`,
    getByFilter: `${api}/api/taxtype/filter`,
  };

  public regulatoryBody = {
    create: `${api}/api/regulatorybody`,
    update: `${api}/api/regulatorybody`,
    getById: `${api}/api/regulatorybody/getbyid`,
    getByFilter: `${api}/api/regulatorybody/filter`,
  };

  public entityStatus = {
    create: `${api}/api/entitystatus`,
    update: `${api}/api/entitystatus`,
    getById: `${api}/api/entitystatus/getbyid`,
    getByFilter: `${api}/api/entitystatus/filter`,
  };

  public entityRelationClassification = {
    create: `${api}/api/entityrelationclassification`,
    update: `${api}/api/entityrelationclassification`,
    getById: `${api}/api/entityrelationclassification/getbyid`,
    getByFilter: `${api}/api/entityrelationclassification/filter`,
  };

  public office = {
    getById: `${api}/api/office/getbyid`,
    update: `${api}/api/office`,
  };

  public general = {
    getByTable: `${api}/api/general/getbytable`,
    getByBusinessUnity: `${api}/api/general/getbusinessunity`,
  };

  public common = {
    getByTable: `${api}/api/common/getbytable`,
  };

  public individual = {
    create: `${api}/api/individual`,
    update: `${api}/api/individual`,
    getByTable: `${api}/api/individual/getbytable`,
    getByFilter: `${api}/api/individual/getbyfilter`,
    getById: `${api}/api/individual/getbyid`,
    getBusinessUnityIds: `${api}/api/individual/getbusinessunityids`,
    getDepartments: `${api}/api/individual/getdepartments`,
    getFunctions: `${api}/api/individual/getfunctions`,
    getByPostalCode: 'https://viacep.com.br/ws/CODE/json',
    getIndividualsIgnoreDependentes: `${api}/api/individual/GetIndividualsIgnoreDependentes`,
  };

  public select = {
    getByEntity: `${api}/api/select/selectsbyentity`,
    getIndividualEntityRelations: `${api}/api/select/entityrelationclassifications`,
  };

  public organization = {
    getOrganizations: `${api}/api/organization`,
    getOrganizationsByBu: `${api}/api/organization/businessunity`,
    getEntityTypes: `${api}/api/organization/entitytypes`,
    getRelationClassifications: `${api}/api/organization/relationclassifications`,
    getEmployeesByEntity: `${api}/api/organization/entity/employees`,
  };

  public filter = {
    states: `${api}/api/filter/states`,
    businessUnitIds: `${api}/api/filter/businessunitids`,
    entityStatus: `${api}/api/filter/status`,
    entities: `${api}/api/filter/entities`,
    createFilter: `${api}/api/filter`,
    getFilter: `${api}/api/filter`,
  };

  public entity = {
    create: `${api}/api/entity`,
    update: `${api}/api/entity`,
    delete: `${api}/api/entity`,
    getById: `${api}/api/entity`,
    getByFilter: `${api}/api/entity/filter`,
    getIdentifier: `${api}/api/entity/getidentifiers`,
    getForXlsx: `${api}/api/entity/getforxlsx`,
  };

  public client = {
    create: `${api}/api/client`,
    update: `${api}/api/client`,
    getById: `${api}/api/client`,
    getByFilter: `${api}/api/client/getbyfilter`,
  };

  // public auth = {
  //   getMenus: `${apiSecurity}/api/user/menus`
  // };

  public auth = {
    getMenus: `${api}/api/auth/menus`,
  };

  public checklist = {
    list: `${checklistApi}/api/Checklist`,
    getById: `${checklistApi}/api/Checklist/GetChecklistByKey`,
    duplicateWithId: `${checklistApi}/api/Checklist/DuplicateChecklistByKey`,
  };

  public entities = {
    list: `${checklistApi}/api/ChecklistEntity`,
  };

  public triggers = {
    list: `${checklistApi}/api/Trigger`,
  };

  public entitiesModal = {
    list: `${checklistApi}/api/Entities`,
  };

  public teams = {
    list: `${checklistApi}/api/Team`,
  };

  public owners = {
    list: `${checklistApi}/api/Owner`,
  };

  public note = {
    stageNote: `${checklistApi}/api/ChecklistEntityStageNote`
  };

  public attachments = {
    stageAttachments: `${checklistApi}/api/ChecklistEntityStageAttachment`,
  };

  public attachmentList = {
    listAttachment: `${checklistApi}/api/ChecklistEntityStageAttachment/ListChecklistEntityStageAttachmentGetByKey`
  };
  public attachmentDownload = {
    download: `${checklistApi}/api/ChecklistEntityStageAttachment/download`
  };
  public activyLogList = {
    log: `${checklistApi}/api/ChecklistEntityStageLog`
  };
  public deleteAttachment = {
    delete: `${checklistApi}/api/ChecklistEntityStageAttachment`
  };
  public putFinish = {
    finish: `${checklistApi}/finish`
  };
  public disable = {
    status: `${checklistApi}/api/Checklist/disable`
  };
  public enable = {
    status: `${checklistApi}/api/Checklist/enable`
  }
  public linkSend = {
    link: `${checklistApi}/api/ChecklistEntityStageLink`
  }
  public save = {
    draft: `${checklistApi}/api/Checklist/draft`
  }
  public runCicle = {
    cicle: `${checklistApi}/api/Checklist/RunCicle`
  }
  public setExpected = {
    expected: `${checklistApi}/setExpectedDeadline`
  }
  public subTask = {
    create: `${checklistApi}/api/ChecklistSubstask`,
    entities: `${checklistApi}/api/ChecklistSubstask/Entities`,
    delete: `${checklistApi}/api/ChecklistSubstask`, 
    view: `${checklistApi}/api/ChecklistSubstask`,
    list: `${checklistApi}/api/ChecklistSubstask/subtasks`
  }

  public subTaskNote = {
    note: `${checklistApi}/api/ChecklistSubtaskNote`
  }

  public subTaskLink = {
    link: `${checklistApi}/api/ChecklistSubtaskLink`
  }

  public subTaskAttachment = {
    attachment: `${checklistApi}/api/ChecklistSubtaskAttachment`,
    list: `${checklistApi}/api/ChecklistSubtaskAttachment/ListChecklistSubtaskAttachmentGetByKey`,
    download: `${checklistApi}/api/ChecklistSubtaskAttachment/download`
  }

  public subTaskLog = {
    log: `${checklistApi}/api/ChecklistSubtaskLog`
  }

  public subTaskFinish = {
    finish: `${checklistApi}/api/ChecklistSubstask/finish`
  }
}
