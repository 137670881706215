import LinkData from '@/src/types/organization/linkData';
import Node from '@/src/types/organization/node';
import NodeData from '@/src/types/organization/nodeData';
import NodeInfo from '@/src/types/organization/nodeInfo';
import { AppState } from './types';

export default {
  getNode(state: AppState): Node {
    return state.node;
  },
  getNodeData(state: AppState): NodeData[] {
    return state.nodeData;
  },
  getLinkData(state: AppState): LinkData[] {
    return state.linkData;
  },
  getNodeInfo(state: AppState): NodeInfo {
    return state.nodeInfo;
  }
};
