
  import { Component, Watch, Prop, Vue } from 'vue-property-decorator';
  import { Getter } from 'vuex-class';

  import Endpoint from '../../utils/endpoint';
  import Employee from '@/src/types/individual/employee';
  import SelectNumber from '@/src/types/app/generic/selectNumber';

  import Date from './Date.vue';
  import Select from '@/src/types/app/generic/select';

  @Component({
    components: {
      Date: Date
    }
  })
  export default class DependentComponent extends Vue {
    endpoint = new Endpoint();

    @Getter('getEmployee', { namespace: 'individual' }) employee!: Employee;
    @Getter('getOriginalEmployee', { namespace: 'individual' })
    originalEmployee!: Employee;

    gettingDegrees = true;

    degrees: Array<Select> = [];
    genders: Array<SelectNumber> = [
      {
        text: 'MALE',
        value: 0
      },
      {
        text: 'FEMALE',
        value: 1
      }
    ];

    async created() {
      await this.getDegreesAsync();
    }

    get isChanged(): boolean {
      const changed = this.$toHash(JSON.stringify(this.employee.dependents));
      const original = this.$toHash(JSON.stringify(this.originalEmployee.dependents));

      return changed !== original;
    }

    async getDegreesAsync(): Promise<void> {
      const response = await this.$http.get(`${this.endpoint.individual.getByTable}/DegreeRelatedness/id/txname`);

      if (response.status === 200) {
        this.degrees = response.data;
      }

      this.gettingDegrees = false;
    }

    addDependent(): void {
      if (this.employee.dependents === null) this.employee.dependents = [];

      this.employee.dependents.push({
        individualId: null,
        name: null,
        socialName: null,
        bornDate: null,
        gender: null,
        naturalness: null,
        nationality: null,
        degreeRelatednessId: null
      });
    }

    removeDependent(index: number): void {
      this.employee.dependents.splice(index, 1);
    }

    cleanSelect(index: number): void {
      this.employee.dependents[index].gender = null;
    }

    cleanSelectDegree(index: number): void {
      this.employee.dependents[index].degreeRelatednessId = null;
    }
  }
