
  import { Component, Watch, Prop, Vue } from 'vue-property-decorator';
  import { Getter, Action } from 'vuex-class';

  import Individual from '@/src/types/individual/individual';
  import SelectNumber from '@/src/types/app/generic/selectNumber';

  @Component({})
  export default class GenderComponent extends Vue {
    @Getter('getIndividual', { namespace: 'individual' }) individual!: Individual;
    @Getter('getOriginalIndividual', { namespace: 'individual' })
    originalIndividual!: Individual;

    genders: Array<SelectNumber> = [
      {
        text: 'MALE',
        value: 0
      },
      {
        text: 'FEMALE',
        value: 1
      }
    ];

    cleanSelect(): void {
      this.individual.gender = null;
    }
  }
