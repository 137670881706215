import { ActionContext } from 'vuex';
import { AppState } from './types';

import Snackbar from '../../types/app/application/Snackbar';
import Filter from '@/src/types/app/application/filter';

export default {
  setSnackbar(store: ActionContext<AppState, any>, snackbar: Snackbar): void {
    store.commit('setSnackbar', snackbar);
  },
  setFilter(store: ActionContext<AppState, any>, filter: Filter): void {
    store.commit('setFilter', filter);
  },
  setFilterSkip(store: ActionContext<AppState, any>, skip: number): void {
    store.commit('setFilterSkip', skip);
  },
  setFullScreen(store: ActionContext<AppState, any>, full: boolean): void {
    store.commit('setFullScreen', full);
  },
  setOverlay(store: ActionContext<AppState, any>, overlay: boolean): void {
    store.commit('setOverlay', overlay);
  }
};
