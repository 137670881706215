
import { Component, Vue, Prop } from 'vue-property-decorator';
import { Getter, Action } from 'vuex-class';

import User from '../../../types/auth/User';

@Component({})
export default class ModalDisableStatus extends Vue {
  @Getter('getDisableModal', { namespace: 'checklist' })
  disableStatusModal!: boolean;
  @Action('setDisableModal', { namespace: 'checklist' }) setDisableModal!: any;

  @Getter('getChecklistKeyId', { namespace: 'checklist' })
  checklistKeyId!: boolean;
  @Action('setChecklistKeyId', { namespace: 'checklist' })
  setChecklistKeyId!: any;

  @Getter('getUser', { namespace: 'auth' }) user!: User;

  @Prop(Boolean) readonly loading: boolean | undefined;

  public async disableStatus() {
    const params = {
      checklistKey: this.checklistKeyId,
      userUpdatedLogin: this.user.email,
    };
    this.$emit('disableStatus', params);
    this.$emit('disableStatusView');
  }

  closeModal() {
    this.setDisableModal(false);
  }
}
