import Header from './header';

export default class IndividualHeader {
  public static header: Header[] = [
    {
      text: 'Name',
      align: 'start',
      sortable: true,
      value: 'name',
      class: 'truncate',
      width: '45%'
    },
    // {
    //   text: 'Nickname',
    //   align: 'start',
    //   sortable: true,
    //   value: 'nickName',
    //   class: 'truncate',
    //   width: ''
    // },
    {
      text: 'Gender',
      align: 'start',
      sortable: true,
      value: 'gender',
      class: 'truncate',
      width: ''
    },
    {
      text: 'Nationality',
      align: 'start',
      sortable: true,
      value: 'nationality',
      class: 'truncate',
      width: ''
    },
    {
      text: 'Admission Date',
      align: 'start',
      sortable: true,
      value: 'admissionDate',
      class: 'truncate',
      width: ''
    },
    {
      text: 'Edit',
      align: 'right',
      value: 'actions',
      sortable: false,
      class: null,
      width: '5%'
    }
  ];
}
