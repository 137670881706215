
  import { Component, Prop, Vue } from 'vue-property-decorator';
  import { Getter } from 'vuex-class';

  import Entity from '@/src/types/entities/entity';

  @Component({})
  export default class EndFiscalYearComponent extends Vue {
    @Getter('getOriginalEntity', { namespace: 'entity' }) originalEntity!: Entity;
    @Getter('getEntity', { namespace: 'entity' })
    entity!: Entity;

    @Prop({ type: Array })
    rules;

    months: Array<any> = [];

    created() {
      const dateRef = new Date();

      for (let i = 0; i < 12; i++) {
        const date = new Date(dateRef.getFullYear(), i, 1);
        const month = date.toLocaleString('en-US', { month: 'long' });

        this.months.push({
          text: month,
          value: i + 1
        });
      }
    }

    cleanSelect(): void {
      this.entity.endFiscalYear = null;
    }
  }
