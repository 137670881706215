import Client from '@/src/types/client/client';
import Employee from '@/src/types/individual/employee';
import Individual from '@/src/types/individual/individual';
import { AppState } from './types';

export default {
  getIndividual(state: AppState): Individual {
    return state.individual;
  },
  getEmployee(state: AppState): Employee {
    return state.employee;
  },
  getOriginalIndividual(state: AppState): Individual {
    return state.originalIndividual;
  },
  getOriginalEmployee(state: AppState): Employee {
    return state.originalEmployee;
  },
  getClient(state: AppState): Client {
    return state.client;
  },
  getOriginalClient(state: AppState): Client {
    return state.originalClient;
  }
};
