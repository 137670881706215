
  import { Component, Prop, Vue } from 'vue-property-decorator';
  import { Getter } from 'vuex-class';

  @Component({})
  export default class CommandsComponent extends Vue {
    @Getter('canCreate', { namespace: 'auth' }) canCreate!: boolean;
    @Getter('canUpdate', { namespace: 'auth' }) canUpdate!: boolean;
    @Getter('canDelete', { namespace: 'auth' }) canDelete!: boolean;

    @Prop({ type: Function })
    save;

    @Prop({ type: Function })
    remove;

    @Prop({ type: String })
    to;

    @Prop({ type: Boolean })
    saving;

    @Prop({ type: String })
    state;
  }
