const clientId = process.env.VUE_APP_CLIENT_ID;
const authority = process.env.VUE_APP_AUTHORITY;
const redirectUri = process.env.VUE_APP_URL_REDIRECT;
const redirectLogoutUri = process.env.VUE_APP_URL_REDIRECT_LOGOUT;
const scope = process.env.VUE_APP_SCOPE;
const user_impersonation_scope = process.env.VUE_APP_SCOPE_IMPERSONATION;

import * as Msal from '@azure/msal-browser';
import User from '../types/auth/User';

const msalConfig = {
  auth: {
    clientId: clientId,
    authority: authority,
    redirectUri: redirectUri,
  },
  cache: {
    cacheLocation: 'localStorage',
    storeAuthStateInCookie: true,
  },
} as Msal.Configuration;

const loginRequest = {
  scopes: ['openid', 'profile'],
};

const tokenRequest: any = {
  scopes: [user_impersonation_scope],
  forceRefresh: false,
};

const tokenSecurityRequest: any = {
  scopes: ['https://leste-security.azurewebsites.net/user_impersonation'],
  forceRefresh: true,
};

const graphRequest: any = {
  scopes: ['User.Read'],
};

export class AuthService {
  msalInstance: Msal.PublicClientApplication;

  constructor() {
    this.msalInstance = new Msal.PublicClientApplication(msalConfig);
  }

  public async getAllAccountsAsync(): Promise<any[]> {
    const retVal = this.msalInstance.getAllAccounts();
    return retVal;
  }

  public async logoutAsync() {
    // const accounts = this.msalInstance.getAllAccounts();

    // const logoutRequest: any = {
    //   account: this.msalInstance.getAccountByUsername(accounts[0].username)
    // };

    // this.msalInstance.logout(logoutRequest);

    await this.msalInstance.logout({
      postLogoutRedirectUri: redirectLogoutUri,
    });
  }

  public async getAccountsAsync(): Promise<any> {
    try {
      const accounts = this.msalInstance.getAllAccounts();
      console.log('getAccountsAsync', accounts);

      if (accounts && accounts.length > 1) {
        return accounts;
      } else {
        return [];
      }
    } catch (error) {
      console.log('authService: getAccountsAsync()', error);
      return [];
    }
  }

  public async LoginByAccountAsync(account: any): Promise<string | null> {
    try {
      tokenRequest.account = account;

      const response = await this.msalInstance.acquireTokenSilent(tokenRequest);

      return response.accessToken;
    } catch (error) {
      console.log('authService: LoginByAccountAsync()', error);
      await this.msalInstance.logout();
      return null;
    }
  }

  public async loginAsync(): Promise<string | null> {
    try {
      const response = await this.msalInstance.loginPopup(loginRequest);

      return response.accessToken;
    } catch (error) {
      console.log('authService I: loginAsync()', error);
      return null;
    }
  }

  public async getTokenForRequiredAsync(): Promise<string | null> {
    try {
      const accounts = this.msalInstance.getAllAccounts();
      if (accounts.length === 0) {
        return null;
      }

      if (accounts.length > 1) {
        return null;
      }

      tokenRequest.account = accounts[0];

      const response = await this.msalInstance.acquireTokenSilent(tokenRequest);

      return response.accessToken;
    } catch (error) {
      console.log('authService: getTokenForRequiredAsync()', error);
      return null;
    }
  }

  public async getTokenForMenuAsync(): Promise<string | null> {
    try {
      const accounts = this.msalInstance.getAllAccounts();

      if (accounts.length === 0) {
        return null;
      }

      if (accounts.length > 1) {
        return null;
      }

      tokenSecurityRequest.account = accounts[0];

      const response = await this.msalInstance.acquireTokenSilent(
        tokenSecurityRequest
      );

      return response.accessToken;
    } catch (error) {
      console.log('authService: getTokenForMenuAsync()', error);

      const response = await this.msalInstance.acquireTokenPopup(
        tokenSecurityRequest
      );

      return response.accessToken;
    }
  }

  public getTokenData(): User | null {
    try {
      const currentAccounts = this.msalInstance.getAllAccounts();
      if (currentAccounts.length === 0) {
        return null;
      }

      if (currentAccounts.length === 1) {
        const data = this.msalInstance.getAccountByUsername(
          currentAccounts[0].username
        );

        return {
          name: data?.name,
          email: data?.username,
        } as User;
      }

      return null;
    } catch (error) {
      console.log('authService: getTokenData()', error);

      return null;
    }
  }

  public async getTokenGraph(): Promise<string | null> {
    try {
      const accounts = this.msalInstance.getAllAccounts();
  
      if (!accounts || accounts.length === 0 || accounts.length > 1) {
        return null;
      }
  
      graphRequest.account = accounts[0];
      const response = await this.msalInstance.acquireTokenSilent(graphRequest);
  
      return response.accessToken;
    } catch (error) {
      console.log('authService I: getTokenGraph()', error);
      if (error.errorCode === 'invalid_grant') {
        try {
          const accounts = this.msalInstance.getAllAccounts();
  
          graphRequest.account = accounts[0];
          const response = await this.msalInstance.acquireTokenPopup(
            graphRequest
          );
  
          return response.accessToken;
        } catch (error) {
          console.log('authService II: getTokenGraph()', error);
          return null;
        }
      }
      console.log(
        'authService: getTokenGraph(): ',
        JSON.parse(JSON.stringify(error))
      );
      return null;
    }
  }
}
