
import { Component, Vue, Prop } from 'vue-property-decorator';
import { Getter, Action } from 'vuex-class';

@Component({})
export default class ModalSaveFinished extends Vue {
  @Getter('getLinkFieldFull', { namespace: 'checklist' })
  linkFieldFull!: boolean;
  @Action('setLinkFieldFull', { namespace: 'checklist' })
  setLinkFieldFull!: any;

  @Getter('getChecklistEntityStageKey', { namespace: 'checklist' })
  checklistEntityStageKey!: string;

  @Getter('getNote', { namespace: 'checklist' })
  public note!: string;
  @Action('setNote', { namespace: 'checklist' }) public setNote!: string;

  @Prop(Boolean) readonly loading: boolean | undefined;

  @Prop(String) linkField: string | undefined;

  public postLink() {
    this.$emit('postLink');
  }

  closeModal() {
    this.$emit('closeModal');
  }
}
