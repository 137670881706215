
  import { Component, Vue } from 'vue-property-decorator';
  import { Getter, Action } from 'vuex-class';

  import Header from '../../types/app/headers/header';
  import GeneralHeader from '../../types/app/headers/GeneralHeader';
  import GenericFilter from '../../components/generics/GenericFilter.vue';
  import Filter from '@/src/types/app/application/filter';

  import Endpoint from '../../utils/endpoint';
  import { debounce } from 'lodash';
  import { Watch } from 'vue-property-decorator';
  import qs from 'qs';

  @Component({
    components: {
      GenericFilter
    }
  })
  export default class EntityTypes extends Vue {
    endpoint = new Endpoint();
    headers: Array<Header> = GeneralHeader.header;

    @Getter('canCreate', { namespace: 'auth' }) canCreate!: boolean;
    @Getter('canRead', { namespace: 'auth' }) canRead!: boolean;
    @Getter('getFilter', { namespace: 'app' }) filter!: Filter;

    @Action('setFilter', { namespace: 'app' }) setFilter!: any;
    @Action('setFilterSkip', { namespace: 'app' }) setFilterSkip!: any;

    debounceSearch: any = null;
    loading = false;
    loadingMore = false;
    hasMore = true;

    entityTypes: any[] = [];

    created() {
      this.debounceSearch = debounce(this.getEntityTypes, 1000);
      this.getEntityTypes();
    }

    @Watch('filter.search')
    private async filtering() {
      if (this.filter.search != null) {
        this.setFilterSkip(0);

        this.entityTypes = [];
        this.debounceSearch();
      }
    }

    getByFilter() {
      this.setFilterSkip(0);

      this.entityTypes = [];
      this.getEntityTypes();
    }

    cleanFilter() {
      this.setFilter({
        skip: 0,
        take: 10,
        search: null,
        active: null
      });

      this.entityTypes = [];
      this.getEntityTypes();
    }

    getEntityTypes(): void {
      this.loading = true;

      this.$http
        .get(`${this.endpoint.entityType.getByFilter}?${qs.stringify(this.filter)}`)
        .then(res => {
          res.data.data.forEach(entityType => {
            entityType.activeText = entityType.active == true ? 'Active' : 'Inactive';
            this.entityTypes.push(entityType);
          });

          this.hasMore = res.data.data.length >= 10;
          this.loading = false;
        })
        .catch(err => {
          this.loading = false;
          console.log('error response', err);
        });
    }

    getMore(): void {
      const skip = (this.filter.skip += 1);

      this.setFilterSkip(skip);
      this.getEntityTypes();
    }

    goToDEtail(item: any): void {
      this.$router.push({ name: 'EntityType', params: { id: item.id } });
    }
  }
