import _Vue from 'vue';

function format(k, v) {
  return v !== null ? `${k}=${encodeURIComponent(v)}` : '';
}

function toQs(obj: any) {
  const concat = Object.entries(obj).map(([k, v]) =>
    Array.isArray(v) ? v.map(arr => toQs({ [k]: arr })) : format(k, v)
  );

  const retVal = []
    .concat(...concat)
    .filter(x => x)
    .join('&');

  return retVal;
}

export function Utils(Vue: typeof _Vue): void {
  Vue.prototype.$parseDate = (date: string | null) => {
    if (date == null || date.length < 10) return null;
    console.log('dataaa', date);

    const [m, d, y] = date.split('/');

    const month = parseInt(m) - 1;
    const day = parseInt(d);
    const year = parseInt(y);

    return new Date(year, month, day);
  };

  Vue.prototype.$formatDate = (date: Date) => {
    if (!date) return null;

    const [year, month, day] = date
      .toISOString()
      .substr(0, 10)
      .split('-');

    return `${month}/${day}/${year}`;
  };

  Vue.prototype.$onlyNumber = (evt: any) => {
    const numbers = '0123456789';
    if (numbers.indexOf(evt.key) === -1) {
      evt.preventDefault();
    }
  };

  Vue.prototype.$controlPercent = (evt: any) => {
    const numbers = '0123456789';
    if (numbers.indexOf(evt.key) === -1) {
      evt.preventDefault();
    }

    if (evt.target.value > 100) {
      evt.target.value = 100;
      evt.preventDefault();
    }
  };

  Vue.prototype.$formatCurrency = (value: number | null, isValueActive: boolean) => {
    return !value || !isValueActive
      ? ''
      : value.toLocaleString('en-US', {
          style: 'currency',
          currency: 'USD'
        });
  };

  Vue.prototype.$cutText = (source: string, size: number) => {
    return source.length > size ? source.slice(0, size - 1) + '…' : source;
  };

  Vue.prototype.$toHash = (source: string) => {
    return source.split('').reduce(function(a, b) {
      a = (a << 5) - a + b.charCodeAt(0);
      return a & a;
    }, 0);
  };

  Vue.prototype.$to_qs = (obj: any) => {
    return toQs(obj);
  };
}

declare module 'vue/types/vue' {
  interface Vue {
    $parseDate(date: string | null): Date | null;
    $formatDate(date: Date | null): string | null;
    $onlyNumber(evt: any): void;
    $controlPercent(evt: any): void;
    $formatCurrency(value: number | null, isValueActive: boolean): string;
    $cutText(source: string, size: number): string;
    $toHash(source: string): number;
    $to_qs(obj: any): string;
  }
}
