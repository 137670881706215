
  import Filter from '@/src/types/app/application/filter';
  import { Component, Prop, Vue } from 'vue-property-decorator';
  import { Getter, Action } from 'vuex-class';

  @Component({})
  export default class GenericFilter extends Vue {
    @Getter('getFilter', { namespace: 'app' }) filter!: Filter;

    @Prop({ type: Function })
    getByFilter;

    @Prop({ type: Function })
    cleanFilter;
  }
