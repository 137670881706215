import Header from './header';

export default class ChecklistHeaderDetails {
  public static indexHeader: Header[] = [
    {
      text: 'Name',
      align: 'start',
      sortable: true,
      value: 'companyName',
      class: null,
      width: '',
    },
  ];
}
