import { UserState } from './types';
import User from '../../types/auth/User';
import Area from '@/src/types/auth/area';
import router from '../../router';
import Auth from '../../utils/auth';
import CurrentRoute from '@/src/types/auth/currentRoute';

export default {
  getUser(state: UserState): User {
    return state.user;
  },
  getAreas(state: UserState): Area[] {
    return state.areas !== null ? state.areas : [];
  },
  getIslogged(state: UserState): boolean {
    return state.user != null ? true : false;
  },
  getErrorStatus(state: UserState): boolean {
    return state.error;
  },
  getCurrentRoute(state: UserState): CurrentRoute {
    return state.currentRoute;
  },
  canCreate(state: UserState): boolean {

    if (state.areas != null) {
      return Auth.verifyPermission(state.areas, state.currentRoute.parent, state.currentRoute.name, 'Create');
    }

    return false;
  },
  canUpdate(state: UserState): boolean {
    if (state.areas != null) {
      return Auth.verifyPermission(state.areas, state.currentRoute.parent, state.currentRoute.name, 'Update');
    }

    return false;
  },
  canRead(state: UserState): boolean {
    if (state.areas != null) {
      return Auth.verifyPermission(state.areas, state.currentRoute.parent, state.currentRoute.name, 'Update');
    }

    return false;
  },
  canDelete(state: UserState): boolean {
    if (state.areas != null) {
      return Auth.verifyPermission(state.areas, state.currentRoute.parent, state.currentRoute.name, 'Delete');
    }

    return false;
  }
};
