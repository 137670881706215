import Header from './header';

export default class ChecklistStageTaskHeader {
  public static indexHeader: Header[] = [
    {
      text: 'Title',
      align: 'start',
      sortable: false,
      value: 'title',
      class: 'truncate',
      width: '30%'
    },
    {
      text: 'Estimated',
      align: 'center',
      sortable: false,
      value: 'estimated',
      class: 'truncate',
      width: '15%'
    },
    {
      text: 'Is Complete',
      align: 'center',
      sortable: false,
      value: 'isComplete',
      class: 'truncate',
      width: '15%'
    },
    {
      text: 'Start Date',
      align: 'center',
      sortable: false,
      value: 'startDate',
      class: 'truncate',
      width: '15%'
    }
  ];
}
