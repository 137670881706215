
  import { Component, Watch, Prop, Vue } from 'vue-property-decorator';
  import { Getter, Action } from 'vuex-class';

  import Endpoint from '../../utils/endpoint';
  import Select from '../../types/app/generic/select';
  import Employee from '@/src/types/individual/employee';

  import BusinessUnitDate from '../../components/individual/BusinesUnitDate.vue';

  @Component({
    components: {
      BusinessUnitDate: BusinessUnitDate
    }
  })
  export default class IndividualUnityBusinessComponent extends Vue {
    endpoint = new Endpoint();

    @Getter('getEmployee', { namespace: 'individual' }) employee!: Employee;
    @Getter('getOriginalEmployee', { namespace: 'individual' })
    originalEmployee!: Employee;

    @Prop({ type: Array })
    rules;

    menu = false;
    entitiesBusiness: Array<Select> = [];
    departments: Array<Select> = [];
    functions: Array<Select> = [];

    async created() {
      await this.getEntityEntityUnityBusinesssAsync();
      await this.getDepartmentsAsync();
      await this.getFunctionsAsync();
    }

    async getEntityEntityUnityBusinesssAsync(): Promise<void> {
      const response = await this.$http.get(`${this.endpoint.common.getByTable}/businessunities/id/txname`);

      if (response.status === 200) {
        this.entitiesBusiness = response.data;
      }
    }

    async getDepartmentsAsync(): Promise<void> {
      const response = await this.$http.get(`${this.endpoint.individual.getByTable}/departments/id/txname`);

      if (response.status === 200) {
        this.departments = response.data;
      }
    }

    async getFunctionsAsync(): Promise<void> {
      const response = await this.$http.get(`${this.endpoint.individual.getByTable}/functions/id/txname`);

      if (response.status === 200) {
        this.functions = response.data;
      }
    }

    cleanSelect(): void {
      console.log('arrumar');

      //this.unity.businessUnityId = null;
    }

    addBusinessUnit(): void {
      if (this.employee.individualUnityBusiness === null) this.employee.individualUnityBusiness = [];
      this.employee.individualUnityBusiness.push({
        departmentId: null,
        functionId: null,
        businessUnityId: null,
        effectiveStartDate: null,
        effectiveEndDate: null
      });
    }

    removeBusiness(index: number): void {
      this.employee.individualUnityBusiness.splice(index, 1);
    }
  }
