
  import { Component, Watch, Prop, Vue, Ref } from 'vue-property-decorator';
  import { Getter, Action } from 'vuex-class';

  import Individual from '@/src/types/individual/individual';
  import SelectNumber from '@/src/types/app/generic/selectNumber';

  @Component({})
  export default class BornDateComponent extends Vue {
    @Getter('getIndividual', { namespace: 'individual' }) individual!: Individual;
    @Getter('getOriginalIndividual', { namespace: 'individual' })
    originalIndividual!: Individual;

    menu = false;

    get bornDate(): string | null {
      return this.individual.bornDate
        ? this.formatDate(new Date(this.individual.bornDate).toISOString().substr(0, 10))
        : null;
    }
    set bornDate(value: string | null) {
      this.individual.bornDate = this.parseDate(value);
    }

    get originalBornDate(): string | null {
      if (this.originalIndividual.bornDate !== null) {
        return this.formatDate(new Date(this.originalIndividual.bornDate).toISOString().substr(0, 10));
      }

      return null;
    }

    @Watch('menu')
    private async mnu(val) {
      val && setTimeout(() => ((this.$refs['picker'] as any).activePicker = 'YEAR'));
    }

    formatDate(date): any {
      if (!date) return null;

      const [year, month, day] = date.split('-');
      return `${month}/${day}/${year}`;
    }

    parseDate(date): any {
      if (!date) return null;

      const [month, day, year] = date.split('/');
      return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`;
    }
  }
