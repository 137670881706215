import Header from './header';

export default class InnerChecklistHeader {
    public static innerIndexHeader: Header[] = [
        {
            text: 'Entity',
            align: 'start',
            value: 'name',
            sortable: true,
            class: null,
            width: '25%'
        },
        {
            text: 'Deadline',
            align: 'start',
            value: 'deadline',
            sortable: true,
            class: null,
            width: '10%'
        },
        {
            text: 'Task in Progress',
            align: 'start',
            value: 'task',
            sortable: true,
            class: null,
            width: '10%'
        },
        {
            text: 'Status',
            align: 'start',
            value: 'status',
            sortable: true,
            class: null,
            width: '10%'
        },
    ]
    }