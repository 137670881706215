
import { Component, Vue } from 'vue-property-decorator';
import { Getter, Action } from 'vuex-class';
import Endpoint from '../../utils/endpoint';

import Client from '@/src/types/client/client';
import Individual from '@/src/types/individual/individual';
import Gender from '../../components/individual/Gender.vue';
import BornDate from '../../components/individual/BornDate.vue';
import ParentName from '../../components/individual/ParentName.vue';
import Email from '../../components/individual/Email.vue';
import Phone from '../../components/individual/Phone.vue';
import Document from '../../components/individual/Document.vue';
import Address from '../../components/individual/Address.vue';

import CommandsComponent from '../../components/generics/Commands.vue';

@Component({
  components: {
    Gender: Gender,
    BornDate: BornDate,
    ParentName: ParentName,
    Email: Email,
    Phone: Phone,
    Document: Document,
    Address: Address,
    CommandsComponent: CommandsComponent,
  },
})
export default class ClientForm extends Vue {
  endpoint = new Endpoint();

  @Getter('getIndividual', { namespace: 'individual' }) individual!: Individual;
  @Getter('getOriginalIndividual', { namespace: 'individual' })
  originalIndividual!: Individual;

  @Getter('getClient', { namespace: 'individual' }) client!: Client;
  @Getter('getOriginalClient', { namespace: 'individual' })
  originalClient!: Client;

  @Action('setSnackbar', { namespace: 'app' }) setSnackbar!: any;
  @Action('setIndividual', { namespace: 'individual' }) setIndividual!: any;
  @Action('setClient', { namespace: 'individual' }) setClient!: any;

  isCreate = true;
  valid = null;
  saving = false;
  loading = false;
  typesClient = [
    {
      text: 'Physical',
      value: 1,
    },
    {
      text: 'Legal',
      value: 2,
    },
  ];
  rules = [
    v => !!v || 'This field is required',
    //v => (v && v.length <= 10) || 'Name must be less than 10 characters'
  ];

  dateRules = [
    (v: string) => {
      const message = 'Invalid date mm/dd/yyyy';

      if (v == null) return true;

      if (v.length < 10) return message;

      const [m, d, y] = v.split('/');

      const month = parseInt(m);
      const day = parseInt(d);
      const year = parseInt(y);

      if (day < 1 || day > 31 || day === 0) return message;

      if ((month < 1 && month > 12) || month === 0) return message;

      if (year < 1920 || year > 2100 || year === 0) return message;

      return true;
    },
  ];

  async created() {
    this.isCreate = !this.$route.params.id;
    if (!this.isCreate) {
      this.loading = true;
      await this.getClientAsync();
    }
  }

  get mode(): string {
    return this.isCreate ? 'Create' : 'Update';
  }

  async getClientAsync(): Promise<void> {
    try {
      const resp = await this.$http.get(
        `${this.endpoint.client.getById}/${this.$route.params.id}`
      );
      console.log('resp', JSON.parse(JSON.stringify(resp)));

      if (!resp.data.hasStatusErrors) {
        this.setClient({
          id: resp.data.data.id,
          typeClient: resp.data.data.typeClient,
          active: true,
        });

        this.setIndividual({
          id: resp.data.data.individual.id,
          name: resp.data.data.individual.name,
          socialName: resp.data.data.individual.socialName,
          bornDate: resp.data.data.individual.bornDate,
          gender: resp.data.data.individual.gender,
          naturalness: resp.data.data.individual.naturalness,
          nationality: resp.data.data.individual.nationality,
          observation: resp.data.data.individual.observation,
          emails: resp.data.data.individual.emails,
          phones: resp.data.data.individual.phones,
          addresses: resp.data.data.individual.addresses,
          documents: resp.data.data.individual.documents,
        });
      }

      this.loading = false;
    } catch (error) {
      console.log('error', error);
    }
  }

  async saveAsync(): Promise<void> {
    if ((this.$refs.form as Vue & { validate: () => boolean }).validate()) {
      this.saving = true;
      const client = {
        id: null,
        typeClient: this.client.typeClient,
        active: this.client.active,
        individual: this.individual,
      };

      let resp: any = null;
      if (this.isCreate) {
        resp = await this.createAsync(client);
      } else {
        resp = await this.updateAsync(client);
      }

      if (!resp.data.hasStatusErrors) {
        this.setSnackbar({
          show: true,
          message: `${this.$route.meta?.title} ${
            this.isCreate ? 'created' : 'updated'
          } with Success`,
          color: 'success',
        });

        this.$router.push({ name: this.$route.meta?.before });
      }

      if (resp.data.hasStatusErrors) {
        this.setSnackbar({
          show: true,
          message: `Error to ${this.isCreate ? 'create' : 'update'} ${
            this.$route.meta?.title
          }`,
          color: 'error',
        });
      }

      this.saving = false;
    }
  }

  async createAsync(client: Client): Promise<any> {
    try {
      return await this.$http.post(this.endpoint.client.create, {
        request: client,
      });
    } catch (error) {
      console.log('error', error);
      this.setSnackbar({
        show: true,
        message: `Error to ${this.isCreate ? 'create' : 'update'} ${
          this.$route.meta?.title
        }`,
        color: 'error',
      });
      this.saving = false;
    }
  }

  //   async getApi(){

  //     const response = await this.$http.get(this.endpoint.financialPlain, {
  //   //   headers: { Authorization: token },
  //   // });)
  //   //   async getFinancialPlain() {
  //   // const token = await this.authService.getTokenForMenuAsync();
  //   // this.loading = true;
  //   // const response = await this.$http.get(this.endpoint.financialPlain, {
  //   //   headers: { Authorization: token },
  //   // });
  //   // const items = response;
  //   // console.log(items.companies);
  //   // this.items = items;
  //   // this.loading = false;
  // }
  //   }

  async updateAsync(client: Client): Promise<any> {
    try {
      client.id = this.$route.params.id;
      return await this.$http.put(this.endpoint.client.update, {
        request: client,
      });
    } catch (error) {
      console.log('error', error);
      this.setSnackbar({
        show: true,
        message: `Error to ${this.isCreate ? 'create' : 'update'} ${
          this.$route.meta?.title
        }`,
        color: 'error',
      });
      this.saving = false;
    }
  }

  goToIndex(): string {
    return this.$route.meta?.index;
  }
}
