
import { Component, Vue, Prop } from 'vue-property-decorator';
import { Getter, Action } from 'vuex-class';
import { Watch } from 'vue-property-decorator';

import Endpoint from '../../../utils/endpoint';

import User from '../../../types/auth/User';
import Checklist from '../../../types/checklist/checklist';

@Component({})
export default class ModalSaveDraft extends Vue {
  private endpoint = new Endpoint();
  @Getter('getSaveDraftModal', { namespace: 'checklist' })
  saveDraftModal!: boolean;
  @Action('setSaveDraftModal', { namespace: 'checklist' })
  setSaveDraftModal!: any;
  @Getter('getUser', { namespace: 'auth' }) user!: User;

  @Prop(Boolean) readonly loading: boolean | undefined;
  @Prop(Boolean) readonly triggersSelectedHasCompany!: boolean;

  async saveDraft() {
    this.$emit('saveDraft');
  }

  get setModalText() {
    return this.triggersSelectedHasCompany
      ? 'Do you want to save this checklist?'
      : 'The selected triggers has none companies associated.<br />Save the checklist anyway?';
  }

  get setModalWidth() {
    return this.triggersSelectedHasCompany ? '481px' : '630px';
  }

  get setModalClass() {
    return this.triggersSelectedHasCompany
      ? 'modal-checklist'
      : 'modal-no-entities';
  }

  closeModal() {
    this.setSaveDraftModal(false);
  }
}
