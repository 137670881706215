
  import { Component, Ref, Vue } from 'vue-property-decorator';
  import { Getter } from 'vuex-class';

  import Endpoint from '../../utils/endpoint';

  import Select from '../../types/app/generic/select';
  import Individual from '@/src/types/individual/individual';

  @Component({})
  export default class EmailComponent extends Vue {
    endpoint = new Endpoint();

    @Getter('getIndividual', { namespace: 'individual' }) individual!: Individual;

    typeEmails: Select[] = [];

    emailRules = [
      v => !!v || 'E-mail is required',
      v => /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(v) || 'E-mail must be valid'
    ];

    async created() {
      await this.getEmailTypesAsync();
    }

    async getEmailTypesAsync(): Promise<void> {
      const response = await this.$http.get(`${this.endpoint.common.getByTable}/emailtypes/id/txname`);

      if (response.status === 200) {
        this.typeEmails = response.data;
      }
    }

    addEmail(): void {
      if (this.individual.emails === null) this.individual.emails = [];
      this.individual.emails.push({
        emailTypeId: '',
        email: '',
        primary: this.individual.emails.length === 0
      });
    }

    removeEmail(index: number): void {
      this.individual.emails.splice(index, 1);
    }

    setEmailPrimary(index: number) {
      for (let i = 0; i < this.individual.emails.length; i++) {
        this.individual.emails[i].primary = index === i;
      }
    }
  }
