
  import { Component, Watch, Prop, Vue } from 'vue-property-decorator';
  import { Getter } from 'vuex-class';

  import Endpoint from '../../utils/endpoint';
  import Select from '@/src/types/app/generic/select';
  import Individual from '@/src/types/individual/individual';

  import GenericDates from '../../components/individual/GenericDates.vue';
  import IndividualRelationValues from '../../components/individual/IndividualRelationValues.vue';

  @Component({
    components: {
      GenericDates: GenericDates,
      IndividualRelationValues: IndividualRelationValues
    }
  })
  export default class EntityRelationComponent extends Vue {
    endpoint = new Endpoint();

    @Getter('getIndividual', { namespace: 'individual' }) individual!: Individual;
    @Getter('getOriginalIndividual', { namespace: 'individual' })
    originalIndividual!: Individual;

    @Prop({ type: Array })
    dateRules;

    entityRelationClassifications: Array<Select> = [];
    entities: Array<Select> = [];

    menu = false;
    isValueActive = false;
    isPercentActive = false;

    async created() {
      await this.getEntityRelationsAsync();
      await this.getEntitiesAsync();
    }

    get isChanged(): boolean {
      const changed = this.$toHash(JSON.stringify(this.individual.individualRelations));
      const original = this.$toHash(JSON.stringify(this.originalIndividual.individualRelations));

      return changed !== original;
    }

    async getEntityRelationsAsync(): Promise<void> {
      const response = await this.$http.get(
        `${this.endpoint.individual.getByTable}/IndividualEntityRelationClassifications/id/txname`
      );

      if (response.status === 200) {
        this.entityRelationClassifications = response.data;
      }
    }

    async getEntitiesAsync(): Promise<void> {
      const response = await this.$http.get(`${this.endpoint.general.getByTable}/entities/id/txname`);

      if (response.status === 200) {
        this.entities = response.data;
      }
    }

    addRelation(): void {
      if (this.individual.individualRelations === null) this.individual.individualRelations = [];

      console.log('added');

      this.individual.individualRelations.push({
        percent: 0,
        value: 0
      });
    }

    removeRelation(index: number): void {
      this.individual.individualRelations.splice(index, 1);
    }
  }
