
  import Endpoint from '../../utils/endpoint';

  import LinkData from '@/src/types/organization/linkData';
  import Node from '@/src/types/organization/node';
  import NodeData from '@/src/types/organization/nodeData';
  import NodeInfo from '@/src/types/organization/nodeInfo';
  import { Component, Vue, Watch, Prop } from 'vue-property-decorator';
  import { Getter, Action } from 'vuex-class';

  import go from '../../shared/node_modules/gojs';

  import { jsPDF } from 'jspdf';
  import Select from '@/src/types/app/generic/select';

  @Component({})
  export default class Chart extends Vue {
    endpoint = new Endpoint();

    @Getter('getNode', { namespace: 'organization' }) node!: Node;
    @Getter('getNodeData', { namespace: 'organization' }) nodeData!: NodeData[];
    @Getter('getLinkData', { namespace: 'organization' }) linkData!: LinkData[];
    @Getter('getOverlay', { namespace: 'app' }) overlay!: boolean;
    @Getter('getFullScreen', { namespace: 'app' }) fullScreen!: boolean;

    @Action('setFullScreen', { namespace: 'app' }) setFullScreen!: any;
    @Action('setEntityType', { namespace: 'entity' }) setEntityType!: any;
    @Action('setNodeInfo', { namespace: 'organization' }) setNodeInfo!: any;
    @Action('setSnackbar', { namespace: 'app' }) setSnackbar!: any;

    //#region Chart
    chart = go.GraphObject.make;

    diagram = this.chart(go.Diagram);
    model = this.chart(go.TreeLayout);
    //#endregion

    //#region Variables
    sendPdf = true;
    sendImg = false;
    businessUnits: Array<Select> = [];
    businessUnitIds: Array<string> = [];
    nodeInfo: Array<NodeInfo> = [];
    imgs: Array<any> = [];
    pin = false;
    dialogPrint = false;
    fab = false;
    menuBusinessUnits = false;
    sendingFiles = false;
    progress = 0;
    fileName = this.$route.name;
    //#endregion

    //#region Initialize
    async mounted() {
      await this.initializeChart();
    }

    async created() {
      await this.getBusinessunits();
    }
    //#endregion

    //#region Watchers
    @Watch('fullScreen')
    private watchFullScreen(value) {
      const div = this.diagram.div;
      if (div) {
        div.style.position = value ? 'fixed' : 'relative';
        div.style.top = value ? '0' : '';
        div.style.left = value ? '0' : '';
        div.style.minWidth = value ? '100%' : '100%';
        div.style.minHeight = value ? '100%' : 'calc(100vh - 200px)';
        div.style.marginLeft = '5px';
        div.style.marginTop = '5px';
        div.style.marginRight = '5px';
        div.style.marginBottom = '5px';
        div.style.paddingBottom = '5px';

        this.diagram.requestUpdate();
      }
    }
    //#endregion

    //#region Methods
    async initializeChart(): Promise<void> {
      this.diagram = this.chart(go.Diagram, 'diagram', {
        'undoManager.isEnabled': true,
        //'animationManager.initialAnimationStyle': go.AnimationManager.AnimateLocations,
        contentAlignment: go.Spot.Center,
        isReadOnly: false,
        mouseOver: this.mouseOver,
        click: this.setPinOff,
        layout: this.chart(go.TreeLayout, {
          treeStyle: go.TreeLayout.StyleLastParents,
          arrangement: go.TreeLayout.ArrangementHorizontal,
          angle: 90,
          layerSpacing: 35,
          nodeSpacing: 15,
          alternateAngle: 90,
          alternateLayerSpacing: 35,
          alternateAlignment: go.TreeLayout.AlignmentBus,
          alternateNodeSpacing: 20
        })
      });

      this.diagram.nodeTemplate = this.chart(
        go.Node,
        'Auto',
        {
          fromSpot: go.Spot.RightSide,
          toSpot: go.Spot.LeftSide,
          click: this.changePin
        },

        this.chart(
          go.Shape,
          'RoundedRectangle',
          {
            parameter1: 5, // the corner has a large radius
            height: 35,

            fill: this.chart(go.Brush, 'Linear', { 0: 'rgb(254, 201, 0)', 1: 'rgb(254, 162, 0)' }),
            portId: '',
            cursor: 'pointer'
          },
          new go.Binding('fill', 'color')
        ),
        this.chart(
          go.TextBlock,
          'Default Text',
          {
            margin: 10,
            stroke: 'white',
            font: ' 9px sans-serif'
          },
          new go.Binding('text', 'text').makeTwoWay()
        )
      );

      this.diagram.linkTemplate = this.chart(
        go.Link,
        {
          routing: go.Link.Orthogonal,
          curve: go.Link.JumpOver,
          fromSpot: go.Spot.Top,
          toSpot: go.Spot.Bottom,
          corner: 0,
          isLayoutPositioned: true,
          segmentFraction: 1,
          reshapable: true,
          resegmentable: true,
          toolTip: this.chart(
            'ToolTip',
            this.chart(go.TextBlock, { margin: 4 }, new go.Binding('text', '', this.linkInfo))
          )
        },
        new go.Binding('points').makeTwoWay(),
        this.chart(
          go.Shape,
          { strokeWidth: 1 },
          new go.Binding('stroke', 'color'),
          new go.Binding('strokeDashArray', 'dash')
        ),
        this.chart(go.Shape, { toArrow: 'Standard', stroke: null }, new go.Binding('fill', 'color'))
      );
    }

    linkInfo(d): string | null {
      let tooltip = '';

      this.linkData.forEach(link => {
        if (link.from == d.from && link.to == d.to && link.nodeInfo) {
          console.log('link', JSON.parse(JSON.stringify(link)));

          const relationship = link.nodeInfo.find(x => x.key === 'Relationship') as NodeInfo;

          if (relationship) {
            tooltip = relationship.value;
          }
        }
      });

      return tooltip;
    }

    loadChart(): void {
      this.diagram.model = new go.GraphLinksModel(this.nodeData, this.linkData);

      this.diagram.hasHorizontalScrollbar = false;
      this.diagram.hasVerticalScrollbar = false;
    }

    setPinOff(): void {
      this.pin = false;
    }

    changePin(e: any): void {
      this.pin = true;
      this.loadNodeInfo(this.getNodeInfo(e));
    }

    mouseOver(e: any): void {
      if (!this.pin) {
        this.loadNodeInfo(this.getNodeInfo(e));
      }
    }

    loadNodeInfo(node: NodeData | null): void {
      this.nodeInfo = [];

      if (node && node.nodeInfo) {
        this.nodeInfo = node.nodeInfo.map(x => {
          return { key: x.key, value: x.value, ignore: x.ignore };
        });
      }
    }

    getNodeInfo(e: any): NodeData | null {
      if (e === undefined) e = this.diagram.lastInput;
      const doc = e.documentPoint;

      const list = this.diagram.findObjectsNear(doc, 50, null, function(x) {
        return x instanceof go.Node;
      });

      let closest: any = null;
      let closestDist = 999999999;

      list.each(function(node) {
        const dist = doc.distanceSquaredPoint(node.getDocumentPoint(go.Spot.Center));
        if (dist < closestDist) {
          closestDist = dist;
          closest = node;
        }
      });

      if (closest && closest.part && closest.part.data) {
        return closest.part.data as NodeData;
      }

      return null;
    }

    goToDetail(): void {
      const type = this.nodeInfo.find(x => x.key === 'Type') as NodeInfo;

      this.setEntityType(type?.value.toLowerCase());

      let to: any = {};

      if (this.$router && this.$router.options && this.$router.options.routes) {
        this.$router.options.routes.forEach(route => {
          if (route.meta && route.meta.type === type.value.toLowerCase() && route.path && route.path.includes(':id?')) {
            to = route;
          }
        });
      }

      const id = this.nodeInfo.find(x => x.key.toLowerCase() === 'id') as NodeInfo;

      const routeData = this.$router.resolve({ name: to.name, params: { id: id.value } });
      window.open(routeData.href, '_blank');
      // this.$router.push({ name: to.name, params: { id: id.value } });
    }

    generatePDF(): void {
      const doc = new jsPDF('l', 'mm'); //, pageSize);

      const width = doc.internal.pageSize.getWidth();
      const height = doc.internal.pageSize.getHeight();

      doc.addImage(this.imgs[0], 'JPEG', 0, 0, width, height);

      const pdfURL = doc.output('bloburl');
      window.open(pdfURL as any, '_blank');
    }

    generateImages(width, height): void {
      console.log('images', this.nodeData.length);

      setTimeout(() => {
        this.pin = false;
        this.nodeInfo = [];
      }, 500);

      this.imgs = [];

      const db = this.diagram.documentBounds;
      const boundsheight = db.height;
      console.log('boundsheight', boundsheight);

      let scale = 0;

      if (boundsheight > 350 && boundsheight > 500) {
        scale = 1;
      }

      if (boundsheight > 500) {
        scale = 2;
      }

      const img: any = this.diagram.makeImage({
        background: 'rgba(0,0,0,0)',
        scale: scale,
        maxSize: new go.Size(Infinity, Infinity)
      });

      this.imgs.push(img.src);

      this.dialogPrint = true;
    }

    async getBusinessunits(): Promise<void> {
      const response = await this.$http.get(`${this.endpoint.common.getByTable}/businessunities/id/txname`);

      if (response.status === 200) {
        this.businessUnits = response.data;
      }
    }

    async sendPdfToBusinessUnits(): Promise<void> {
      try {
        this.sendingFiles = true;

        const doc = new jsPDF('l', 'mm'); //, pageSize);

        const width = doc.internal.pageSize.getWidth();
        const height = doc.internal.pageSize.getHeight();

        await this.getProgress();

        doc.addImage(this.imgs[0], 'JPEG', 0, 0, width, height, undefined, 'FAST');

        const blob = doc.output('blob');

        const formData = new FormData();

        if (this.sendPdf) {
          formData.append('files', blob, `${this.fileName}.pdf`);
          formData.append('name', `${this.fileName}.pdf`);
        }

        if (this.sendImg) {
          formData.append('files', this.b64toBlob(this.imgs[0]), `${this.fileName}.jpg`);
          formData.append('name', `${this.fileName}.jpg`);
        }

        this.businessUnitIds.forEach(id => {
          formData.append('businessUnitIds', id);
        });

        const resp = await this.$http({
          method: 'post',
          url: this.endpoint.businessUnit.upload,
          data: formData
        });

        this.sendingFiles = false;

        clearInterval(this.intervalid);
      } catch (error) {
        this.sendingFiles = false;
        clearInterval(this.intervalid);
      }
    }

    b64toBlob(dataURI: string): Blob {
      const byteString = atob(dataURI.split(',')[1]);
      const ab = new ArrayBuffer(byteString.length);
      const ia = new Uint8Array(ab);

      for (let i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
      }
      return new Blob([ab], { type: 'image/jpeg' });
    }

    intervalid: any;
    async getProgress(): Promise<void> {
      this.intervalid = setInterval(() => {
        this.$http.get(this.endpoint.businessUnit.progress).then(resp => {
          this.progress = resp.data;

          this.setSnackbar({
            show: true,
            message: 'Files sended with success',
            color: 'success'
          });
        });
      }, 3000);
    }

    // saveChart(): void {
    //   // const modelToSave = this.diagram.model.toJson();
    //   localStorage.removeItem('modelToSave');
    //   localStorage.setItem('modelToSave', this.diagram.model.toJson());
    //   this.diagram.isModified = false;
    // }

    // loadChartSaved(): void {
    //   const modelToSave = localStorage.getItem('modelToSave');

    //   if (modelToSave) {
    //     const model = go.Model.fromJson(modelToSave);
    //     //console.log('model', go.Model.fromJson(modelToSave));

    //     model.makeUniqueKeyFunction = (model, data) => {
    //       let i = model.nodeDataArray.length * 2 + 1;
    //       while (model.findNodeDataForKey(i) !== null) i += 2;
    //       data.id = i; // assume Model.nodeKeyProperty === "id"
    //       return i;
    //     };

    //     // // link data id's are even numbers
    //     // model.makeUniqueLinkKeyFunction = (model, data) => {
    //     //   let i = model.linkDataArray.length * 2 + 2;
    //     //   while (model.findLinkDataForKey(i) !== null) i += 2;
    //     //   data.id = i; // assume GraphLinksModel.linkKeyProperty === "id"
    //     //   return i;
    //     // };

    //     //this.diagram.model = new go.GraphLinksModel([], []);
    //     this.diagram.model = model;
    //   }
    // }

    // makeUniqueKeyFunction(model: any, data: any): any {
    //   let i = model.nodeDataArray.length * 2 + 1;
    //   while (model.findNodeDataForKey(i) !== null) i += 2;
    //   data.id = i; // assume Model.nodeKeyProperty === "id"
    //   return i;
    // }

    // showIncremental(str: string): void {
    //   // show the last transaction as an incremental update in JSON-formatted text
    //   const element: any = document.getElementById('myTransaction');

    //   // don't show anything upon the initial layout
    //   if (element && element.value === 'InitialLayout') str = '';
    //   element.value = str;
    // }
    //#endregion
  }
