import { ActionContext } from 'vuex';
import { AppState } from './types';
import Checklist from '@/src/types/checklist/checklist';
import ChecklistStage from '@/src/types/checklist/checklistStage';
import ChecklistStageTask from '@/src/types/checklist/checklistStageTask';
import ChecklistTrigger from '@/src/types/checklist/checklistTrigger';
import TriggerType from '@/src/types/checklist/triggerType';
import Feedback from '../../types/checklist/feedback';

export default {
  setChecklist(
    store: ActionContext<AppState, any>,
    checklist: Checklist
  ): void {
    store.commit('setChecklist', checklist);
  },
  setTriggerType(
    store: ActionContext<AppState, any>,
    triggerType: TriggerType
  ): void {
    store.commit('setTriggerType', triggerType);
  },
  setChecklistTrigger(
    store: ActionContext<AppState, any>,
    checklistTrigger: ChecklistTrigger
  ): void {
    store.commit('setChecklistTrigger', checklistTrigger);
  },
  setChecklistStage(
    store: ActionContext<AppState, any>,
    checklistStage: ChecklistStage
  ): void {
    store.commit('setChecklistStage', checklistStage);
  },
  setChecklistStageTask(
    store: ActionContext<AppState, any>,
    checklistStageTask: ChecklistStageTask
  ): void {
    store.commit('setChecklistStageTask', checklistStageTask);
  },
  setNewChecklist(store: ActionContext<AppState, any>) {
    store.commit('setChecklistEmpty', {
      id: 0,
      checklistKey: null,
      name: null,
      teamKey: null,
      ownerKey: null,
      interval: null,
      startDate: null,
      checklistTriggers: [],
      checklistStages: [],
    });

    store.commit('setChecklistStageEmpty', {
      id: 0,
      checklistStageKey: null,
      checklistKey: null,
      name: null,
      sequence: null,
      isEvidence: null,
      estimatedDays: null,
      predecessorChecklistStageKey: null,
      checklistStageTask: [],
    });

    store.commit('setChecklistTriggerEmpty', {
      id: 0,
      checklisttriggerKey: null,
      checklistKey: null,
      triggerType: {
        id: null,
        name: null,
      },
    });

    store.commit('setChecklistStageTaskEmpty', {
      id: 0,
      checklistStageTaskKey: null,
      checklistStageKey: null,
      title: null,
      description: null,
      estimated: null,
      isComplete: null,
      startDate: null,
    });
  },

  setModalTasks(store: ActionContext<AppState, any>, show: boolean): void {
    store.commit('setModalTasks', show);
  },
  setSaveModal(store: ActionContext<AppState, any>, show: boolean): void {
    store.commit('setSaveModal', show);
  },
  setSaveDraftModal(store: ActionContext<AppState, any>, show: boolean): void {
    store.commit('setSaveDraftModal', show);
  },
  setEntitiesModal(store: ActionContext<AppState, any>, show: boolean): void {
    store.commit('setEntitiesModal', show);
  },
  setCompaniesSubTaskModal(
    store: ActionContext<AppState, any>,
    show: boolean
  ): void {
    store.commit('setCompaniesSubTaskModal', show);
  },
  setFeedback(store: ActionContext<AppState, any>, feedback: Feedback) {
    store.commit('setFeedback', feedback);
  },
  setChecklistData(
    store: ActionContext<AppState, any>,
    checklistData: Checklist
  ) {
    store.commit('setChecklistData', checklistData);
  },
  setChecklistEntityStageKey(
    store: ActionContext<AppState, any>,
    checklistEntityStageKey: string
  ) {
    store.commit('setChecklistEntityStageKey', checklistEntityStageKey);
  },
  setChecklistNameTask(
    store: ActionContext<AppState, any>,
    checklistNameTask: string
  ) {
    store.commit('setChecklistNameTask', checklistNameTask);
  },
  setFinishModal(store: ActionContext<AppState, any>, show: boolean): void {
    store.commit('setFinishModal', show);
  },
  setSubTaskFormModal(
    store: ActionContext<AppState, any>,
    show: boolean
  ): void {
    store.commit('setSubTaskFormModal', show);
  },
  setDisableModal(store: ActionContext<AppState, any>, show: boolean): void {
    store.commit('setDisableModal', show);
  },
  setEnableModal(store: ActionContext<AppState, any>, show: boolean): void {
    store.commit('setEnableModal', show);
  },
  setChecklistKeyId(store: ActionContext<AppState, any>, show: string): void {
    store.commit('setChecklistKeyId', show);
  },
  setNoteFieldFull(store: ActionContext<AppState, any>, show: boolean): void {
    store.commit('setNoteFieldFull', show);
  },
  setNote(store: ActionContext<AppState, any>, show: string): void {
    store.commit('setNote', show);
  },
  setLinkFieldFull(store: ActionContext<AppState, any>, show: boolean): void {
    store.commit('setLinkFieldFull', show);
  },
  setUserCanInteract(store: ActionContext<AppState, any>, show: boolean): void {
    store.commit('setUserCanInteract', show);
  },
  setTaskKey(store: ActionContext<AppState, any>, show: string): void {
    store.commit('setTaskKey', show);
  },
  setDeleteSubTaskModal(
    store: ActionContext<AppState, any>,
    show: boolean
  ): void {
    store.commit('setDeleteSubTaskModal', show);
  },
  setModalSubTask(store: ActionContext<AppState, any>, show: boolean): void {
    store.commit('setModalSubTask', show);
  },
  setChecklistSubTaskKey(
    store: ActionContext<AppState, any>,
    show: string
  ): void {
    store.commit('setChecklistSubTaskKey', show);
  },
  setSubTaskName(store: ActionContext<AppState, any>, show: string): void {
    store.commit('setSubTaskName', show);
  },
  setSubTaskKey(store: ActionContext<AppState, any>, show: string): void {
    store.commit('setSubTaskKey', show);
  },
  setSubTaskOrTask(store: ActionContext<AppState, any>, show: string): void {
    store.commit('setSubTaskOrTask', show);
  },
  setIsEditSubTask(store: ActionContext<AppState, any>, show: boolean): void {
    store.commit('setIsEditSubTask', show);
  },
  setTriggersSelectedHasCompany(
    store: ActionContext<AppState, any>,
    hasCompanies: boolean
  ): void {
    store.commit('setTriggersSelectedHasCompany', hasCompanies);
  },

  setEntityStageKeyForSubTaskReload(
    store: ActionContext<AppState, any>,
    EntityStageKeyForSubTaskReload: boolean
  ) {
    store.commit(
      'setEntityStageKeyForSubTaskReload',
      EntityStageKeyForSubTaskReload
    );
  },
  setStageKeyForSubTaskReload(
    store: ActionContext<AppState, any>,
    StageKeyForSubTaskReload: boolean
  ) {
    store.commit('setStageKeyForSubTaskReload', StageKeyForSubTaskReload);
  },
};
