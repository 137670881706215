import { render, staticRenderFns } from "./MaritalStatus.vue?vue&type=template&id=73b6249d"
import script from "./MaritalStatus.vue?vue&type=script&lang=ts"
export * from "./MaritalStatus.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports