import Header from './header';

export default class ChecklistHeader {
  public static indexHeader: Header[] = [
    {
      text: 'Name',
      align: 'start',
      sortable: true,
      value: 'name',
      class: null,
      width: '20%',
    },
    {
      text: 'Team',
      align: 'start',
      sortable: true,
      value: 'teamKey',
      class: null,
      width: '13%',
    },
    {
      text: 'Owner',
      align: 'start',
      sortable: true,
      value: 'ownerKey',
      class: null,
      width: '15%',
    },
    {
      text: 'Interval',
      align: 'start',
      sortable: true,
      value: 'interval',
      class: null,
      width: '10%',
    },
    // {
    //   text: 'Start at',
    //   align: 'start',
    //   value: 'startDate',
    //   sortable: true,
    //   class: null,
    //   width: '10%'
    // },
    {
      text: 'Deadline',
      align: 'start',
      value: 'realDeadLine',
      sortable: true,
      class: null,
      width: '10%',
    },
    {
      text: 'Entities',
      align: 'start',
      value: 'countEntities',
      sortable: true,
      class: null,
      width: '8%',
    },
    {
      text: 'Status',
      align: 'start',
      value: 'decriptionStatus',
      sortable: false,
      class: null,
      width: '4%',
    },
    {
      text: '',
      align: '',
      value: 'data-table-expand',
      sortable: false,
      class: null,
      width: '5%',
    },
    {
      text: '',
      align: 'end',
      value: 'data-table-expand',
      sortable: false,
      class: null,
      width: '3%',
    },
  ];
}
