import Header from './header';

export default class ChecklistHeader {
  public static indexHeaderDetailsSubTask: Header[] = [
    {
      text: '',
      align: 'start',
      sortable: true,
      value: 'subName',
      class: null,
      width: '25%',
    },
    {
      text: '',
      align: 'start',
      sortable: true,
      value: 'teamKey',
      class: null,
      width: '7%',
    },
    {
      text: '',
      align: 'start',
      value: 'finished',
      sortable: false,
      class: null,
      width: '20%',
    },
    {
      text: '',
      align: 'center',
      value: 'up',
      sortable: false,
      class: null,
      width: '5%',
    },
  ];
}
