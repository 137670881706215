
  import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
  import { Getter, Action } from 'vuex-class';
  import qs from 'qs';

  import Endpoint from '../../../utils/endpoint';
  import Select from '@/src/types/app/generic/select';
  import Entity from '@/src/types/entities/entity';

  import { ConfigForms } from '../../../utils/configForms';
  import OrgaizationForm from '@/src/types/entities/organizationForm';

  @Component({})
  export default class FormOrganizationComponent extends Vue {
    endpoint = new Endpoint();

    @Getter('getOriginalEntity', { namespace: 'entity' }) originalEntity!: Entity;
    @Getter('getEntity', { namespace: 'entity' })
    entity!: Entity;

    @Action('setConfigForm', { namespace: 'entity' }) setConfigForm!: any;

    @Prop({ type: Array })
    rules;

    @Watch('entity.formOrganizationId')
    private async filtering() {
      this.setConfig();
    }

    formOrganizations: Array<OrgaizationForm> = [];

    async created() {
      await this.getFormOrganizationsAsync();
    }

    async getFormOrganizationsAsync(): Promise<void> {
      try {
        const response = await this.$http.get(
          `${this.endpoint.formOrganization.getByFilter}?${qs.stringify({
            take: 100
          })}`
        );

        this.formOrganizations = response.data.data;
      } catch (error) {
        console.log('error', error);
      }
    }

    setConfig() {
      this.formOrganizations.forEach(formOrganization => {
        if (formOrganization.id === this.entity.formOrganizationId) {
          this.setConfigForm(formOrganization.requiredFields);
        }
      });
    }

    cleanSelect(): void {
      this.entity.formOrganizationId = null;
    }
  }
