
  import { Component, Watch, Prop, Vue } from 'vue-property-decorator';
  import { Getter, Action } from 'vuex-class';

  import Endpoint from '../../utils/endpoint';
  import Select from '../../types/app/generic/select';
  import LesteEntity from '@/src/types/entities/lesteEntity';
  import OriginalLesteEntity from '@/src/types/entities/lesteEntity';
  import Office from '../../views/Office/Form.vue';
  import OfficeModel from '../../types/entities/office';

  @Component({
    components: {
      Office: Office
    }
  })
  export default class EntityUnityBusinessComponent extends Vue {
    endpoint = new Endpoint();

    @Getter('getLesteEntity', { namespace: 'entity' })
    lesteEntity!: LesteEntity;
    @Getter('getOriginalLesteEntity', { namespace: 'entity' })
    originalLesteEntity!: any;

    @Action('setOffice', { namespace: 'entity' }) setOffice!: any;
    @Getter('getOffice', { namespace: 'entity' }) officeModel!: OfficeModel;

    @Prop({ type: Array })
    rules;

    dialog = false;
    saving = false;
    gettingOffice = false;

    originalValue: string | null = '';
    offices: Array<Select> = [];

    async created() {
      await this.getOfficesAsync();
    }

    get originalStartDate(): string | null {
      if (this.originalLesteEntity.entityOffice.effectiveStartDate !== null) {
        const dt = new Date(this.originalLesteEntity.entityOffice.effectiveStartDate);
        return this.$formatDate(dt);
      }

      return null;
    }

    get startDate(): string | null {
      return this.$formatDate(this.lesteEntity.entityOffice.effectiveStartDate);
    }
    set startDate(value: string | null) {
      this.lesteEntity.entityOffice.effectiveStartDate = this.$parseDate(value);
    }

    async getOfficesAsync(): Promise<void> {
      const response = await this.$http.get(`${this.endpoint.general.getByTable}/offices/id/txname`);

      if (response.status === 200) {
        this.offices = response.data;
      }
    }

    async openOfficeDialog(): Promise<void> {
      await this.getOfficeAsync();
    }

    async getOfficeAsync(): Promise<void> {
      this.gettingOffice = true;

      const response = await this.$http.get(
        `${this.endpoint.office.getById}/${this.lesteEntity.entityOffice.entityOfficeId}`
      );

      this.setOffice({
        active: response.data.data.active,
        name: response.data.data.name,
        shortName: response.data.data.shortName,
        cityId: response.data.data.cityId,
        address: response.data.data.address,
        number: response.data.data.number,
        complement: response.data.data.complement,
        district: response.data.data.district,
        postalCode: response.data.data.postalCode
      });

      this.gettingOffice = false;
      this.dialog = true;
    }

    async updateOffice(): Promise<void> {
      try {
        this.saving = true;

        const response = await this.$http.put(this.endpoint.office.update, {
          id: this.lesteEntity.entityOffice.entityOfficeId,
          office: this.officeModel
        });

        await this.getOfficesAsync();

        this.saving = false;
        this.dialog = false;
      } catch (error) {
        this.saving = false;
        console.log('error', error);
      }
    }

    cleanSelect(): void {
      this.lesteEntity.entityOffice.entityOfficeId = null;
    }
  }
