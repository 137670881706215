import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
import Required from '../utils/required';

import Home from '../views/Home.vue';
import Login from '../views/Auth/Login.vue';
import Logout from '../views/Auth/Logout.vue';

import entityRoutes from './entties';
import individualRoutes from './individuals';
import externalEntityRoutes from './externalEntities';
import generalRoutes from './general';
import organization from './organization';
import checklistRoutes from './checklist';

const required = new Required();

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/logout',
    name: 'Logout',
    component: Logout
  },
  {
    path: '/',
    name: 'Home',
    component: Home,
    beforeEnter: required.requireAuth
  },
  ...entityRoutes,
  ...individualRoutes,
  ...externalEntityRoutes,
  ...generalRoutes,
  ...organization,
  ...checklistRoutes
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});

export default router;
