
  import { Component, Watch, Prop, Vue } from 'vue-property-decorator';
  import { Getter } from 'vuex-class';

  import Endpoint from '../../utils/endpoint';
  import Select from '@/src/types/app/generic/select';
  import Entity from '@/src/types/entities/entity';
  import Dates from '../../components/individual/GenericDates.vue';

  @Component({
    components: {
      Dates: Dates
    }
  })
  export default class IndividualEntityRelationComponent extends Vue {
    endpoint = new Endpoint();

    @Getter('getOriginalEntity', { namespace: 'entity' }) originalEntity!: Entity;
    @Getter('getEntity', { namespace: 'entity' })
    entity!: Entity;

    @Prop({ type: Array })
    dateRules;

    requiredRules = [
      // (v: string) => {
      //   if (
      //     typeof this.entity !== 'undefined' &&
      //     this.entity.entityRelation.individualId !== null &&
      //     v === null
      //   ) {
      //     return 'This field is required';
      //   }
      //   return true;
      // }
    ];

    individualEntityRelationClassifications: Array<Select> = [];
    employees: Array<Select> = [];

    async created() {
      if (this.entity.individualEntityRelation === null) this.entity.individualEntityRelation = [];

      await this.getEmployeesRelationsAsync();
      await this.getEmployeesAsync();
    }

    async getEmployeesRelationsAsync(): Promise<void> {
      const response = await this.$http.get(
        `${this.endpoint.individual.getByTable}/IndividualEntityRelationClassifications/id/txname`
      );

      if (response.status === 200) {
        this.individualEntityRelationClassifications = response.data;
      }
    }

    async getEmployeesAsync(): Promise<void> {
      const response = await this.$http.get(`${this.endpoint.individual.getByTable}/individuals/id/txname`);

      if (response.status === 200) {
        this.employees = response.data;
      }
    }

    addRelation(): void {
      if (this.entity.individualEntityRelation === null) this.entity.individualEntityRelation = [];

      this.entity.individualEntityRelation.push({
        individualId: null,
        individualRelationClassificationId: null,
        effectiveStartDate: null,
        effectiveEndDate: null
      });
    }

    removeRelation(index: number): void {
      this.entity.individualEntityRelation.splice(index, 1);
    }
  }
