import Vue from 'vue';
import Vuex from 'vuex';

import auth from '../vuex/auth';
import app from '../vuex/app';
import entity from '../vuex/entity';
import individual from '../vuex/individual';
import organization from '../vuex/organization';
import checklist from '../vuex/checklist';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    auth,
    app,
    entity,
    individual,
    organization,
    checklist
  }
});
