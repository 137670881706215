
import Vue from 'vue';
import Component from 'vue-class-component';
import { Getter, Action } from 'vuex-class';

import FeedbackInterface from '../../../types/checklist/feedback';

@Component({})
export default class Feedback extends Vue {
  @Getter('getFeedback', { namespace: 'checklist' })
  public feedback!: FeedbackInterface;
  @Action('setFeedback', { namespace: 'checklist' }) public setFeedback: any;
}
