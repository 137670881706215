
  import { Component, Ref, Vue } from 'vue-property-decorator';
  import { Action } from 'vuex-class';

  import Generic from '../../types/app/generic/generic';
  import Endpoint from '../../utils/endpoint';

  @Component({})
  export default class LicenseType extends Vue {
    endpoint = new Endpoint();
    @Action('setSnackbar', { namespace: 'app' }) setSnackbar!: any;

    valid = null;
    saving = false;
    loading = false;
    rules = [
      v => !!v || 'This field is required'
      //v => (v && v.length <= 10) || 'Name must be less than 10 characters'
    ];
    isCreate = true;

    licensetype: Generic = {
      name: '',
      shortName: '',
      active: true
    };

    async created() {
      this.isCreate = !this.$route.params.id;
      if (!this.isCreate) {
        this.loading = true;
        await this.getLicenseTypes();
      }
    }

    get title(): string {
      return this.isCreate ? 'Create Regulatory License Type' : 'Regulatory License Type Detail';
    }

    async getLicenseTypes(): Promise<void> {
      this.loading = true;
      const response = await this.$http.get(`${this.endpoint.licenseType.getById}/${this.$route.params.id}`);

      if (response.status == 200) {
        this.licensetype = response.data.data;
        this.loading = false;
      }
    }

    async save(): Promise<void> {
      if ((this.$refs.form as Vue & { validate: () => boolean }).validate()) {
        if (this.isCreate) {
          await this.create();
        } else {
          await this.update();
        }
      }
    }

    async update(): Promise<void> {
      this.saving = true;

      const response = await this.$http.put(
        `${this.endpoint.licenseType.update}/${this.$route.params.id}`,
        this.licensetype
      );

      if (response.status == 200) {
        this.saving = false;

        this.setSnackbar({
          show: true,
          message: 'Regulatory license type update',
          color: 'success'
        });

        this.$router.push({ name: 'IndexLicenseType' });
      } else {
        this.saving = false;
        console.log('erro...', response);
      }
    }

    async create(): Promise<void> {
      this.saving = true;

      const response = await this.$http.post(this.endpoint.licenseType.create, this.licensetype);

      if (response.status == 200) {
        this.saving = false;

        this.setSnackbar({
          show: true,
          message: 'Regulatory license type saved',
          color: 'success'
        });

        this.$router.push({ name: 'IndexLicenseType' });
      }

      if (response.status != 200) {
        this.saving = false;
      }
    }
  }
