
  import { Component, Watch, Prop, Vue, Ref } from 'vue-property-decorator';
  import { Getter, Action } from 'vuex-class';

  import Individual from '@/src/types/individual/individual';
  import Select from '@/src/types/app/generic/select';
  import Endpoint from '../../utils/endpoint';

  @Component({})
  export default class BornDateComponent extends Vue {
    endpoint = new Endpoint();

    @Getter('getIndividual', { namespace: 'individual' }) individual!: Individual;
    @Getter('getOriginalIndividual', { namespace: 'individual' })
    originalIndividual!: Individual;

    gettingAddressTypes = true;
    gettingCities = true;

    addressTypes: Array<Select> = [];
    cities: Array<Select> = [];

    componentForm = {
      street_number: 'short_name',
      route: 'long_name',
      locality: 'long_name',
      administrative_area_level_1: 'short_name',
      country: 'long_name',
      postal_code: 'short_name'
    };

    async created() {
      await this.getAddressTypesAsync();
      await this.getCitiesAsync();
    }

    get isChanged(): boolean {
      const changed = this.$toHash(JSON.stringify(this.individual.addresses));
      const original = this.$toHash(JSON.stringify(this.originalIndividual.addresses));

      return changed !== original;
    }

    async getAddressTypesAsync(): Promise<void> {
      const response = await this.$http.get(`${this.endpoint.common.getByTable}/AddressTypes/id/txname`);

      this.addressTypes = response.data;
      this.gettingAddressTypes = false;
    }

    async getCitiesAsync(): Promise<void> {
      const response = await this.$http.get(`${this.endpoint.common.getByTable}/Cities/id/txname`);

      this.cities = response.data;
      this.gettingCities = false;
    }

    addAddress(): void {
      if (this.individual.addresses === null) this.individual.addresses = [];

      this.individual.addresses.push({
        street: null,
        number: null,
        complement: null,
        district: null,
        postalCode: null,
        addressTypeId: null,
        cityId: null
      });
    }

    removeAddress(index: number): void {
      this.individual.addresses.splice(index, 1);
    }

    cleanSelect(i: number): void {
      console.log(i);
      this.individual.addresses[i].addressTypeId = null;
    }

    async getByPostalCode(zipCode: string): Promise<void> {
      const response = await this.$http.get(this.endpoint.individual.getByPostalCode.replace('CODE', zipCode));
    }
  }
