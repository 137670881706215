import { AppState } from './types';
import Checklist from '../../types/checklist/checklist';
import ChecklistStage from '../../types/checklist/checklistStage';
import ChecklistStageTask from '../../types/checklist/checklistStageTask';
import ChecklistTrigger from '../../types/checklist/checklistTrigger';
import Feedback from '../../types/checklist/feedback';

export default {
  getChecklist(state: AppState): Checklist {
    return state.checklist;
  },
  getChecklistStage(state: AppState): ChecklistStage {
    return state.checklistStage;
  },
  getChecklistStageTask(state: AppState): ChecklistStageTask {
    return state.checklistStageTask;
  },
  getChecklistTrigger(state: AppState): ChecklistTrigger {
    return state.checklistTrigger;
  },

  getModalTasks(state: AppState): boolean {
    return state.checklist.modal;
  },
  getSaveModal(state: AppState): boolean {
    return state.checklistSaveModal;
  },
  getSaveDraftModal(state: AppState): boolean {
    return state.checklistSaveDraftModal;
  },
  getEntitiesModal(state: AppState): boolean {
    return state.checklistEntitiesModal;
  },
  getCompaniesSubTaskModal(state: AppState): boolean {
    return state.companiesSubTaskModal;
  },
  getFeedback(state: AppState): Feedback {
    return state.feedback;
  },
  getChecklistData(state: AppState): Checklist {
    return state.checklistData;
  },
  getChecklistEntityStageKey(state: AppState): string {
    return state.checklistEntityStageKey;
  },
  getChecklistNameTask(state: AppState): string {
    return state.checklistNameTask;
  },
  getFinishModal(state: AppState): boolean {
    return state.checklistFinishModal;
  },
  getDisableModal(state: AppState): boolean {
    return state.checklistDisableModal;
  },
  getEnableModal(state: AppState): boolean {
    return state.checklistEnableModal;
  },
  getChecklistKeyId(state: AppState): string {
    return state.checklistKeyId;
  },
  getNoteFieldFull(state: AppState): boolean {
    return state.noteFieldFull;
  },
  getNote(state: AppState): string {
    return state.note;
  },
  getLinkFieldFull(state: AppState): boolean {
    return state.linkFieldFull;
  },
  getUserCanInteract(state: AppState): boolean {
    return state.userCanInteract;
  },
  getSubTaskFormModal(state: AppState): boolean {
    return state.checklistSubTaskFormModal;
  },
  getTaskKey(state: AppState): string {
    return state.taskKey;
  },
  getDeleteSubTaskModal(state: AppState): boolean {
    return state.checklistDeleteSubTask;
  },
  getModalSubTask(state: AppState): boolean {
    return state.subTaskModal;
  },
  getChecklistSubTaskKey(state: AppState): string {
    return state.checklistSubTaskKey;
  },
  getSubTaskName(state: AppState): string {
    return state.checklistSubTaskName;
  },
  getSubTaskKey(state: AppState): string {
    return state.subTaskKey;
  },
  getSubTaskOrTask(state: AppState): string {
    return state.subTaskOrTask;
  },
  getIsEditSubTask(state: AppState): boolean {
    return state.isEditSubTask;
  },
  getTriggersSelectedHasCompany(state: AppState): boolean {
    return state.triggersSelectedHasCompany;
  },

  getEntityStageKeyForSubTaskReload(state: AppState) {
    return state.EntityStageKeyForSubTaskReload;
  },
  getStageKeyForSubTaskReload(state: AppState) {
    return state.StageKeyForSubTaskReload;
  },
};
