import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import VueTheMask from 'vue-the-mask';
import VueSkeletonLoader from 'skeleton-loader-vue';
import Http from './plugins/http';
import { Utils } from './plugins/utils';
import vuetify from './plugins/vuetify';
import money from 'v-money';
import vueDebounce from 'vue-debounce';

Vue.config.productionTip = false;

Vue.use(new Http());
Vue.use(Utils);
Vue.use(VueTheMask);
Vue.use(money, { precision: 4 });
Vue.component('vue-skeleton-loader', VueSkeletonLoader);
Vue.use(vueDebounce, {
  lock: true,
});

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount('#app');
