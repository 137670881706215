
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { Getter, Action } from 'vuex-class';
import Endpoint from '../../utils/endpoint';
import FormatDate from '../../utils/formatDate';

import ChecklistInterface from '../../types/checklist/checklist';
import Header from '../../types/app/headers/header';
import EntitiesChecklistHeader from '../../types/app/headers/EntitiesChecklistHeader';
import InnerEntitiesChecklistHeader from '../../types/app/headers/innerEntitiesChecklistHeader';
import Triggers from '../../types/checklist/triggers/triggers';
import TriggersReference from '../../types/checklist/triggers/triggersReference';
import Error from '../../types/checklist/errorData';

import Entities from '../../types/checklist/entities';

@Component({})
export default class EntitiesChecklist extends Vue {
  private endpoint = new Endpoint();
  headers: Array<Header> = EntitiesChecklistHeader.indexHeader;
  innerHeaders: Array<Header> = InnerEntitiesChecklistHeader.innerIndexHeader;

  @Watch('entityTypeSelected')
  @Watch('formOrganizationSelected')
  @Watch('regulatoryLicenseSelected')
  @Watch('jurisdictionsSelected')
  handleGetEntities() {
    this.listEntitiesItem();
  }

  formatDate = FormatDate;

  showFilters = false;
  loading = false;
  search = '';
  expanded = [];
  singleExpand = true;
  items: Entities[] = [];
  checklistInner: ChecklistInterface[] = [];

  triggersChecklist: Triggers[] = [];
  entityTypeTrigger: any[] = [];
  formOrganizationTrigger: any[] = [];
  regulatoryLicenseTrigger: any[] = [];
  jurisdictionsTrigger: any[] = [];

  entityTypeSelected: TriggersReference = { dtEntry: '', name: '', id: '' };
  formOrganizationSelected: TriggersReference = {
    dtEntry: '',
    name: '',
    id: '',
  };
  regulatoryLicenseSelected: TriggersReference = {
    dtEntry: '',
    name: '',
    id: '',
  };
  jurisdictionsSelected: TriggersReference = { dtEntry: '', name: '', id: '' };

  entityKeyStorage: Entities = new Entities();

  public async listEntitiesItem() {
    this.loading = true;
    const params = {
      EntityTypeId: this.entityTypeSelected?.id,
      FormOrganizationId: this.formOrganizationSelected?.id,
      JurisdictionId: this.jurisdictionsSelected?.id,
      RegulatoryLicenseId: this.regulatoryLicenseSelected?.id,
    };
    try {
      const entities = await this.$http.get(this.endpoint.entities.list, {
        params,
      });
      const items = entities.data.data;
      this.items = items;
    } catch (error) {
      const errorData = error as Error;
      if (errorData.status === 400) {
        this.items = [];
      }
      console.log('Ocorreu um erro ao listar entidades');
    }
    this.loading = false;
  }

  public handleSetChecklistInner(entity: Entities) {
    const entities = this.items.find(
      (item) => item.entityKey === entity.entityKey
    );
    this.checklistInner = entities?.checklists as ChecklistInterface[];
    this.entityKeyStorage = entity;
  }

  async getTriggersForList() {
    try {
      const triggers = await this.$http.get(this.endpoint.triggers.list);
      this.triggersChecklist = triggers.data.data.triggers;

      this.entityTypeTrigger = this.triggersChecklist[1].triggerReferences;
      this.formOrganizationTrigger =
        this.triggersChecklist[2].triggerReferences;
      this.regulatoryLicenseTrigger =
        this.triggersChecklist[3].triggerReferences;
      this.jurisdictionsTrigger = this.triggersChecklist[4].triggerReferences;
    } catch (error) {
      console.log('Ocorreu um erro ao listar Triggers');
    }
  }

  Detail(entity: Entities, checklist: ChecklistInterface) {
    this.$router.push({
      name: 'DetailChecklist',
      params: {
        id: checklist.checklistEntityKey,
      },
    });
  }

  mounted() {
    this.listEntitiesItem();
    this.getTriggersForList();
  }

  goToChecklist() {
    this.$router.push({
      path: '/checklist',
    });
  }
}
