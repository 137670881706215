
import Individual from '@/src/types/individual/individual';
import { Component, Ref, Vue } from 'vue-property-decorator';
import { Getter, Action } from 'vuex-class';

import Endpoint from '../../utils/endpoint';

import Gender from '../../components/individual/Gender.vue';
import BornDate from '../../components/individual/BornDate.vue';
import MaritalStatus from '../../components/individual/MaritalStatus.vue';
import AdmissionDemissionDateComponent from '../../components/individual/AdmissionDemissionDate.vue';
import ParentName from '../../components/individual/ParentName.vue';
import CourseCertification from '../../components/individual/CourseCertification.vue';
import IndividualUnityBusiness from '../../components/individual/IndividualUnityBusiness.vue';
import Address from '../../components/individual/Address.vue';
import Document from '../../components/individual/Document.vue';
import Account from '../../components/individual/Account.vue';
import IndividualRelation from '../../components/individual/IndividualRelation.vue';
import IndividualIndividualRelation from '../../components/individual/IndividualIndividualRelation.vue';
import Dependent from '../../components/individual/Dependent.vue';
import Email from '../../components/individual/Email.vue';
import Phone from '../../components/individual/Phone.vue';

import CommandsComponent from '../../components/generics/Commands.vue';
import Employee from '@/src/types/individual/employee';

@Component({
  components: {
    Gender: Gender,
    BornDate: BornDate,
    MaritalStatus: MaritalStatus,
    CommandsComponent: CommandsComponent,
    AdmissionDemissionDateComponent: AdmissionDemissionDateComponent,
    ParentName: ParentName,
    CourseCertification: CourseCertification,
    IndividualUnityBusiness: IndividualUnityBusiness,
    Address: Address,
    Document: Document,
    Account: Account,
    IndividualRelation: IndividualRelation,
    IndividualIndividualRelation: IndividualIndividualRelation,
    Dependent: Dependent,
    Email: Email,
    Phone: Phone,
  },
})
export default class DetailIndividual extends Vue {
  endpoint = new Endpoint();

  @Action('setSnackbar', { namespace: 'app' }) setSnackbar!: any;

  @Getter('getIndividual', { namespace: 'individual' }) individual!: Individual;
  @Getter('getEmployee', { namespace: 'individual' }) employee!: Employee;

  @Getter('getOriginalIndividual', { namespace: 'individual' })
  originalIndividual!: Individual;
  @Getter('getOriginalEmployee', { namespace: 'individual' })
  originalEmployee!: Employee;

  @Action('setNewEmployee', { namespace: 'individual' }) setNewEmployee!: any;
  @Action('setIndividual', { namespace: 'individual' }) setIndividual!: any;
  @Action('setEmployee', { namespace: 'individual' }) setEmployee!: any;

  valid = null;
  saving = false;
  loading = false;
  dialog = false;

  rules = [v => !!v || 'This field is required'];
  dateRules = [
    (v: string) => {
      const message = 'Invalid date mm/dd/yyyy';

      if (v == null) return true;

      if (v.length < 10) return message;

      const [m, d, y] = v.split('/');

      const month = parseInt(m);
      const day = parseInt(d);
      const year = parseInt(y);

      if (day < 1 || day > 31 || day === 0) return message;

      if ((month < 1 && month > 12) || month === 0) return message;

      if (year < 1920 || year > 2100 || year === 0) return message;

      return true;
    },
  ];

  individualModel = {
    active: true,
    individual: {},
    employee: {},
  };

  async created() {
    this.setNewEmployee();
    await this.getEmployeeAsync();
  }

  async getEmployeeAsync(): Promise<void> {
    this.loading = true;

    const response = await this.$http.get(
      `${this.endpoint.individual.getById}/${this.$route.params.id}`
    );

    const individual: Individual = response.data.data.individual;
    const employee: Employee = response.data.data.employee;

    if (employee.individualUnityBusiness === null) {
      Object.assign(employee, {
        individualUnityBusiness: {},
      });
    }

    this.setIndividual(individual);
    this.setEmployee(employee);

    this.loading = false;
  }

  async save(): Promise<void> {
    Object.assign(this.individualModel.individual, this.individual);
    Object.assign(this.individualModel.employee, this.employee);

    if ((this.$refs.form as Vue & { validate: () => boolean }).validate()) {
      try {
        this.saving = true;

        const response = await this.$http.put(
          `${this.endpoint.individual.update}`,
          {
            id: this.$route.params.id,
            individual: this.individualModel,
          }
        );

        this.saving = false;

        this.setSnackbar({
          show: true,
          message: `${this.$route.meta?.title} Success`,
          color: 'success',
        });

        this.$router.push({ name: this.$route.meta?.before });
      } catch (error) {
        console.log('error', error);
        this.saving = false;

        this.setSnackbar({
          show: true,
          message: 'Update Fail',
          color: 'error',
        });
      }
    }
  }

  goToIndex(): string {
    return this.$route.meta?.external
      ? `/external/${this.$route.meta?.index}`
      : `/${this.$route.meta?.index}`;
  }
}
