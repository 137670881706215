import { feedbackInitialValue } from '../../types/checklist/feedback';
import { checklistInitValue } from '../../types/checklist/checklist';

export default {
  checklist: {
    id: 0,
    checklistKey: null,
    name: null,
    teamKey: null,
    ownerKey: null,
    interval: null,
    startDate: null,
    checklistTriggers: [],
    checklistStages: [],
    modal: false,
  },
  checklistStage: {
    id: 0,
    checklistStageKey: null,
    checklistKey: null,
    name: null,
    sequence: null,
    isEvidence: null,
    estimatedDays: null,
    predecessorChecklistStageKey: null,
    checklistStageTask: [],
  },
  checklistStageTask: {
    id: 0,
    checklistStageTaskKey: null,
    checklistStageKey: null,
    title: null,
    description: null,
    estimated: null,
    isComplete: null,
    startDate: null,
  },
  checklistTrigger: {
    id: 0,
    checklisttriggerKey: null,
    checklistKey: null,
    triggerType: {
      id: null,
      name: null,
    },
  },
  checklistSaveModal: false,
  checklistSaveDraftModal: false,
  checklistEntitiesModal: false,
  feedback: feedbackInitialValue,
  checklistData: checklistInitValue,
  checklistEntityStageKey: '',
  checklistNameTask: '',
  checklistFinishModal: false,
  checklistDisableModal: false,
  checklistEnableModal: false,
  checklistKeyId: '',
  noteFieldFull: false,
  note: '',
  linkFieldFull: false,
  userCanInteract: false,
  subTaskModal: false,
  taskKey: '',
  checklistSubTaskFormModal: false,
  checklistSubTaskKey: '',
  checklistSubTaskName: '',
  checklistDeleteSubTask: false,
  subTaskKey: '',
  subTaskOrTask: '',
  isEditSubTask: false,
  triggersSelectedHasCompany: false,
  boolean: false,
  companiesSubTaskModal: false,

  EntityStageKeyForSubTaskReload: '',
  StageKeyForSubTaskReload: '',
};
