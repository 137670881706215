
  import { Component, Watch, Prop, Vue } from 'vue-property-decorator';
  import { Getter } from 'vuex-class';

  import Endpoint from '../../utils/endpoint';
  import Select from '@/src/types/app/generic/select';
  import Individual from '@/src/types/individual/individual';

  import GenericDates from '../../components/individual/GenericDates.vue';
  import individual from '@/src/vuex/individual';

  @Component({
    components: {
      GenericDates: GenericDates
    }
  })
  export default class IndividualRelationComponent extends Vue {
    endpoint = new Endpoint();

    @Getter('getIndividual', { namespace: 'individual' }) individual!: Individual;
    @Getter('getOriginalIndividual', { namespace: 'individual' })
    originalIndividual!: Individual;

    @Prop({ type: Array })
    dateRules;

    individuals: Array<Select> = [];
    individualRelations: Array<Select> = [];

    async created() {
      await this.getIndividualsAsync();
      await this.getIndividualRelationsAsync();
    }

    get individualsFiltered(): Select[] {
      return this.individuals.filter(x => x.text !== this.originalIndividual.name);
    }

    get isChanged(): boolean {
      const changed = this.$toHash(JSON.stringify(this.individual.individualIndividualRelations));
      const original = this.$toHash(JSON.stringify(this.originalIndividual.individualIndividualRelations));

      return changed !== original;
    }

    async getIndividualsAsync(): Promise<void> {
      const resp = await this.$http.get(this.endpoint.individual.getIndividualsIgnoreDependentes);
      if (resp.status === 200) {
        this.individuals = resp.data;
      }
    }

    async getIndividualRelationsAsync(): Promise<void> {
      const resp = await this.$http.get(
        `${this.endpoint.individual.getByTable}/IndividualRelationClassifications/id/txname`
      );
      if (resp.status === 200) {
        this.individualRelations = resp.data;
      }
    }

    addRelation(): void {
      console.log(this.individual.individualIndividualRelations);

      if (this.individual.individualIndividualRelations === null) {
        this.individual.individualIndividualRelations = [];
      }

      console.log('added');

      this.individual.individualIndividualRelations.push({});
    }

    removeRelation(index: number): void {
      this.individual.individualIndividualRelations.splice(index, 1);
    }
  }
