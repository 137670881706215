import { RouteConfig } from 'vue-router';

import IndexChecklist from '../views/Checklist/Index.vue';
import CreateChecklist from '../views/Checklist/Create.vue';
import DetailChecklist from '../views/Checklist/Detail.vue';
import ViewChecklist from '../views/Checklist/ViewChecklist.vue';
import EntitiesChecklist from '../views/Checklist/EntitiesChecklist.vue';

import Required from '../utils/required';

const required = new Required();

const checklistRoutes: Array<RouteConfig> = [
  {
    path: '/checklist',
    name: 'IndexChecklist',
    meta: {
      parent: 'Leste Checklist',
      create: 'CreateChecklist',
      edit: 'CreateChecklist',
      title: 'Checklists',
      type: 'checklist',
      external: false,
    },
    component: IndexChecklist,
    beforeEnter: required.requireAuth,
    // component: DetailChecklist
  },
  {
    path: '/checklist/new',
    name: 'CreateChecklist',
    meta: {
      parent: 'Leste Checklist',
      before: 'IndexChecklist',
      index: 'checklists',
      title: 'Create Checklist',
      type: 'checklist',
      external: false,
    },
    component: CreateChecklist,
    beforeEnter: required.requireAuth,
  },
  {
    path: '/checklist/edit/:id',
    name: 'EditChecklist',
    meta: {
      parent: 'Leste Checklist',
      before: 'IndexChecklist',
      index: 'checklists',
      title: 'Create Checklist',
      type: 'checklist',
      external: false,
    },
    props: true,
    component: CreateChecklist,
    beforeEnter: required.requireAuth,
  },
  {
    path: '/checklist/new/:id',
    name: 'DuplicateChecklist',
    meta: {
      parent: 'Leste Checklist',
      before: 'IndexChecklist',
      index: 'checklists',
      title: 'Create Checklist',
      type: 'checklist',
      external: false,
    },
    props: true,
    component: CreateChecklist,
    beforeEnter: required.requireAuth,
  },
  {
    path: '/checklist/:id',
    name: 'ViewChecklist',
    meta: {
      parent: 'Leste Checklist',
      before: 'IndexChecklist',
      index: 'checklists',
      type: 'checklist',
      external: false,
    },
    props: true,
    component: ViewChecklist,
    beforeEnter: required.requireAuth,
  },
  {
    path: '/entities/:id',
    name: 'DetailChecklist',
    meta: {
      parent: 'Leste Checklist',
      before: 'IndexChecklist',
      index: 'checklists',
      type: 'checklist',
      external: false,
    },
    props: true,
    component: DetailChecklist,
    beforeEnter: required.requireAuth,
  },
  {
    path: '/entities',
    name: 'EntitiesChecklist',
    meta: {
      parent: 'Leste Checklist',
      before: 'IndexChecklist',
      index: 'checklists',
      type: 'checklist',
      external: false,
    },
    props: true,
    component: EntitiesChecklist,
    beforeEnter: required.requireAuth,
  },
];

export default checklistRoutes;
