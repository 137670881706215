
  import { Component, Watch, Prop, Vue } from 'vue-property-decorator';
  import { Getter, Action } from 'vuex-class';

  import Endpoint from '../../utils/endpoint';

  import Individual from '@/src/types/individual/individual';
  import Select from '@/src/types/app/generic/select';

  import DocumentDate from '../../components/individual/DocumentDate.vue';

  @Component({
    components: {
      DocumentDate: DocumentDate
    }
  })
  export default class DocumentComponent extends Vue {
    endpoint = new Endpoint();

    @Getter('getIndividual', { namespace: 'individual' }) individual!: Individual;
    @Getter('getOriginalIndividual', { namespace: 'individual' })
    originalIndividual!: Individual;

    @Prop({ type: Array })
    dateRules;

    menuOne = false;
    menuTwo = false;
    documents: Array<Select> = [];

    async created() {
      await this.getDocumentsAsync();
    }

    async getDocumentsAsync(): Promise<void> {
      const response = await this.$http.get(`${this.endpoint.individual.getByTable}/IndividualDocumentTypes/id/txname`);

      if (response.status === 200) {
        this.documents = response.data;
      }
    }

    cleanSelect(index: number): void {
      this.individual.documents[index].documentTypeId = null;
    }

    addDocument(): void {
      console.log('add', this.individual.documents);

      if (!this.individual.documents) this.individual.documents = [];

      this.individual.documents.push({
        register: '',
        documentDate: null,
        expirationDate: null,
        documentTypeId: ''
      });
    }
  }
