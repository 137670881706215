import { AppState } from './types';

import Entity from '@/src/types/entities/entity';
import LesteEntity from '@/src/types/entities/lesteEntity';
import ConfigForm from '@/src/types/entities/configForm';
import FilterEntity from '@/src/types/entities/filterEntity';
import ExternalEntity from '@/src/types/entities/externalEntity';
import EntityData from '@/src/types/entities/entityData';
import Office from '@/src/types/entities/office';

export default {
  getOffice(state: AppState): Office {
    return state.office;
  },
  getEntity(state: AppState): Entity {
    return state.entity;
  },
  getOriginalEntity(state: AppState): Entity {
    return state.originalEntity;
  },
  getEntityData(state: AppState): EntityData {
    return state.entityData;
  },
  getLesteEntity(state: AppState): LesteEntity {
    return state.lesteEntity;
  },
  getOriginalLesteEntity(state: AppState): LesteEntity {
    return state.originalLesteEntity;
  },
  getExternalEntity(state: AppState): ExternalEntity {
    return state.externalEntity;
  },
  getConfigForm(state: AppState): ConfigForm {
    return state.configForm;
  },
  getFilterEntity(state: AppState): FilterEntity {
    return state.filterEntity;
  }
};
