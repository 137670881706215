import { AppState } from './types';
import Snackbar from '../../types/app/application/Snackbar';
import Filter from '@/src/types/app/application/filter';

export default {
  getSnackbar(state: AppState): Snackbar {
    return state.snackbar;
  },
  getFilter(state: AppState): Filter {
    return state.filter;
  },
  getFullScreen(state: AppState): boolean {
    return state.fullScreen;
  },
  getOverlay(state: AppState): boolean {
    return state.overlay;
  }
};
