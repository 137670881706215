
  import { Component, Watch, Prop, Vue, Ref } from 'vue-property-decorator';
  import { Getter, Action } from 'vuex-class';

  import Individual from '@/src/types/individual/individual';
  import SelectNumber from '@/src/types/app/generic/selectNumber';
  import Employee from '@/src/types/individual/employee';

  @Component({})
  export default class AdmissionDemissionDateComponent extends Vue {
    @Getter('getEmployee', { namespace: 'individual' }) employee!: Employee;
    @Getter('getOriginalEmployee', { namespace: 'individual' })
    originalEmployee!: Employee;

    menuOne = false;
    menuTwo = false;

    get admission(): string | null {
      return this.employee.admission
        ? this.formatDate(new Date(this.employee.admission).toISOString().substr(0, 10))
        : null;
    }
    set admission(value: string | null) {
      this.employee.admission = this.parseDate(value);
    }

    get originalAdmission(): string | null {
      if (this.originalEmployee.admission !== null) {
        return this.formatDate(new Date(this.originalEmployee.admission).toISOString().substr(0, 10));
      }

      return null;
    }

    get demission(): string | null {
      return this.employee.demission
        ? this.formatDate(new Date(this.employee.demission).toISOString().substr(0, 10))
        : null;
    }
    set demission(value: string | null) {
      this.employee.demission = this.parseDate(value);
    }

    get originalDemission(): string | null {
      if (this.originalEmployee.demission !== null) {
        return this.formatDate(new Date(this.originalEmployee.demission).toISOString().substr(0, 10));
      }

      return null;
    }

    @Watch('menuOne')
    private async mnuOne(val) {
      val && setTimeout(() => ((this.$refs['pickerOne'] as any).activePicker = 'YEAR'));
    }

    @Watch('menuTwo')
    private async mnuTwo(val) {
      val && setTimeout(() => ((this.$refs['pickerTwo'] as any).activePicker = 'YEAR'));
    }

    formatDate(date): any {
      if (!date) return null;

      const [year, month, day] = date.split('-');
      return `${month}/${day}/${year}`;
    }

    parseDate(date): any {
      if (!date) return null;

      const [month, day, year] = date.split('/');
      return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`;
    }
  }
