import Header from './header';

export default class ClientHeader {
  public static header: Header[] = [
    {
      text: 'Name',
      align: 'start',
      sortable: true,
      value: 'name',
      class: '',
      width: ''
    },
    {
      text: 'Email',
      align: 'start',
      sortable: true,
      value: 'email',
      class: '',
      width: ''
    },
    {
      text: 'Type',
      align: 'start',
      sortable: true,
      value: 'type',
      class: '',
      width: ''
    },
    {
      text: 'Edit',
      align: 'right',
      value: 'actions',
      sortable: false,
      class: null,
      width: ''
    }
  ];
}
