import { RouteConfig } from 'vue-router';
import Required from '../utils/required';

import IndexAdvisor from '../views/Entities/Common/Index.vue';
import CreateAdvisor from '../views/Entities/Common/Create.vue';
import DetailAdvisor from '../views/Entities/Common/Detail.vue';

import IndexSerie from '../views/Entities/Common/Index.vue';
import CreateSerie from '../views/Entities/Common/Create.vue';
import DetailSerie from '../views/Entities/Common/Detail.vue';

import IndexFeeder from '../views/Entities/Common/Index.vue';
import CreateFeeder from '../views/Entities/Common/Create.vue';
import DetailFeeder from '../views/Entities/Common/Detail.vue';

import IndexGeneralPartner from '../views/Entities/Common/Index.vue';
import CreateGeneralPartner from '../views/Entities/Common/Create.vue';
import DetailGeneralPartner from '../views/Entities/Common/Detail.vue';

import IndexHolding from '../views/Entities/Common/Index.vue';
import CreateHolding from '../views/Entities/Common/Create.vue';
import DetailHolding from '../views/Entities/Common/Detail.vue';

import IndexInvestor from '../views/Entities/Common/Index.vue';
import CreateInvestor from '../views/Entities/Common/Create.vue';
import DetailInvestor from '../views/Entities/Common/Detail.vue';

import IndexMaster from '../views/Entities/Common/Index.vue';
import CreateMaster from '../views/Entities/Common/Create.vue';
import DetailMaster from '../views/Entities/Common/Detail.vue';

import IndexPortfolio from '../views/Entities/Common/Index.vue';
import CreatePortfolio from '../views/Entities/Common/Create.vue';
import DetailPortfolio from '../views/Entities/Common/Detail.vue';

import IndexVehicle from '../views/Entities/Common/Index.vue';
import CreateVehicle from '../views/Entities/Common/Create.vue';
import DetailVehicle from '../views/Entities/Common/Detail.vue';

import IndexOther from '../views/Entities/Common/Index.vue';
import CreateOther from '../views/Entities/Common/Create.vue';
import DetailOther from '../views/Entities/Common/Detail.vue';

import IndexAll from '../views/Entities/Common/Index.vue';
import CreateAll from '../views/Entities/Common/Create.vue';
import DetailAll from '../views/Entities/Common/Detail.vue';

const required = new Required();

const entityRoutes: Array<RouteConfig> = [
  {
    path: '/advisors',
    name: 'IndexAdvisor',
    meta: {
      parent: 'Leste Entity',
      create: 'CreateAdvisor',
      edit: 'DetailAdvisor',
      title: 'Advisors',
      type: 'advisor',
      external: false
    },
    component: IndexAdvisor,
    beforeEnter: required.requireAuth
  },
  {
    path: '/advisor',
    name: 'CreateAdvisor',
    meta: {
      parent: 'Leste Entity',
      before: 'IndexAdvisor',
      index: 'advisors',
      title: 'Create Advisor',
      type: 'advisor',
      external: false
    },
    component: CreateAdvisor,
    beforeEnter: required.requireAuth
  },
  {
    path: '/advisor/:id?',
    name: 'DetailAdvisor',
    meta: {
      parent: 'Leste Entity',
      before: 'IndexAdvisor',
      index: 'advisors',
      title: 'Edit Advisor',
      type: 'advisor',
      external: false
    },
    component: DetailAdvisor,
    beforeEnter: required.requireAuth
  },
  {
    path: '/feeders',
    name: 'IndexFeeder',
    meta: {
      parent: 'Leste Entity',
      create: 'CreateFeeder',
      edit: 'DetailFeeder',
      title: 'Feeders',
      type: 'feeder',
      external: false
    },
    component: IndexFeeder,
    beforeEnter: required.requireAuth
  },
  {
    path: '/feeder',
    name: 'CreateFeeder',
    meta: {
      parent: 'Leste Entity',
      before: 'IndexFeeder',
      index: 'feeders',
      title: 'Create Feeder',
      type: 'feeder',
      external: false
    },
    component: CreateFeeder,
    beforeEnter: required.requireAuth
  },
  {
    path: '/feeder/:id?',
    name: 'DetailFeeder',
    meta: {
      parent: 'Leste Entity',
      before: 'IndexFeeder',
      index: 'feeders',
      title: 'Edit Feeder',
      type: 'feeder',
      external: false
    },
    component: DetailFeeder,
    beforeEnter: required.requireAuth
  },
  {
    path: '/generalpartners',
    name: 'IndexGeneralPartner',
    meta: {
      parent: 'Leste Entity',
      create: 'CreateGeneralPartner',
      edit: 'DetailGeneralPartner',
      title: 'General Partners',
      type: 'gp',
      external: false
    },
    component: IndexGeneralPartner,
    beforeEnter: required.requireAuth
  },
  {
    path: '/generalpartner',
    name: 'CreateGeneralPartner',
    meta: {
      parent: 'Leste Entity',
      before: 'IndexGeneralPartner',
      index: 'generalpartners',
      title: 'Create General Partner',
      type: 'gp',
      external: false
    },
    component: CreateGeneralPartner,
    beforeEnter: required.requireAuth
  },
  {
    path: '/generalpartner/:id?',
    name: 'DetailGeneralPartner',
    meta: {
      parent: 'Leste Entity',
      before: 'IndexGeneralPartner',
      index: 'generalpartners',
      title: 'Edit General Partner',
      type: 'gp',
      external: false
    },
    component: DetailGeneralPartner,
    beforeEnter: required.requireAuth
  },
  {
    path: '/holdings',
    name: 'IndexHolding',
    meta: {
      parent: 'Leste Entity',
      create: 'CreateHolding',
      edit: 'DetailHolding',
      title: 'Holdings',
      type: 'holding',
      external: false
    },
    component: IndexHolding,
    beforeEnter: required.requireAuth
  },
  {
    path: '/holding',
    name: 'CreateHolding',
    meta: {
      parent: 'Leste Entity',
      before: 'IndexHolding',
      index: 'holdings',
      title: 'Create Holding',
      type: 'holding',
      external: false
    },
    component: CreateHolding,
    beforeEnter: required.requireAuth
  },
  {
    path: '/holding/:id?',
    name: 'DetailHolding',
    meta: {
      parent: 'Leste Entity',
      before: 'IndexHolding',
      index: 'holdings',
      title: 'Edit Holding',
      type: 'holding',
      external: false
    },
    component: DetailHolding,
    beforeEnter: required.requireAuth
  },
  {
    path: '/investors',
    name: 'IndexInvestor',
    meta: {
      parent: 'Leste Entity',
      create: 'CreateInvestor',
      edit: 'DetailInvestor',
      title: 'Investors',
      type: 'investor',
      external: false
    },
    component: IndexInvestor,
    beforeEnter: required.requireAuth
  },
  {
    path: '/investor',
    name: 'CreateInvestor',
    meta: {
      parent: 'Leste Entity',
      before: 'IndexInvestor',
      index: 'investors',
      title: 'Create Investor',
      type: 'investor',
      external: false
    },
    component: CreateInvestor,
    beforeEnter: required.requireAuth
  },
  {
    path: '/investor/:id?',
    name: 'DetailInvestor',
    meta: {
      parent: 'Leste Entity',
      before: 'IndexInvestor',
      index: 'investors',
      title: 'Edit Investor',
      type: 'investor',
      external: false
    },
    component: DetailInvestor,
    beforeEnter: required.requireAuth
  },

  {
    path: '/masters',
    name: 'IndexMaster',
    meta: {
      parent: 'Leste Entity',
      create: 'CreateMaster',
      edit: 'DetailMaster',
      title: 'Master Funds',
      type: 'master',
      external: false
    },
    component: IndexMaster,
    beforeEnter: required.requireAuth
  },
  {
    path: '/master',
    name: 'CreateMaster',
    meta: {
      parent: 'Leste Entity',
      before: 'IndexMaster',
      index: 'masters',
      title: 'Create Master Fund',
      type: 'master',
      external: false
    },
    component: CreateMaster,
    beforeEnter: required.requireAuth
  },
  {
    path: '/master/:id?',
    name: 'DetailMaster',
    meta: {
      parent: 'Leste Entity',
      before: 'IndexMaster',
      index: 'masters',
      title: 'Edit Master Fund',
      type: 'master',
      external: false
    },
    component: DetailMaster,
    beforeEnter: required.requireAuth
  },
  {
    path: '/portfolios',
    name: 'IndexPortfolio',
    meta: {
      parent: 'Leste Entity',
      create: 'CreatePortfolio',
      edit: 'DetailPortfolio',
      title: 'Portfolios',
      type: 'portfolio',
      external: false
    },
    component: IndexPortfolio,
    beforeEnter: required.requireAuth
  },
  {
    path: '/portfolio',
    name: 'CreatePortfolio',
    meta: {
      parent: 'Leste Entity',
      before: 'IndexPortfolio',
      index: 'portfolios',
      title: 'Create Portfolio',
      type: 'portfolio',
      external: false
    },
    component: CreatePortfolio,
    beforeEnter: required.requireAuth
  },
  {
    path: '/portfolio/:id?',
    name: 'DetailPortfolio',
    meta: {
      parent: 'Leste Entity',
      before: 'IndexPortfolio',
      index: 'portfolios',
      title: 'Edit Portfolio',
      type: 'portfolio',
      external: false
    },
    component: DetailPortfolio,
    beforeEnter: required.requireAuth
  },
  {
    path: '/series',
    name: 'IndexSerie',
    meta: {
      parent: 'Leste Entity',
      create: 'CreateSerie',
      edit: 'DetailSerie',
      title: 'Series',
      type: 'series',
      external: false
    },
    component: IndexSerie,
    beforeEnter: required.requireAuth
  },
  {
    path: '/serie',
    name: 'CreateSerie',
    meta: {
      parent: 'Leste Entity',
      before: 'IndexSerie',
      index: 'series',
      title: 'Create Serie',
      type: 'series',
      external: false
    },
    component: CreateSerie,
    beforeEnter: required.requireAuth
  },
  {
    path: '/serie/:id?',
    name: 'DetailSerie',
    meta: {
      parent: 'Leste Entity',
      before: 'IndexSerie',
      index: 'series',
      title: 'Edit Serie',
      type: 'series',
      external: false
    },
    component: DetailSerie,
    beforeEnter: required.requireAuth
  },
  {
    path: '/vehicles',
    name: 'IndexVehicle',
    meta: {
      parent: 'Leste Entity',
      create: 'CreateVehicle',
      edit: 'DetailVehicle',
      title: 'Vehicles',
      type: 'vehicle',
      external: false
    },
    component: IndexVehicle,
    beforeEnter: required.requireAuth
  },
  {
    path: '/vehicle',
    name: 'CreateVehicle',
    meta: {
      parent: 'Leste Entity',
      before: 'IndexVehicle',
      index: 'vehicles',
      title: 'Create Vehicle',
      type: 'vehicle',
      external: false
    },
    component: CreateVehicle,
    beforeEnter: required.requireAuth
  },
  {
    path: '/vehicle/:id?',
    name: 'DetailVehicle',
    meta: {
      parent: 'Leste Entity',
      before: 'IndexVehicle',
      index: 'vehicles',
      title: 'Edit Vehicle',
      type: 'vehicle',
      external: false
    },
    component: DetailVehicle,
    beforeEnter: required.requireAuth
  },
  {
    path: '/others',
    name: 'IndexOther',
    meta: {
      parent: 'Leste Entity',
      create: 'CreateAll',
      edit: 'DetailOther',
      title: 'Others',
      type: 'other',
      external: false
    },
    component: IndexOther,
    beforeEnter: required.requireAuth
  },
  {
    path: '/other',
    name: 'CreateAll',
    meta: {
      parent: 'Leste Entity',
      before: 'IndexOther',
      index: 'others',
      title: 'Create Other',
      type: 'other',
      external: false
    },
    component: CreateAll,
    beforeEnter: required.requireAuth
  },
  {
    path: '/other/:id?',
    name: 'DetailOther',
    meta: {
      parent: 'Leste Entity',
      before: 'IndexOther',
      index: 'others',
      title: 'Edit Other',
      type: 'other',
      external: false
    },
    component: DetailOther,
    beforeEnter: required.requireAuth
  },

  {
    path: '/all',
    name: 'IndexAll',
    meta: {
      parent: 'Leste Entity',
      edit: 'DetailAll',
      title: 'All',
      type: 'all',
      external: false
    },
    component: IndexAll,
    beforeEnter: required.requireAuth
  },
  {
    path: '/all/:id?',
    name: 'DetailAll',
    meta: {
      parent: 'Leste Entity',
      before: 'IndexAll',
      index: 'all',
      title: 'Edit',
      type: 'all',
      external: false
    },
    component: DetailAll,
    beforeEnter: required.requireAuth
  }
];

export default entityRoutes;
