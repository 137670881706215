export default {
  filterEntity: {
    entityType: null,
    skip: 0,
    take: 10,
    search: null,
    actives: [true],
    entityTypeIds: [],
    businessUnityIds: [],
    regulatoryLicenseTypeIds: [],
    statusIds: [],
    officeIds: [],
    flgExternal: false,
    ignoreNull: true
  },
  entity: {
    entityType: null,
    entityTypeId: null,
    currencyId: null,
    flgOpen: false,
    name: null,
    shortName: null,
    purpose: null,
    webSite: null,
    socialMedia: null,
    giin: null,
    nif: null,
    corporateRegister: null,
    legalEntityIdentifier: null,
    ein: null,
    cnpj: null,
    branchCnpj: null,
    cnae: null,
    endFiscalYear: null,
    formOrganizationId: null,
    taxPayer: {
      taxTypeId: null,
      effectiveStartDate: null
    },
    entityTaxElection: {
      taxElectionId: null,
      effectiveStartDate: null
    },
    entityRelation: [],
    individualEntityRelation: [],
    phones: [],
    emails: []
  },
  originalEntity: {
    entityType: null,
    entityTypeId: null,
    currencyId: null,
    flgOpen: false,
    name: null,
    shortName: null,
    purpose: null,
    webSite: null,
    socialMedia: null,
    giin: null,
    nif: null,
    corporateRegister: null,
    legalEntityIdentifier: null,
    ein: null,
    cnpj: null,
    branchCnpj: null,
    cnae: null,
    endFiscalYear: null,
    formOrganizationId: null,
    taxPayer: {
      taxTypeId: null,
      effectiveStartDate: null
    },
    entityTaxElection: {
      taxElectionId: null,
      effectiveStartDate: null
    },
    entityRelation: [],
    individualEntityRelation: [],
    phones: [],
    emails: []
  },
  lesteEntity: {
    jurisdictionId: null,
    regulatoryLicenseTypeId: null,
    entityBusinessUnity: {
      businessUnityId: null,
      effectiveStartDate: null
    },
    entityStatus: {
      entityStatusId: null,
      effectiveStartDate: null
    },
    entityOffice: {
      entityOfficeId: null,
      effectiveStartDate: null
    }
  },
  originalLesteEntity: {
    jurisdictionId: null,
    regulatoryLicenseTypeId: null,
    entityBusinessUnity: {
      businessUnityId: null,
      effectiveStartDate: null
    },
    entityStatus: {
      entityStatusId: null,
      effectiveStartDate: null
    },
    entityOffice: {
      entityOfficeId: null,
      effectiveStartDate: null
    }
  },
  externalEntity: {
    cityId: null,
    street: null,
    number: null,
    complement: null,
    district: null,
    postalCode: null
  },
  configForm: {
    giin: true,
    nif: true,
    corporateRegister: true,
    legalIdentifier: true,
    ein: true,
    cnpj: true,
    cnae: true
  },
  entityData: {
    total: 0,
    hasMore: true,
    data: []
  },
  office: {
    active: null,
    name: null,
    shortName: null,
    cityId: '',
    address: null,
    number: null,
    complement: null,
    district: null,
    postalCode: null
  }
};
