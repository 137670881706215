
  import { Component, Prop, Vue } from 'vue-property-decorator';
  import { Getter } from 'vuex-class';

  import Entity from '@/src/types/entities/entity';

  @Component({})
  export default class NifComponent extends Vue {
    @Getter('getOriginalEntity', { namespace: 'entity' }) originalEntity!: Entity;
    @Getter('getEntity', { namespace: 'entity' }) entity!: Entity;

    @Prop({ type: Array })
    rules;

    @Prop({ type: Boolean })
    required;
  }
