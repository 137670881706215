import Header from './header';

export default class GeneralHeader {
  public static header: Header[] = [
    {
      text: 'Name',
      align: 'start',
      sortable: true,
      value: 'name',
      class: '',
      width: ''
    },
    {
      text: 'Short Name',
      align: 'start',
      sortable: true,
      value: 'shortName',
      class: '',
      width: ''
    },
    {
      text: 'Status',
      align: 'start',
      sortable: true,
      value: 'activeText',
      class: '',
      width: ''
    },
    {
      text: 'Edit',
      align: 'right',
      value: 'actions',
      sortable: false,
      class: null,
      width: ''
    }
  ];
}
