import Client from '@/src/types/client/client';
import Employee from '@/src/types/individual/employee';
import Individual from '@/src/types/individual/individual';
import { ActionContext } from 'vuex';
import { AppState } from './types';

export default {
  setClient(store: ActionContext<AppState, any>, client: Client) {
    store.commit('setClient', client);
    store.commit('setOriginalClient', JSON.parse(JSON.stringify(client)));
  },
  setIndividual(store: ActionContext<AppState, any>, individual: Individual) {
    store.commit('setIndividual', individual);
    store.commit('setOriginalIndividual', JSON.parse(JSON.stringify(individual)));
  },
  setEmployee(store: ActionContext<AppState, any>, employee: Employee) {
    store.commit('setEmployee', employee);
    store.commit('setOriginalEmployee', JSON.parse(JSON.stringify(employee)));
  },
  setNewEmployee(store: ActionContext<AppState, any>) {
    //#region Employee
    store.commit('setEmployeeEmpty', {
      nickName: null,
      admission: null,
      demission: null,
      fatherName: null,
      motherName: null,
      miniCurriculum: null,
      maritalStatusId: null,
      dependents: [],
      courseCertifications: [],
      individualUnityBusiness: []
    });
    //#endregion

    //#region Original Employee
    store.commit('setOriginalEmployeeEmpty', {
      nickName: null,
      admission: null,
      demission: null,
      fatherName: null,
      motherName: null,
      miniCurriculum: null,
      maritalStatusId: null,
      dependents: [],
      courseCertifications: [],
      individualUnityBusiness: []
    });
    //#endregion

    //#region Individual
    store.commit('setIndividualEmpty', {
      name: null,
      socialName: null,
      bornDate: null,
      gender: null,
      naturalness: null,
      nationality: null,
      documents: [],
      accounts: [],
      addresses: [],
      emails: [],
      phones: [],
      individualRelations: [],
      individualIndividualRelations: []
    });
    //#endregion

    //#region Original Individual
    store.commit('setOriginalIndividualEmpty', {
      name: null,
      socialName: null,
      bornDate: null,
      gender: null,
      naturalness: null,
      nationality: null,
      documents: [],
      accounts: [],
      addresses: [],
      emails: [],
      phones: [],
      individualRelations: [],
      individualIndividualRelations: []
    });
    //#endregion
  },
  setIndividualType(store: ActionContext<AppState, any>, type: string): void {
    store.commit('setIndividualType', type);
  },

  setNewClient(store: ActionContext<AppState, any>) {
    //#region Client
    store.commit('setClientEmpty', {
      typeClient: 0,
      active: true,
      individual: {}
    });
    //#endregion

    //#region Original Client
    store.commit('setOriginalClientEmpty', {
      typeClient: 0,
      active: true,
      individual: {}
    });
    //#endregion

    //#region Individual
    store.commit('setIndividualEmpty', {
      name: null,
      socialName: null,
      bornDate: null,
      gender: null,
      naturalness: null,
      nationality: null,
      documents: [],
      accounts: [],
      addresses: [],
      emails: [],
      phones: [],
      individualRelations: [],
      individualIndividualRelations: []
    });
    //#endregion

    //#region Original Individual
    store.commit('setOriginalIndividualEmpty', {
      name: null,
      socialName: null,
      bornDate: null,
      gender: null,
      naturalness: null,
      nationality: null,
      documents: [],
      accounts: [],
      addresses: [],
      emails: [],
      phones: [],
      individualRelations: [],
      individualIndividualRelations: []
    });
    //#endregion
  }
};
