
import { Component, Vue, Prop } from 'vue-property-decorator';
import { Getter, Action } from 'vuex-class';

@Component({})
export default class ModalSaveFinished extends Vue {
  @Getter('getNoteFieldFull', { namespace: 'checklist' })
  noteFieldFull!: boolean;
  @Action('setNoteFieldFull', { namespace: 'checklist' })
  setNoteFieldFull!: any;

  @Getter('getChecklistEntityStageKey', { namespace: 'checklist' })
  checklistEntityStageKey!: string;

  @Getter('getNote', { namespace: 'checklist' })
  public note!: string;
  @Action('setNote', { namespace: 'checklist' }) public setNote!: string;

  @Prop(Boolean) readonly loading: boolean | undefined;

  @Prop(String) noteField: string | undefined;

  public postNotes() {
    this.$emit('postNotes');
  }

  closeModal() {
    this.$emit('closeModal');
  }
}
