
import Entity from '@/src/types/entities/entity';
import { Component, Ref, Vue } from 'vue-property-decorator';
import { Getter, Action } from 'vuex-class';

import Endpoint from '../../../utils/endpoint';

import EntityName from '../../../components/entities/EntityName.vue';
import RegulatoryLicenseType from '../../../components/entities/fields/RegulatoryLicenseType.vue';
import EntityUnityBusiness from '../../../components/entities/EntityUnityBusiness.vue';
import EntityStatus from '../../../components/entities/EntityStatus.vue';
import EntityOffice from '../../../components/entities/EntityOffice.vue';
import EntityPhone from '../../../components/entities/Phone.vue';
import EntityEmail from '../../../components/entities/Email.vue';
import TaxPayer from '../../../components/entities/TaxPayer.vue';
import EntityTaxElection from '../../../components/entities/EntityTaxElection.vue';
import EntityRelation from '../../../components/entities/EntityRelation.vue';
import IndividualRelation from '../../../components/entities/IndividualRelation.vue';

//fields
import Jurisdiction from '../../../components/entities/fields/Jurisdiction.vue';
import FormOrganization from '../../../components/entities/fields/FormOrganization.vue';
import LesteEntity from '@/src/types/entities/lesteEntity';
import EntityType from '../../../components/entities/fields/EntityType.vue';
import Currency from '../../../components/entities/fields/Currency.vue';

import Giin from '../../../components/entities/fields/Giin.vue';

import Nif from '../../../components/entities/fields/Nif.vue';
import CorporateRegister from '../../../components/entities/fields/CorporateRegister.vue';
import LegalIdentifier from '../../../components/entities/fields/LegalIdentifier.vue';
import Cnpj from '../../../components/entities/fields/Cnpj.vue';
import Cnae from '../../../components/entities/fields/Cnae.vue';
import Ein from '../../../components/entities/fields/Ein.vue';
import FiscalYear from '../../../components/entities/fields/FiscalYear.vue';
import CommandsComponent from '../../../components/generics/Commands.vue';

@Component({
  components: {
    EntityName: EntityName,
    FormOrganization: FormOrganization,
    RegulatoryLicenseType: RegulatoryLicenseType,
    Jurisdiction: Jurisdiction,
    EntityUnityBusiness: EntityUnityBusiness,
    EntityStatus: EntityStatus,
    EntityOffice: EntityOffice,
    FiscalYear: FiscalYear,
    GIIN: Giin,
    Nif: Nif,
    CorporateRegister: CorporateRegister,
    LegalIdentifier: LegalIdentifier,
    Cnpj: Cnpj,
    Cnae: Cnae,
    Ein: Ein,
    EntityPhone: EntityPhone,
    EntityEmail: EntityEmail,
    TaxPayer: TaxPayer,
    EntityTaxElection: EntityTaxElection,
    EntityRelation: EntityRelation,
    EntityType: EntityType,
    Currency: Currency,
    IndividualRelation: IndividualRelation,
    CommandsComponent: CommandsComponent,
  },
})
export default class DetailCommon extends Vue {
  endpoint = new Endpoint();

  @Getter('getEntity', { namespace: 'entity' }) entity!: Entity;
  @Getter('getOriginalEntity', { namespace: 'entity' }) originalEntity!: Entity;
  @Getter('getLesteEntity', { namespace: 'entity' }) lesteEntity!: LesteEntity;
  @Getter('getConfigForm', { namespace: 'entity' }) configForm!: any;

  @Action('setEntity', { namespace: 'entity' }) setEntity!: any;
  @Action('setLesteEntity', { namespace: 'entity' }) setLesteEntity!: any;
  @Action('setSnackbar', { namespace: 'app' }) setSnackbar!: any;

  valid = null;
  saving = false;
  loading = false;
  dialog = false;
  siteTitle = 'Corporative';
  rules = [v => !!v || 'This field is required'];
  dateRules = [
    (v: string) => {
      const message = 'Invalid date mm/dd/yyyy';

      if (v == null) return true;

      if (v.length < 10) return message;

      const [m, d, y] = v.split('/');

      const month = parseInt(m);
      const day = parseInt(d);
      const year = parseInt(y);

      if (day < 1 || day > 31 || day === 0) return message;

      if ((month < 1 && month > 12) || month === 0) return message;

      if (year < 1920 || year > 2100 || year === 0) return message;

      return true;
    },
  ];

  async created() {
    await this.getEntityAsync();
  }

  entityModel = {
    active: true,
    flgExternal: this.$route.meta?.external,
    lesteEntity: {},
    entity: {
      entityType: '',
    },
  };

  async getEntityAsync(): Promise<void> {
    try {
      this.loading = true;

      const response = await this.$http.get(
        `${this.endpoint.entity.getById}/${this.$route.params.id}`
      );

      const obj = response.data.data;
      console.log('entity', JSON.parse(JSON.stringify(obj)));

      this.entityModel.active = obj.active;

      obj.entityBusinessUnity.effectiveStartDate = obj.entityBusinessUnity
        .effectiveStartDate
        ? new Date(obj.entityBusinessUnity.effectiveStartDate)
        : null;

      obj.entityStatus.effectiveStartDate = obj.entityStatus.effectiveStartDate
        ? new Date(obj.entityStatus.effectiveStartDate)
        : null;

      obj.entityOffice.effectiveStartDate = obj.entityOffice.effectiveStartDate
        ? new Date(obj.entityOffice.effectiveStartDate)
        : null;

      obj.entityTaxElection.effectiveStartDate = obj.entityTaxElection
        .effectiveStartDate
        ? new Date(obj.entityTaxElection.effectiveStartDate)
        : null;

      obj.taxPayer.effectiveStartDate = obj.taxPayer.effectiveStartDate
        ? new Date(obj.taxPayer.effectiveStartDate)
        : null;

      obj.entityRelation.effectiveStartDate = obj.entityRelation
        .effectiveStartDate
        ? new Date(obj.entityRelation.effectiveStartDate)
        : null;

      const entity = Object.assign(
        {},
        {
          entityType: obj.entityType,
          entityTypeId: obj.entityTypeId,
          currencyId: obj.currencyId,
          flgOpen: obj.flgOpen,
          name: obj.name,
          shortName: obj.shortName,
          purpose: obj.purpose,
          webSite: obj.website,
          socialMedia: obj.socialMedia,
          giin: obj.giin,
          nif: obj.nif,
          corporateRegister: obj.corporateRegister,
          legalEntityIdentifier: obj.legalEntityIdentifier,
          ein: obj.ein,
          cnpj: obj.cnpj,
          branchCnpj: obj.branchCnpj,
          cnae: obj.cnae,
          endFiscalYear: obj.endFiscalYear,
          formOrganizationId: obj.formOrganizationId,
          taxPayer: obj.taxPayer,
          entityTaxElection: obj.entityTaxElection,
          entityRelation: obj.entityRelation,
          individualEntityRelation: obj.individualEntityRelation,
          phones: obj.phones,
          emails: obj.emails,
        }
      );

      this.setEntity(entity);
      // this.setOriginalEntity(JSON.parse(JSON.stringify(entity)));

      const lesteEntity = Object.assign(
        {},
        {
          jurisdictionId: obj.jurisdictionId,
          regulatoryLicenseTypeId: obj.regulatoryLicenseTypeId,
          entityBusinessUnity: obj.entityBusinessUnity,
          entityStatus: obj.entityStatus,
          entityOffice: obj.entityOffice,
        }
      );

      this.setLesteEntity(lesteEntity);
      // this.setOriginalLesteEntity(JSON.parse(JSON.stringify(lesteEntity)));

      this.loading = false;
    } catch (error) {
      console.log('erro', error);
      this.loading = false;
    }
  }

  async save(): Promise<void> {
    if ((this.$refs.form as Vue & { validate: () => boolean }).validate()) {
      try {
        this.saving = true;

        Object.assign(this.entityModel.lesteEntity, this.lesteEntity);
        Object.assign(this.entityModel.entity, this.entity);

        const response = await this.$http.put(this.endpoint.entity.update, {
          id: this.$route.params.id,
          request: this.entityModel,
        });

        this.saving = false;

        this.setSnackbar({
          show: true,
          message: 'Entity updated with success',
          color: 'success',
        });

        this.$router.push({ name: this.$route.meta?.before });
      } catch (error) {
        console.log('error', error);
        this.saving = false;

        this.setSnackbar({
          show: true,
          message: 'Update Entity Fail',
          color: 'error',
        });
      }
    }
  }

  async remove(): Promise<void> {
    console.log('removendo...');
  }

  goToIndex(): string {
    return this.$route.meta?.external
      ? `/external/${this.$route.meta?.index}`
      : `/${this.$route.meta?.index}`;
  }
}
