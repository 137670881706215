import moment from "moment";

export default function handleFormatDateHours(date: string) {
    const dateFormatted = moment(date).format('DD/MMMM/YYYY');
    const dateFormattedH = moment(date).format('HH:mm')
    const dateSeparated = dateFormatted.split('/');
    const monthFormatted = dateSeparated[1]?.substring(0, 3);
    const dateResult =
    dateFormattedH + ' - ' + dateSeparated[0] + '/' + monthFormatted + '/' + dateSeparated[2];

    return date ? dateResult : '';
  }
