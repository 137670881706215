import ChecklistTrigger from './checklistTrigger';
import ChecklistStage from './checklistStage';
import Entities from './entities'
export default interface Checklist {
  id: number;
  checklistKey: string;
  checklistEntityKey: string;
  name: string;
  teamKey: string;
  ownerKey: string;
  interval: string;
  startDate: Date;
  checklistTriggers: ChecklistTrigger[];
  checklistStages: ChecklistStage[];
  checklistEntities: Entities[];
  modal: boolean;
}

const checklistInitValue: Checklist = {
  id: 0,
  checklistKey: '',
  checklistEntityKey: '',
  name: '',
  teamKey: '',
  ownerKey: '',
  interval: '',
  startDate: new Date(),
  checklistTriggers: [],
  checklistStages: [],
  checklistEntities: [],
  modal: true || false,
};

export { checklistInitValue };
