
import { Component, Vue } from 'vue-property-decorator';
import { Action } from 'vuex-class';

import Generic from '../../types/app/generic/generic';
import Endpoint from '../../utils/endpoint';

import CommandsComponent from '../../components/generics/Commands.vue';

@Component({
  components: {
    CommandsComponent: CommandsComponent,
  },
})
export default class BusinessUnit extends Vue {
  endpoint = new Endpoint();
  @Action('setSnackbar', { namespace: 'app' }) setSnackbar!: any;

  valid = null;
  saving = false;
  loading = false;
  rules = [
    v => !!v || 'This field is required',
    //v => (v && v.length <= 10) || 'Name must be less than 10 characters'
  ];
  isCreate = true;

  businessUnit: Generic = {
    name: '',
    shortName: '',
    active: true,
    site: '',
    folder: '',
  };

  async created() {
    this.isCreate = !this.$route.params.id;
    if (!this.isCreate) {
      this.loading = true;
      await this.getBusinessUnit();
    }
  }

  get title(): string {
    return this.isCreate ? 'Create BusinessUnit' : 'Business Unit Detail';
  }

  async getBusinessUnit(): Promise<void> {
    this.loading = true;
    const response = await this.$http.get(
      `${this.endpoint.businessUnit.getById}/${this.$route.params.id}`
    );
    console.log('resp', response);

    if (response.status == 200) {
      this.businessUnit = response.data.data;
      this.loading = false;
    }
  }

  async save(): Promise<void> {
    if ((this.$refs.form as Vue & { validate: () => boolean }).validate()) {
      if (this.isCreate) {
        await this.create();
      } else {
        await this.update();
      }
    }
  }

  async update(): Promise<void> {
    this.saving = true;

    const response = await this.$http.put(
      `${this.endpoint.businessUnit.update}/${this.$route.params.id}`,
      this.businessUnit
    );

    if (response.status == 200) {
      this.saving = false;

      this.setSnackbar({
        show: true,
        message: 'BusinessUnit update',
        color: 'success',
      });

      this.$router.push({ name: 'IndexBusinessUnit' });
    } else {
      this.saving = false;
      console.log('erro...', response);
    }
  }

  async create(): Promise<void> {
    this.saving = true;

    const response = await this.$http.post(
      this.endpoint.businessUnit.create,
      this.businessUnit
    );

    if (response.status == 200) {
      this.saving = false;

      this.setSnackbar({
        show: true,
        message: 'Business Unit saved',
        color: 'success',
      });

      this.$router.push({ name: 'IndexBusinessUnit' });
    }

    if (response.status != 200) {
      this.saving = false;
    }
  }

  goToIndex(): string {
    return `/${this.$route.meta?.index}`;
  }
}
