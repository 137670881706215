import { AuthService } from '../services/authService';
export default class Required {
  authService = new AuthService();
  public requireAuth = async (to: any, from: any, next: any) => {
    const token = await this.authService.getTokenForRequiredAsync();

    const route = window.location.href.split('/');
    const routeFormatted = `/${route.splice(3, route.length).join('/')}`;

    if (!token || token == null) {
      localStorage.setItem('route', routeFormatted);
      next({ path: '/login' });
    } else {
      next();
    }
  };
}
