
import { Component, Vue, Prop } from 'vue-property-decorator';
import { Getter, Action } from 'vuex-class';

import User from '../../../types/auth/User';

@Component({})
export default class ModalEnableStatus extends Vue {
  @Getter('getEnableModal', { namespace: 'checklist' })
  enableStatusModal!: boolean;
  @Action('setEnableModal', { namespace: 'checklist' }) setEnableModal!: any;

  @Getter('getChecklistKeyId', { namespace: 'checklist' })
  checklistKeyId!: boolean;
  @Action('setChecklistKeyId', { namespace: 'checklist' })
  setChecklistKeyId!: any;

  @Getter('getUser', { namespace: 'auth' }) user!: User;

  @Prop(Boolean) readonly loading: boolean | undefined;
  @Prop(String) readonly id: string | undefined;
  @Prop(Number) statusId: number | undefined;

  public async enableStatus() {
    const params = {
      checklistKey: this.checklistKeyId,
      userUpdatedLogin: this.user.email,
    };
    this.$emit('enableStatus', params);
    this.$emit('enableStatusView');
  }

  public putRunCicle() {
    this.$emit('putRunCicle');
  }

  public ifPutEnableOrRunCicle() {
    if (this.statusId === 2) {
      this.enableStatus();
    }
    if (this.statusId === 3) {
      this.putRunCicle();
    }
  }

  closeModal() {
    this.setEnableModal(false);
  }
}
