import Vue from 'vue';
import Vuetify from 'vuetify/lib';

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    dark: false,
    themes: {
      light: {
        primary: '#013767',
        secondary: '#EEEEEE',
        tertiary: '#E0E0E0'
      }
    }
  }
});
