import { UserState } from './types';
import User from '../../types/auth/User';
import Area from '@/src/types/auth/area';
import CurrentRoute from '@/src/types/auth/currentRoute';

export default {
  setUser(state: UserState, user: User) {
    state.user = user;
  },
  setAreas(state: UserState, areas: Area[]) {
    state.areas = areas;
  },
  setCurrentRoute(state: UserState, currentRoute: CurrentRoute) {
    state.currentRoute = currentRoute;
  }
};
