
  import { Component, Watch, Prop, Vue, Ref } from 'vue-property-decorator';
  import { Getter, Action } from 'vuex-class';

  @Component({})
  export default class IndividualRelationValuesComponent extends Vue {
    @Prop({ type: Object })
    model;

    isValueActive = false;
    isPercentActive = false;

    get percent(): string | null {
      if (this.isPercentActive || !this.model.percent) {
        return `${this.model.percent}`;
      } else {
        return '% ' + this.model.percent.toFixed(0).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, '$1,');
      }
    }
    set percent(value: string | null) {
      if (!value) value = '0';

      let newValue = parseFloat(value.replace(/[^\d\\.]/g, ''));
      if (isNaN(newValue)) {
        newValue = 0;
      }

      this.model.percent = newValue > 100 ? 100 : newValue;
    }

    get value(): string | null {
      if (this.isValueActive || !this.model.value) {
        return `${this.model.value}`;
      } else {
        return this.$formatCurrency(this.model.value, true);
      }
    }
    set value(value: string | null) {
      if (!value) value = '0';

      let newValue = parseFloat(value.replace(/[^\d\\.]/g, ''));
      if (isNaN(newValue)) {
        newValue = 0;
      }
      this.model.value = newValue;
    }

    get valueFormated(): string {
      return this.$formatCurrency(this.model.value, this.isValueActive);
    }
  }
