
  import { Component, Watch, Prop, Vue, Ref } from 'vue-property-decorator';
  import { Getter, Action } from 'vuex-class';

  @Component({})
  export default class RelationDateComponent extends Vue {
    menuStart = false;
    menuEnd = false;

    @Prop({ type: Array })
    dateRules;

    @Prop({ type: Object })
    entityRelation;

    get effectiveStartDate(): string | null {
      return this.entityRelation.effectiveStartDate
        ? this.formatDate(new Date(this.entityRelation.effectiveStartDate).toISOString().substr(0, 10))
        : null;
    }
    set effectiveStartDate(value: string | null) {
      this.entityRelation.effectiveStartDate = this.parseDate(value);
    }

    @Watch('menuStart')
    private async mnu(val) {
      val && setTimeout(() => ((this.$refs['picker'] as any).activePicker = 'YEAR'));
    }

    get effectiveEndDate(): string | null {
      return this.entityRelation.effectiveEndDate
        ? this.formatDate(new Date(this.entityRelation.effectiveEndDate).toISOString().substr(0, 10))
        : null;
    }
    set effectiveEndDate(value: string | null) {
      this.entityRelation.effectiveEndDate = this.parseDate(value);
    }

    @Watch('menuEnd')
    private async mnuEnd(val) {
      val && setTimeout(() => ((this.$refs['picker'] as any).activePicker = 'YEAR'));
    }

    formatDate(date): string | null {
      if (!date) return null;

      const [year, month, day] = date.split('-');
      return `${month}/${day}/${year}`;
    }

    parseDate(date): string | null {
      if (!date) return null;

      const [month, day, year] = date.split('/');
      return `${month.padStart(2, '0')}-${day.padStart(2, '0')}-${year}`;
    }
  }
