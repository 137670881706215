
import Vue from 'vue';
import Component from 'vue-class-component';
import { Getter, Action } from 'vuex-class';
import User from './types/auth/User';
import { AuthService } from './services/authService';
import Snackbar from './types/app/application/snackbar';
import { Watch } from 'vue-property-decorator';
import axios from 'axios';
import Area from './types/auth/area';

import Endpoint from './utils/endpoint';
import Entity from './types/entities/entity';
import { TokenService } from './services/tokenService';
import Checklist from './types/checklist/checklist';

import ChecklistTaskModal from '@/components/checklist/Modais/ChecklistTaskModal.vue';
import Feedback from '../src/components/checklist/Modais/Feedback.vue';

@Component({
  components: {
    Feedback,
  },
})
export default class App extends Vue {
  endpoint = new Endpoint();
  authService = new AuthService();
  instance = axios.create();

  @Getter('getEntity', { namespace: 'entity' }) entity!: Entity;
  @Getter('getOriginalEntity', { namespace: 'entity' }) originalEntity!: Entity;

  @Getter('getUser', { namespace: 'auth' }) user!: User;
  @Getter('getAreas', { namespace: 'auth' }) items!: Area[];

  @Getter('getSnackbar', { namespace: 'app' }) snackbar!: Snackbar;
  @Getter('getFullScreen', { namespace: 'app' }) fullScreen!: boolean;

  @Getter('getChecklist', { namespace: 'checklist' }) checklist!: Checklist;

  @Action('setUser', { namespace: 'auth' }) setUser: any;
  @Action('setSnackbar', { namespace: 'app' }) setSnackbar!: any;
  @Action('setAreas', { namespace: 'auth' }) setAreas: any;
  @Action('setCurrentRoute', { namespace: 'auth' }) setCurrentRoute: any;

  @Action('setEntityType', { namespace: 'entity' }) setEntityType!: any;
  @Action('setNewEntity', { namespace: 'entity' }) setNewEntity!: any;

  @Action('setIndividualType', { namespace: 'individual' })
  setIndividualType!: any;
  @Action('setNewEmployee', { namespace: 'individual' }) setNewEmployee!: any;

  @Action('setChecklist', { namespace: 'checklist' }) setChecklist!: any;

  siteTitle = 'Corporative';
  drawer = false;
  group = null;
  fav = true;
  menu = false;
  message = false;
  hints = true;
  messages = 6;
  show = false;
  imgUrl = '';
  userAccounts: any[] = [];
  gettingMenu = false;
  interval = {} as any;
  value = 0;

  async created() {
    await this.initialize();
    this.setActive();

    window.addEventListener('beforeunload', this.beforeWindowUnload);
    window.addEventListener('blur', this.tabBlur);
    window.addEventListener('focus', this.tabFocus);
  }

  mounted() {
    this.interval = setInterval(() => {
      if (this.value === 100) {
        return (this.value = 0);
      }
      this.value += 10;
    }, 500);

    this.setNewEntity();
    this.setEntityType(this.$route.meta?.type);
  }

  tabBlur() {
    if (this.hasChanged) {
      this.siteTitle = document.title;
      document.title = 'Work unsaved';
    }
  }

  tabFocus() {
    document.title = this.siteTitle;
  }

  beforeWindowUnload(e: any) {
    if (this.hasChanged) {
      e.preventDefault();
      e.returnValue = '';
    }
  }

  @Watch('$route')
  private route(route) {
    this.setCurrentRoute({
      parent: this.$route.meta?.parent,
      name: this.$route.name,
    });
    this.setActive();

    this.setNewEntity();
    this.setEntityType(this.$route.meta?.type);

    this.setIndividualType(this.$route.meta?.type);
    this.setNewEmployee();
  }

  // get accounts(): any[] {
  //   return this.userAccounts;
  // }

  get hasChanged(): boolean {
    const changed = this.$toHash(JSON.stringify(this.entity));
    const original = this.$toHash(JSON.stringify(this.originalEntity));

    return changed != original;
  }

  active(route): boolean {
    return this.$route.name === route || this.$route.meta?.before === route;
  }

  setActive(): void {
    for (let i = 0; i < this.items.length; i++) {
      this.items[i].active = this.items[i].title === this.$route.meta?.parent;
    }
  }

  async switchAccount(): Promise<void> {
    const accessToken = await this.authService.loginAsync();
    if (accessToken) {
      const userData = this.authService.getTokenData();

      this.setUser(userData);

      await this.getMenus();
      await this.getGraphToken();
    } else {
      await this.logout();
    }
  }

  async getAllAccounts(): Promise<void> {
    this.userAccounts = await this.authService.getAccountsAsync();
  }

  async getMenus(): Promise<void> {
    try {
      this.gettingMenu = true;

      // const token = await this.authService.getTokenForMenuAsync();

      // const resp = await this.instance({
      //   method: 'get',
      //   url: `${this.endpoint.auth.getMenus}/${process.env.VUE_APP_ID}`,
      //   headers: { Authorization: `Bearer ${token}` }
      // });

      const resp = await this.$http
        .get(`${this.endpoint.auth.getMenus}/${process.env.VUE_APP_ID}
        /${this.user.email}`);

      let data = resp.data;

      if (resp.data.hasStatusErrors) {
        data = [];
        this.setSnackbar({
          show: true,
          color: 'red',
          message: `${resp.data.statusCode.item2} (${resp.data.statusCode.item1})`,
        });
      }

      this.setAreas(data);

      this.gettingMenu = false;
      clearInterval(this.interval);
    } catch (error) {
      this.setSnackbar({
        show: true,
        color: 'red',
        message: error.data,
      });
    }
  }

  async getGraphToken(): Promise<void> {
    const graphToken = await this.authService.getTokenGraph();
    if (graphToken != null) {
      try {
        const resp = await this.instance({
          method: 'get',
          url: 'https://graph.microsoft.com/v1.0/me/photos/48x48/$value',
          responseType: 'blob',
          headers: { Authorization: `Bearer ${graphToken}` },
        });

        this.imgUrl = URL.createObjectURL(resp.data);
      } catch (error) {
        this.imgUrl = require('./assets/avatar.png');
      }
    } else {
      this.imgUrl = require('./assets/avatar.png');
    }
  }

  async initialize(): Promise<void> {
    if (this.$route.name !== 'Logout' && this.$route.name !== 'Login') {
      const userData = this.authService.getTokenData();

      if (userData) {
        this.setUser(userData);

        await this.getMenus();
        await this.getGraphToken();
      }
    }
  }

  closeSnackbar(): void {
    this.setSnackbar({
      show: false,
      color: '',
      message: '',
    });
  }

  goTo(route: string): void {
    this.$router.push({ name: route });
  }

  logout(): void {
    this.authService.logoutAsync();
  }

  cleanCache(): void {
    this.$router.push({ name: 'Home' });
  }
}
