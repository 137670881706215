import LinkData from '@/src/types/organization/linkData';
import Node from '@/src/types/organization/node';
import NodeData from '@/src/types/organization/nodeData';
import NodeInfo from '@/src/types/organization/nodeInfo';
import { stringify } from 'qs';
import { ActionContext } from 'vuex';
import { AppState } from './types';

export default {
  setNode(store: ActionContext<AppState, any>, node: Node) {
    store.commit('setNode', node);
  },
  setNodeData(store: ActionContext<AppState, any>, nodeData: NodeData[]) {
    store.commit('setNodeData', nodeData);
  },
  setLinkData(store: ActionContext<AppState, any>, linkData: LinkData[]) {
    store.commit('setLinkData', linkData);
  },

  setChartDataEmpty(store: ActionContext<AppState, any>) {
    store.commit('setNode', {});
    store.commit('setNodeData', []);
    store.commit('setLinkData', []);
  },

  setNodeAndLinkDataEmpty(store: ActionContext<AppState, any>) {
    store.commit('setNodeData', []);
    store.commit('setLinkData', []);
  },

  addNodeData(store: ActionContext<AppState, any>, nodeData: NodeData) {
    store.commit('addNodeData', nodeData);
  },
  addLinkData(store: ActionContext<AppState, any>, linkData: LinkData) {
    store.commit('addLinkData', linkData);
  },
  renewNodeData(store: ActionContext<AppState, any>, nodeData: NodeData) {
    store.commit('renewNodeData', nodeData);
  },
  renewLinkData(store: ActionContext<AppState, any>, linkData: LinkData) {
    store.commit('renewLinkData', linkData);
  },
  removeNode(store: ActionContext<AppState, any>, key: string) {
    store.commit('removeNode', key);
  },
  setNodeInfo(store: ActionContext<AppState, any>, nodeInfo: NodeInfo) {
    store.commit('setNodeInfo', nodeInfo);
  }
};
