
import { Component, Vue, Prop } from 'vue-property-decorator';
import { Getter, Action } from 'vuex-class';
import { Watch } from 'vue-property-decorator';

import Endpoint from '../../../utils/endpoint';

import Header from '../../../types/app/headers/header';
import ChecklistEntitiesModal from '../../../types/app/headers/ChecklistEntitiesModal';

import TriggerPayload from '../../../types/checklist/triggers/checklistTriggerPayload';
import ChecklistStageEntities from '../../../types/checklist/checklistStageEntities';
import User from '../../../types/auth/User';

@Component({})
export default class ModalEntities extends Vue {
  private endpoint = new Endpoint();
  headers: Array<Header> = ChecklistEntitiesModal.indexHeader;

  @Getter('getEntitiesModal', { namespace: 'checklist' })
  entitiesChecklistModal!: boolean;
  @Action('setEntitiesModal', { namespace: 'checklist' })
  setEntitiesModal!: any;
  @Getter('getUser', { namespace: 'auth' }) user!: User;

  @Prop({ type: Array, required: true }) triggers!: TriggerPayload[];
  @Prop(Array) entities!: ChecklistStageEntities[];
  @Prop(Array) entitiesSelected!: ChecklistStageEntities[];

  @Prop(Boolean) readonly loading: boolean | undefined;

  @Prop(String) totalRecords!: string;

  @Watch('entitiesChecklistModal')
  async handlePostEntities() {
    if (this.entitiesChecklistModal === true) await this.entitiesChecklist();
  }

  entitiesTriggers: any[] = [];

  async entitiesChecklist() {
    this.emptyVariables();
    this.createEntitiesTriggers();
    await this.getEntities();
  }

  async getEntities() {
    await this.$emit('getEntities', this.entitiesTriggers);
  }

  emptyVariables() {
    this.entitiesTriggers = [];
    this.$emit('emptyVariablesEntities');
  }

  createEntitiesTriggers() {
    this.triggers.forEach((element) => {
      this.entitiesTriggers.push({
        triggerTypeId: element.triggerTypeId,
        triggerReferenceId: element.triggerReferenceId,
      });
    });
  }

  async setIgnoredEntities() {
    this.$emit('setIgnoredEntities', this.entitiesSelected);
  }

  closeModal() {
    this.$emit('closeModal');
  }
}
