
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Getter, Action } from 'vuex-class';
import Endpoint from '../../utils/endpoint';
import FormatDate from '../../utils/formatDate';
import VueSkeletonLoader from 'skeleton-loader-vue';
import ChecklistTrigger from '../../types/checklist/checklistTrigger';
import checklist from '@/src/vuex/checklist';
import User from '../../types/auth/User';
import ChecklistInterface, {
  checklistInitValue,
} from '../../types/checklist/checklist';
import ChecklistDisabledStatusModal from '../../components/checklist/Modais/ChecklistDisableStatusModal.vue';
import ChecklistEnabledStatusModal from '../../components/checklist/Modais/ChecklistEnableStatusModal.vue';
import ChecklistOwners from '@/src/types/checklist/checklistOwners';
import ChecklistTeams from '@/src/types/checklist/checklistTeams';
import Errors from '../../types/checklist/errors/errors';
import FeedbackInterface from '../../types/checklist/feedback';

@Component({
  components: {
    VueSkeletonLoader,
    ChecklistDisabledStatusModal: ChecklistDisabledStatusModal,
    ChecklistEnabledStatusModal: ChecklistEnabledStatusModal,
  },
})
export default class ViewChecklist extends Vue {
  @Prop(String) readonly id: string | undefined;

  @Getter('getUser', { namespace: 'auth' }) user!: User;

  @Getter('getFeedback', { namespace: 'checklist' })
  public feedback!: FeedbackInterface;
  @Action('setFeedback', { namespace: 'checklist' }) public setFeedback: any;

  @Getter('getDisableModal', { namespace: 'checklist' })
  disableStatusModal!: boolean;
  @Action('setDisableModal', { namespace: 'checklist' }) setDisableModal!: any;

  @Getter('getEnableModal', { namespace: 'checklist' })
  enableStatusModal!: boolean;
  @Action('setEnableModal', { namespace: 'checklist' }) setEnableModal!: any;

  checklist: ChecklistInterface = checklistInitValue;
  checklistTrigger: ChecklistTrigger[] = [];
  showSkeleton = true;
  owners: ChecklistOwners[] = [];
  teams: ChecklistTeams[] = [];
  loadingStatus = false;

  formatDate = FormatDate;

  private endpoint = new Endpoint();

  public async getChecklistById(): Promise<void> {
    const params = {
      ChecklistKey: this.id,
      LoggedUserEmail: this.user.email,
    };
    const items = await this.$http.get(this.endpoint.checklist.getById, {
      params,
    });
    this.checklist = items.data.data;
    this.checklistTrigger = items.data.data.checklistTriggers;
    this.showSkeleton = false;
  }

  async getTeams() {
    try {
      const response = await this.$http.get(this.endpoint.teams.list);
      const items = response.data.data;
      this.teams = items;
    } catch (error) {
      console.log('Erro em buscar o Teams');
    }
  }

  getNameTeams(teamKey: string) {
    const team = this.teams.find((x) => x.name === teamKey) as ChecklistTeams;
    return team?.name;
  }

  async getOwners() {
    try {
      const response = await this.$http.get(this.endpoint.owners.list);
      const items = response.data.data;
      this.owners = items;
    } catch (error) {
      console.log('Erro em buscar o Teams');
    }
  }

  getNameOwners(ownerKey: string) {
    const owner = this.owners.find(
      (x) => x.email === ownerKey
    ) as ChecklistOwners;
    return owner?.name;
  }

  async putRunCicle() {
    this.loadingStatus = true;
    const params = {
      checklistKey: this.id,
      userUpdateLogin: this.user.email,
    };
    try {
      const response = await this.$http.put(
        this.endpoint.runCicle.cicle,
        params
      );
      if (response.status === 200) {
        //this.getChecklistById();
        this.goToIndex('checklist activated.');
      }
    } catch (error) {
      const responseError = error as Errors;
      console.log('Error no put do status Enable');
      this.setFeedback({
        feedback: true,
        color: 'red',
        text: responseError.data.errors[0].message,
      });
      setTimeout((): void => {
        this.setFeedback({
          feedback: false,
        });
      }, 6000);
    } finally {
      this.loadingStatus = false;
      this.closeModalEnable();
    }
  }

  public handleSetTriggerEntitieName(typeTrigger: string) {
    let entitieName = '';
    this.checklistTrigger.map((entitie) => {
      if (entitie.typeTrigger === typeTrigger) {
        entitieName = entitieName + entitie.name + ', ';
      }
    });
    return entitieName === ''
      ? '----'
      : entitieName.substring(0, entitieName.length - 2);
  }

  setPredecessorName(id: string): string {
    const predecessor = this.checklist.checklistStages.find(
      (item) => item.checklistStageKey === id
    );

    return predecessor?.name === undefined ? '----' : predecessor?.name;
  }

  editChecklist(id: string) {
    this.$router.push({ path: `/checklist/edit/${id}` });
  }

  duplicateChecklist(id: string) {
    this.$router.push({ path: `/checklist/new/${id}` });
  }

  public async disableStatus() {
    this.loadingStatus = true;
    const params = {
      checklistKey: this.id,
      userUpdatedLogin: this.user.email,
    };
    try {
      const response = await this.$http.put(
        this.endpoint.disable.status,
        params
      );
      if (response.status === 200) {
        //this.getChecklistById();
        this.goToIndex('checklist deactivated.');
      }
    } catch (error) {
      const responseError = error as Errors;
      console.log('Error no put do status Disable');
      this.setFeedback({
        feedback: true,
        color: 'red',
        text: responseError.data.errors[0].message,
      });
      setTimeout((): void => {
        this.setFeedback({
          feedback: false,
        });
      }, 6000);
    } finally {
      this.loadingStatus = false;
      this.closeModalDisable();
    }
  }

  closeModalDisable() {
    this.setDisableModal(false);
  }

  public showDisableModal() {
    this.setDisableModal(true);
  }

  public async enableStatus() {
    this.loadingStatus = true;
    const params = {
      checklistKey: this.id,
      userUpdatedLogin: this.user.email,
    };
    try {
      const response = await this.$http.put(
        this.endpoint.enable.status,
        params
      );
      if (response.status === 200) {
        //this.getChecklistById();
        this.goToIndex('checklist activated.');
      }
    } catch (error) {
      const responseError = error as Errors;
      console.log('Error no put do status Enable');
      this.setFeedback({
        feedback: true,
        color: 'red',
        text: responseError.data.errors[0].message,
      });
      setTimeout((): void => {
        this.setFeedback({
          feedback: false,
        });
      }, 6000);
    } finally {
      this.loadingStatus = false;
      this.closeModalEnable();
    }
  }

  closeModalEnable() {
    this.setEnableModal(false);
  }

  public showEnableModal() {
    this.setEnableModal(true);
  }

  goToIndex(msg: string) {
    this.setFeedback({
      feedback: true,
      color: 'green',
      text: msg,
    });
    setTimeout((): void => {
      this.setFeedback({
        feedback: false,
      });
    }, 6000);
    this.$router.push({
      path: '/checklist',
    });
  }

  back() {
    this.$router.push({
      path: '/checklist',
    });
  }

  async mounted() {
    await this.getTeams();
    await this.getOwners();
    this.getChecklistById();
  }

  beforemount() {
    // localStorage.setItem('route', this.$router.currentRoute.path);
  }
}
