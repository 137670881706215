
  import { Component, Prop, Vue } from 'vue-property-decorator';
  import { Watch } from 'vue-property-decorator';
  import { Getter, Action } from 'vuex-class';
  import qs, { stringify } from 'qs';

  import Endpoint from '../../../utils/endpoint';
  import FilterEntity from '@/src/types/entities/filterEntity';
  import Identifier from '@/src/types/entities/identifier';
  import Select from '@/src/types/app/generic/select';
  import SelectBoolean from '@/src/types/app/generic/selectBoolean';

  @Component({})
  export default class EntityFilter extends Vue {
    endpoint = new Endpoint();
    @Getter('getFilterEntity', { namespace: 'entity' }) filter!: FilterEntity;

    @Prop({ type: Function })
    cleanFilter;

    gettingSelects = false;
    gettingEntityTypes = false;

    entitiesBusiness: Array<Select> = [];
    entitiesBusinessByEntity: Array<Select> = [];
    regulatoryLicenseTypes: Array<Select> = [];
    entitiesStatus: Array<Select> = [];
    offices: Array<Select> = [];
    entityTypes: Array<Select> = [];
    actives: Array<SelectBoolean> = [];
    incompletes: Array<SelectBoolean> = [
      { text: 'INCLUDE INCOMPLETES', value: true },
      { text: 'IGNORE INCOMPLETES', value: false },
      { text: 'ONLY INCOMPLETES', value: null }
    ];

    ignore: Array<string> = [
      'EACE427F-70B9-42E5-B519-05CFA7907F82',
      '7A5F8A1F-5F5E-4886-BE2F-43C94C25F8B7',
      '8F5F3ED3-1EDA-4F50-A65E-FE9845A5743A',
      '8F5F3ED3-1EDA-4F50-A65E-FE9845A5743A',
      '084902DF-8323-4FD6-8449-AF4C9408A806',
      '2308F854-F538-4D06-BFD2-7E7D6F83005C',
      'BBA7A9FD-7369-445B-BAD4-330F622E9987',
      'C97BB255-1BA6-4376-890A-95CDBF011DD5',
      'B09737C0-DE9B-4A01-8154-BA3FDD9AE5E2',
      'F4F3C3EE-BAE5-478B-93A9-2E0DD26E8D47',
      '7607D82C-3F32-43F2-9B80-4ED2EF87EDBC',
      'D7E17239-B272-4D88-B5D4-D005233F6A3D',
      '1375A25A-BA47-4BE6-B0B6-2C17887B619D'
    ];

    async created() {
      await this.initialize();
    }

    get showEntityType(): boolean {
      return this.$route.name === 'IndexOther' || this.$route.name === 'IndexExternalOther';
    }

    @Watch('$route')
    private async external() {
      await this.initialize();
    }

    @Watch('filter.search')
    private async search() {
      this.regulatoryLicenseTypes = [];
      this.regulatoryLicenseTypes = [];
      this.entitiesStatus = [];
      this.offices = [];
      this.entityTypes = [];

      await this.initialize();
    }

    async initialize() {
      await this.getSelects();
      await this.getBusinessUnitiesAsync();
    }

    async cleanAll(): Promise<void> {
      this.cleanFilter();
      await this.getSelects();
      await this.getBusinessUnitiesAsync();
    }

    async changeIncompletes(): Promise<void> {
      await this.getSelects();
      await this.getBusinessUnitiesAsync();
      // await this.getBySelect();
    }

    async getByBusinessUnitIds(): Promise<void> {
      const response = await this.$http.get(`${this.endpoint.select.getByEntity}/?${qs.stringify(this.filter)}`);

      this.regulatoryLicenseTypes = [];
      this.entitiesStatus = [];
      this.offices = [];
      this.entityTypes = [];

      this.regulatoryLicenseTypes = response.data.regulatoryLicenseTypes;
      this.entitiesStatus = response.data.status;
      this.offices = response.data.offices;
      this.entityTypes = response.data.entityTypes;
      this.setActiveValues(response.data.totalActive, response.data.totalInactive);
    }

    async getRegulatoryLicenseTypeIds(): Promise<void> {
      const response = await this.$http.get(`${this.endpoint.select.getByEntity}/?${qs.stringify(this.filter)}`);

      this.entitiesBusinessByEntity = [];
      this.entitiesStatus = [];
      this.offices = [];
      this.entityTypes = [];

      this.entitiesBusinessByEntity = response.data.businessUnitIds;
      this.entitiesStatus = response.data.status;
      this.offices = response.data.offices;
      this.entityTypes = response.data.entityTypes;
      this.setActiveValues(response.data.totalActive, response.data.totalInactive);
    }

    async getStatusIds(): Promise<void> {
      const response = await this.$http.get(`${this.endpoint.select.getByEntity}/?${qs.stringify(this.filter)}`);

      this.regulatoryLicenseTypes = [];
      this.offices = [];
      this.entitiesBusinessByEntity = [];
      this.entityTypes = [];

      this.regulatoryLicenseTypes = response.data.regulatoryLicenseTypes;
      this.offices = response.data.offices;
      this.entitiesBusinessByEntity = response.data.businessUnitIds;
      this.entityTypes = response.data.entityTypes;
      this.setActiveValues(response.data.totalActive, response.data.totalInactive);
    }

    async getSelects(): Promise<void> {
      const response = await this.$http.get(`${this.endpoint.select.getByEntity}/?${qs.stringify(this.filter)}`);

      this.regulatoryLicenseTypes = response.data.regulatoryLicenseTypes;
      this.entitiesStatus = response.data.status;
      this.offices = response.data.offices;
      this.entitiesBusinessByEntity = response.data.businessUnitIds;
      this.entityTypes = response.data.entityTypes;
      this.setActiveValues(response.data.totalActive, response.data.totalInactive);

      this.gettingSelects = false;
    }

    setActiveValues(totalActive: number, totalInactive: number): void {
      this.actives = [];
      if (totalActive > 0) {
        this.actives.push({
          text: `ACTIVE (${totalActive})`,
          value: true
        });
      }

      if (totalInactive > 0) {
        this.actives.push({
          text: `INACTIVE (${totalInactive})`,
          value: false
        });
      }
    }

    get entitiesBusinessFiltered(): Select[] {
      const arr: any[] = [];
      const retVal: Select[] = [];

      Object.keys(this.entitiesBusinessByEntity).map(x => {
        arr.push({
          value: x,
          total: this.entitiesBusinessByEntity[x]
        });
      });

      this.entitiesBusiness.forEach(element => {
        arr.some(x => {
          if (x.value === element.value) {
            retVal.push({
              value: element.value,
              text: `${element.text} (${x.total})`
            });
          }
        });
      });

      return retVal;
    }

    get entityTypesFiltered(): Select[] {
      const retVal: Select[] = [];

      this.entityTypes.forEach(element => {
        if (
          !this.ignore.some(x => {
            return x.toLowerCase() === element.value;
          })
        ) {
          retVal.push(element);
        }
      });

      return retVal;
    }

    async getBusinessUnitiesAsync(): Promise<void> {
      const response = await this.$http.get(`${this.endpoint.common.getByTable}/businessunities/id/txname`);

      this.entitiesBusiness = response.data;
    }

    async getEntityTypesAsync(): Promise<void> {
      const response = await this.$http.get(`${this.endpoint.general.getByTable}/entitytypes/id/txshortname`);

      response.data.forEach(entityType => {
        if (
          !this.ignore.some(x => {
            return x === entityType.text;
          })
        ) {
          this.entityTypes.push(entityType);
        }
      });

      this.filter.entityTypeIds = [];

      if (this.$route.name === 'IndexOther' || this.$route.name == 'IndexExternalOther') {
        this.gettingEntityTypes = false;
      }
    }
  }
