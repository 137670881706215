import { RouteConfig } from 'vue-router';
import Required from '../utils/required';

import IndexEmployee from '../views/Individual/Index.vue';
import CreateEmployee from '../views/Individual/Create.vue';
import DetailEmployee from '../views/Individual/Detail.vue';

import IndexDependent from '../views/Individual/Index.vue';
import CreateDependent from '../views/Individual/Create.vue';
import DetailDependent from '../views/Individual/Detail.vue';

import ClientIndex from '../views/Client/Index.vue';
import ClientForm from '../views/Client/Form.vue';

const required = new Required();

const individualRoutes: Array<RouteConfig> = [
  {
    path: '/employees',
    name: 'IndexEmployee',
    meta: {
      parent: 'Individuals',
      create: 'CreateEmployee',
      edit: 'DetailEmployee',
      title: 'Employees',
      type: 'employee',
      external: false
    },
    component: IndexEmployee,
    beforeEnter: required.requireAuth
  },
  {
    path: '/employee',
    name: 'CreateEmployee',
    meta: {
      parent: 'Individuals',
      before: 'IndexEmployee',
      index: 'employees',
      title: 'Create Employee',
      type: 'employee',
      external: false
    },
    component: CreateEmployee,
    beforeEnter: required.requireAuth
  },
  {
    path: '/employee/:id?',
    name: 'DetailEmployee',
    meta: {
      parent: 'Individuals',
      before: 'IndexEmployee',
      index: 'employees',
      title: 'Edit Employee',
      type: 'employee',
      external: false
    },
    component: DetailEmployee,
    beforeEnter: required.requireAuth
  },
  {
    path: '/dependents',
    name: 'IndexDependent',
    meta: {
      parent: 'Dependent',
      create: 'CreateDependent',
      edit: 'DetailDependent',
      title: 'Dependents',
      type: 'dependent',
      external: false
    },
    component: IndexDependent,
    beforeEnter: required.requireAuth
  },
  {
    path: '/dependent',
    name: 'CreateDependent',
    meta: {
      parent: 'Dependent',
      before: 'IndexDependent',
      index: 'dependents',
      title: 'Create Dependent',
      type: 'dependent',
      external: false
    },
    component: CreateDependent,
    beforeEnter: required.requireAuth
  },
  {
    path: '/dependent/:id?',
    name: 'DetailDependent',
    meta: {
      parent: 'Dependent',
      before: 'IndexDependent',
      index: 'dependents',
      title: 'Edit Dependent',
      type: 'dependent',
      external: false
    },
    component: DetailDependent,
    beforeEnter: required.requireAuth
  },

  {
    path: '/clients',
    name: 'IndexClient',
    meta: {
      parent: 'Individuals',
      create: 'CreateClient',
      edit: 'DetailClient',
      title: 'Clients',
      type: 'client',
      external: false
    },
    component: ClientIndex,
    beforeEnter: required.requireAuth
  },
  {
    path: '/client/:id?',
    name: 'ClientForm',
    meta: {
      parent: 'Individuals',
      before: 'IndexClient',
      index: 'clients',
      title: 'Client',
      type: 'client',
      external: false
    },
    component: ClientForm,
    beforeEnter: required.requireAuth
  }
];

export default individualRoutes;
