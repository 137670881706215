
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { Getter, Action } from 'vuex-class';

import User from '../../../types/auth/User';
import ChecklistTeams from '@/src/types/checklist/checklistTeams';
import ChecklistSubTask from '@/src/types/checklist/checklistSubTask';
import ChecklistSubTaskForm from '@/src/types/checklist/checklistSubTaskForm';
import ChecklistEntitySubTask from '@/src/types/checklist/checklistEntitySubTask';
import Endpoint from '../../../utils/endpoint';
import FeedbackInterface from '../../../types/checklist/feedback';
import ChecklistIgnoredEntity from '@/src/types/checklist/checklistIgnoredEntity';
import ChecklistStageEntities, {
  ResponseEntity,
} from '@/src/types/checklist/checklistStageEntities';

import CompaniesSubTaskModal from '../../../components/checklist/Modais/CompaniesSubsTaskModal.vue';

export type VForm = Vue & { validate: () => boolean } & {
  resetValidation: () => boolean;
};

@Component({
  components: { companiesSubTaskModal: CompaniesSubTaskModal },
})
export default class ModalSubTaskFormStatus extends Vue {
  @Getter('getSubTaskFormModal', { namespace: 'checklist' })
  modalSubTaskForm!: boolean;
  @Action('setSubTaskFormModal', { namespace: 'checklist' })
  setSubTaskFormModal!: any;

  @Getter('getModalTasks', { namespace: 'checklist' }) modal!: boolean;
  @Action('setModalTasks', { namespace: 'checklist' }) setModalTasks!: any;

  @Getter('getCompaniesSubTaskModal', { namespace: 'checklist' })
  companiesSubTaskModal!: boolean;
  @Action('setCompaniesSubTaskModal', { namespace: 'checklist' })
  setCompaniesSubTaskModal!: any;

  @Getter('getChecklistKeyId', { namespace: 'checklist' })
  checklistKeyId!: boolean;
  @Action('setChecklistKeyId', { namespace: 'checklist' })
  setChecklistKeyId!: any;

  @Getter('getSubTaskKey', { namespace: 'checklist' })
  subTaskKey!: string;
  @Action('setSubTaskKey', { namespace: 'checklist' })
  setSubTaskKey!: any;

  @Getter('getFeedback', { namespace: 'checklist' })
  public feedback!: FeedbackInterface;
  @Action('setFeedback', { namespace: 'checklist' }) public setFeedback: any;

  @Getter('getChecklistEntityStageKey', { namespace: 'checklist' })
  checklistEntityStageKey!: string;
  @Action('setChecklistEntityStageKey', { namespace: 'checklist' })
  setChecklistEntityStageKey!: any;

  @Getter('getIsEditSubTask', { namespace: 'checklist' })
  isEditSubTask!: boolean;
  @Action('setIsEditSubTask', { namespace: 'checklist' })
  setIsEditSubTask!: any;

  @Getter('getTaskKey', { namespace: 'checklist' })
  taskKey!: string;
  @Action('setTaskKey', { namespace: 'checklist' })
  setTaskKey!: any;

  @Getter('getUser', { namespace: 'auth' }) user!: User;

  @Prop(Boolean) readonly loading: boolean | undefined;
  @Prop(Boolean) readonly isEdit: boolean | undefined;

  @Prop(Array) teams: ChecklistTeams[] | undefined;
  @Prop(Object) checklistSubTask!: ChecklistSubTask;
  @Prop(Array) checklistSubTasks!: ChecklistSubTask[];

  @Prop(String) checklistKey: string | undefined;

  requiredRules: Array<any> = [
    (v: any): boolean | string => {
      if (v) {
        if (typeof v === 'string' && v.trim().length === 0)
          return 'Required field';
        return true;
      }
      if (v === false) return true;
      if (v === 0) return true;

      return 'Required Field';
    },
  ];

  @Watch('modalSubTaskForm')
  async handleGetCompanies() {
    if (this.modalSubTaskForm) {
      await this.getEntitySubTask();
    }
    if (this.isEditSubTask)
      this.setChecklistSubTaskItems(this.checklistSubTask);
  }

  private endpoint = new Endpoint();

  nameSubTaskField = '';
  commentSubTask = '';
  subKey = '';
  entitySubTask: ChecklistStageEntities[] = [];
  entitiesSelecteds: ChecklistStageEntities[] = [];
  companySelected: ChecklistStageEntities[] = [];
  ignoredEntitiesSubTask: ChecklistStageEntities[] = [];
  teamSelected: ChecklistTeams = { name: '' };
  loadingEdit = false;

  companiesSelectedEdit: ChecklistStageEntities[] = [];

  showAutoComplete = false;

  companiesSlectedsLength = '0 Companies';

  get form(): VForm {
    return this.$refs.form as VForm;
  }

  @Watch('loading')
  closeModalWhenSaveOrEdit() {
    if (this.loading === false) {
      this.closeModal();
      this.$emit('reloadSubtaskData');
    }
  }

  @Watch('subTaskKey')
  public async handleChecklistKey() {
    // await this.getEntitySubTask();
    if (this.isEditSubTask) {
      this.loadingEdit = true;
      this.setChecklistSubTaskItems(this.checklistSubTask);
    }
  }

  public returnSubTaskPosition() {
    const subTaskIsEditing = this.checklistSubTasks.find(
      (item) => item.subtaskKey === this.subKey
    ) as ChecklistSubTask;
    return this.checklistSubTasks.indexOf(subTaskIsEditing) + 1;
  }

  public verifyIfSubTaskNameAlreadyExist() {
    return (
      this.checklistSubTasks.find(
        (item) => item.name === this.nameSubTaskField
      ) === undefined
    );
  }

  get handleSetSubTaskTitle() {
    if (this.isEditSubTask) {
      const subTaskPosition = this.returnSubTaskPosition();
      return `Task ${subTaskPosition}`;
    }
    return `Task ${this.checklistSubTasks.length + 1}`;
  }

  closeModal() {
    this.setSubTaskFormModal(false);
    this.setIsEditSubTask(false);
    this.setModalTasks(true);
    this.clearSubTaskFields();
  }

  setCompaniesValue(companies: ChecklistStageEntities[]) {
    const companiesLength = companies.length;
    this.companiesSelectedEdit = companies;
    return companiesLength === 1
      ? `${companiesLength} company`
      : `${companiesLength} companies`;
  }

  public getCompaniesSelecteds(companies: ChecklistStageEntities[]) {
    this.companySelected = companies;
  }

  public handleSaveSubTask() {
    if (!this.form.validate()) return;
    if (!this.verifyIfSubTaskNameAlreadyExist()) {
      this.setFeedback({
        feedback: true,
        color: 'red',
        text: 'There is already a subtask with that name.',
      });
      setTimeout((): void => {
        this.setFeedback({
          feedback: false,
        });
      }, 6000);
      return;
    }

    const params: ChecklistSubTaskForm = {
      name: this.nameSubTaskField,
      checklistStageKey: this.taskKey,
      checklistEntityStageKey: this.checklistEntityStageKey,
      comment: this.commentSubTask,
      teamKey: this.teamSelected.name,
      userUpdatedLogin: this.user.email,
      entities: this.companySelected,
    };
    this.$emit('saveSubTask', params);
  }

  handleSetTaskPosition() {
    this.$emit('handleSetTaskPosition');
  }

  public saveEditSubTask() {
    const params = {
      name: this.nameSubTaskField,
      checklistStageKey: this.checklistSubTask.checklistStageKey,
      checklistEntityStageKey: this.checklistSubTask.checklistEntityStageKey,
      subtaskKey: this.checklistSubTask.subtaskKey,
      comment: this.commentSubTask,
      teamKey: this.teamSelected.name,
      userUpdatedLogin: this.user.email,
      entities: this.companySelected,
    };
    this.$emit('saveEditSubTask', params);
  }

  public async getSubTaskView(item: string, stageKey: string) {
    const response = await this.$http.get(
      this.endpoint.subTask.create + `/${item}`
    );
    const subtask = response.data.data;
    if (subtask === undefined) return;
    const subtasks = subtask.find(
      (checklist) => checklist.comment === this.commentSubTask
    );
    if (!subtasks) return;
    return subtasks.subtaskKey;
  }

  public setSaveOrEditSubTask() {
    if (this.isEditSubTask) {
      this.saveEditSubTask();
    } else {
      this.handleSaveSubTask();
    }
  }

  setChecklistSubTaskItems(item: ChecklistSubTask) {
    this.nameSubTaskField = item.name;
    this.teamSelected = this.teams?.find(
      (x) => x.name === item.teamKey
    ) as ChecklistTeams;
    this.companySelected = item.entities;
    this.commentSubTask = item.comment;
    this.subKey = item.subtaskKey;
    this.loadingEdit = false;
  }

  public async getEntitySubTask() {
    try {
      const {
        data: { entities },
      } = await this.$http.get<ResponseEntity>(
        this.endpoint.subTask.entities + `/${this.checklistKey}`
      );
      this.entitySubTask = entities;
      this.setValueForModalEntities(entities);
    } catch (error) {
      console.log('Error de buscar o get entities da subtask');
    }
  }

  public clearSubTaskFields() {
    this.nameSubTaskField = '';
    this.teamSelected = { name: '' };
    this.companySelected = [];
    this.commentSubTask = '';

    this.form.resetValidation();
  }

  public setValueForModalEntities(entities: ChecklistStageEntities[]) {
    this.entitySubTask = entities;
    this.companySelected = entities;
  }

  setCompanySelecteds(selectedItem: ChecklistStageEntities[]) {
    return selectedItem.length === 1
      ? `${selectedItem.length} company`
      : `${selectedItem.length} companies`;
  }

  openModalEntities() {
    this.setCompaniesSubTaskModal(true);
  }
}
