
  import { Component, Vue } from 'vue-property-decorator';
  import { Getter, Action } from 'vuex-class';

  import Endpoint from '../../utils/endpoint';
  import Select from '@/src/types/app/generic/select';
  import Employee from '@/src/types/individual/employee';

  @Component({})
  export default class GenderComponent extends Vue {
    endpoint = new Endpoint();

    @Getter('getEmployee', { namespace: 'individual' }) employee!: Employee;
    @Getter('getOriginalEmployee', { namespace: 'individual' })
    originalEmployee!: Employee;

    maritalStatus: Array<Select> = [];

    async created() {
      await this.getMaritalStatusAsync();
    }

    async getMaritalStatusAsync(): Promise<void> {
      const response = await this.$http.get(`${this.endpoint.individual.getByTable}/maritalstatus/id/txname`);

      this.maritalStatus = response.data;
    }

    cleanSelect(): void {
      this.employee.maritalStatusId = null;
    }
  }
