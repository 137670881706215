
  import { Component, Watch, Prop, Vue } from 'vue-property-decorator';
  import { Getter, Action } from 'vuex-class';

  import Endpoint from '../../utils/endpoint';

  import Individual from '@/src/types/individual/individual';
  import Select from '@/src/types/app/generic/select';

  @Component({})
  export default class AccontComponent extends Vue {
    endpoint = new Endpoint();

    @Getter('getIndividual', { namespace: 'individual' }) individual!: Individual;
    @Getter('getOriginalIndividual', { namespace: 'individual' })
    originalIndividual!: Individual;

    banks: Array<Select> = require('../../utils/banks.json');

    get isChanged(): boolean {
      const changed = this.$toHash(JSON.stringify(this.individual.accounts));
      const original = this.$toHash(JSON.stringify(this.originalIndividual.accounts));

      return changed !== original;
    }

    addAccount(): void {
      if (this.individual.accounts === null) this.individual.accounts = [];

      this.individual.accounts.push({
        bankId: null,
        agency: null,
        account: null,
        accountDV: null,
        primary: this.individual.accounts.length === 0
      });
    }

    removeAccount(index: number): void {
      this.individual.accounts.splice(index, 1);
      if (this.individual.accounts.length > 0) {
        this.individual.accounts[0].primary = true;
      }
    }

    setAccountPrimary(index: number) {
      for (let i = 0; i < this.individual.accounts.length; i++) {
        this.individual.accounts[i].primary = index === i;
      }
    }

    cleanSelect(): void {
      console.log('corrigir isto');

      //this.individual.document.documentTypeId = null;
    }
  }
