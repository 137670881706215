
  import { Component, Watch, Prop, Vue, Ref } from 'vue-property-decorator';
  import { Getter, Action } from 'vuex-class';

  @Component({})
  export default class RelationValueComponent extends Vue {
    @Prop({ type: Object })
    entityRelation;

    @Prop({ type: Array })
    requiredRules;

    isValueActive = false;

    get value(): string | null {
      if (this.isValueActive || !this.entityRelation.value) {
        return `${this.entityRelation.value}`;
      } else {
        return this.$formatCurrency(this.entityRelation.value, true);
      }
    }
    set value(value: string | null) {
      if (!value) value = '0';

      let newValue = parseFloat(value.replace(/[^\d\\.]/g, ''));
      if (isNaN(newValue)) {
        newValue = 0;
      }
      this.entityRelation.value = newValue;
    }

    get valueFormated(): string {
      return this.$formatCurrency(this.entityRelation.value, this.isValueActive);
    }
  }
