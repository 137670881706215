import { AppState } from './types';

import Checklist from '@/src/types/checklist/checklist';
import ChecklistStage from '@/src/types/checklist/checklistStage';
import ChecklistStageTask from '@/src/types/checklist/checklistStageTask';
import ChecklistTrigger from '@/src/types/checklist/checklistTrigger';
import Feedback from '../../types/checklist/feedback';

export default {
  setChecklistStageTask(
    state: AppState,
    checklistStageTask: ChecklistStageTask
  ): void {
    state.checklistStageTask = checklistStageTask;
  },
  setChecklistStage(state: AppState, checklistStage: ChecklistStage): void {
    state.checklistStage = checklistStage;
  },
  setChecklistTrigger(
    state: AppState,
    checklistTrigger: ChecklistTrigger
  ): void {
    state.checklistTrigger = checklistTrigger;
  },
  setChecklist(state: AppState, checklist: Checklist): void {
    state.checklist = checklist;
  },

  setModalTasks(state: AppState, show: boolean): void {
    state.checklist.modal = show;
  },
  setSaveModal(state: AppState, show: boolean): void {
    state.checklistSaveModal = show;
  },
  setSaveDraftModal(state: AppState, show: boolean): void {
    state.checklistSaveDraftModal = show;
  },
  setEntitiesModal(state: AppState, show: boolean): void {
    state.checklistEntitiesModal = show;
  },
  setCompaniesSubTaskModal(state: AppState, show: boolean): void {
    state.companiesSubTaskModal = show;
  },
  setFeedback(state: AppState, feedback: Feedback) {
    state.feedback = feedback;
  },
  setChecklistData(state: AppState, checklistData: Checklist) {
    state.checklistData = checklistData;
  },
  setChecklistEntityStageKey(state: AppState, checklistEntityStageKey: string) {
    state.checklistEntityStageKey = checklistEntityStageKey;
  },
  setChecklistNameTask(state: AppState, checklistNameTask: string) {
    state.checklistNameTask = checklistNameTask;
  },
  setFinishModal(state: AppState, show: boolean): void {
    state.checklistFinishModal = show;
  },
  setDisableModal(state: AppState, show: boolean): void {
    state.checklistDisableModal = show;
  },
  setEnableModal(state: AppState, show: boolean): void {
    state.checklistEnableModal = show;
  },
  setChecklistKeyId(state: AppState, show: string): void {
    state.checklistKeyId = show;
  },
  setNoteFieldFull(state: AppState, show: boolean): void {
    state.noteFieldFull = show;
  },
  setNote(state: AppState, show: string): void {
    state.note = show;
  },
  setLinkFieldFull(state: AppState, show: boolean): void {
    state.linkFieldFull = show;
  },
  setUserCanInteract(state: AppState, show: boolean): void {
    state.userCanInteract = show;
  },
  setSubTaskFormModal(state: AppState, show: boolean): void {
    state.checklistSubTaskFormModal = show;
  },
  setTaskKey(state: AppState, show: string): void {
    state.taskKey = show;
  },
  setDeleteSubTaskModal(state: AppState, show: boolean): void {
    state.checklistDeleteSubTask = show;
  },
  setModalSubTask(state: AppState, show: boolean): void {
    state.subTaskModal = show;
  },
  setChecklistSubTaskKey(state: AppState, show: string): void {
    state.checklistSubTaskKey = show;
  },
  setSubTaskName(state: AppState, show: string): void {
    state.checklistSubTaskName = show;
  },
  setSubTaskKey(state: AppState, show: string): void {
    state.subTaskKey = show;
  },
  setSubTaskOrTask(state: AppState, show: string): void {
    state.subTaskOrTask = show;
  },
  setIsEditSubTask(state: AppState, show: boolean): void {
    state.isEditSubTask = show;
  },
  setTriggersSelectedHasCompany(state: AppState, hasCompanies: boolean): void {
    state.triggersSelectedHasCompany = hasCompanies;
  },

  setEntityStageKeyForSubTaskReload(
    state: AppState,
    entityStageKeyForSubTaskReload: string
  ) {
    state.EntityStageKeyForSubTaskReload = entityStageKeyForSubTaskReload;
  },
  setStageKeyForSubTaskReload(
    state: AppState,
    StageKeyForSubTaskReload: string
  ) {
    state.EntityStageKeyForSubTaskReload = StageKeyForSubTaskReload;
  },
};
