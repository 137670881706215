
import { Component, Vue } from 'vue-property-decorator';
import { Watch } from 'vue-property-decorator';
import { Getter } from 'vuex-class';

import Endpoint from '../../utils/endpoint';
import Header from '@/src/types/app/headers/header';
import IndividualHeader from '../../types/app/headers/IndividualHeader';

import { debounce } from 'lodash';
import qs from 'qs';
import XLSX from 'xlsx';
import Select from '@/src/types/app/generic/select';
import SelectBoolean from '@/src/types/app/generic/selectBoolean';

@Component({})
export default class IndexIndividual extends Vue {
  endpoint = new Endpoint();
  headers: Array<Header> = IndividualHeader.header;

  @Getter('canCreate', { namespace: 'auth' }) canCreate!: boolean;
  @Getter('canRead', { namespace: 'auth' }) canRead!: boolean;

  businessUnitiesIds: Array<any> = [];
  businessUnities: Array<Select> = [];
  actives: Array<SelectBoolean> = [];

  departments: Array<Select> = [];
  functions: Array<Select> = [];

  // title = this.$route.meta.title;

  gettingBusinessUnityIds = true;
  gettingDepartments = true;
  gettingFunctions = true;
  generatingXlsx = false;

  debounceSearch: any = null;
  loading = true;
  loadingMore = false;
  hasMore = true;
  total = 0;
  data: Array<any> = [];
  filter = {
    skip: 0,
    take: 10,
    search: '',
    actives: [true],
    businessUnityIds: [],
    departmentIds: [],
    functionIds: [],
  };

  async mounted() {
    this.debounceSearch = debounce(this.getIndividualsAsync, 1000);

    this.loadFilter();
    await this.initialize();
    console.log('mounted');
  }

  @Watch('$route')
  private async route() {
    this.loadFilter();
    await this.initialize();
    console.log('Watch');
  }

  @Watch('filter.search')
  private async search() {
    if (
      this.filter.search &&
      (this.filter.search.length >= 3 ||
        this.filter.search.length === 3 ||
        this.filter.search.length === 0)
    ) {
      this.loading = true;
      this.filter.skip = 0;
      this.data = [];
      this.debounceSearch();
    }
  }

  get title(): string {
    return this.$route.meta?.title;
  }

  async saveFilter(): Promise<void> {
    localStorage.setItem(`${this.$route.name}`, JSON.stringify(this.filter));
    await this.initialize();
  }

  async cleanAll(): Promise<void> {
    localStorage.removeItem(`${this.$route.name}`);

    this.filter = {
      skip: 0,
      take: 10,
      search: '',
      actives: [true],
      businessUnityIds: [],
      departmentIds: [],
      functionIds: [],
    };

    await this.initialize();
  }

  loadFilter(): void {
    const ftr = localStorage.getItem(`${this.$route.name}`);
    if (ftr) {
      this.filter = JSON.parse(ftr);
    } else {
      this.filter = {
        skip: 0,
        take: 10,
        search: '',
        actives: [true],
        businessUnityIds: [],
        departmentIds: [],
        functionIds: [],
      };
    }
  }

  async initialize(): Promise<void> {
    this.filter.skip = 0;
    this.data = [];

    this.gettingBusinessUnityIds = true;
    this.gettingDepartments = true;
    this.gettingFunctions = true;

    await this.getIndividualsAsync();
    await this.getBusinessUnityIdsAsync();
    await this.getBusinessUnitiesAsync();
    await this.getDepartmentsAsync();
    await this.getFunctionsAsync();
  }

  get last(): number {
    let retVal = 0;
    const last = this.total - this.data.length;

    if (last < 10) {
      retVal = last;
    }

    if (last >= 10) {
      retVal = 10;
    }

    return retVal;
  }

  async getMore(): Promise<void> {
    this.filter.skip++;
    await this.getIndividualsAsync();
  }

  async getIndividualsAsync(): Promise<void> {
    // console.log('filter', this.filter);

    const response = await this.$http.get(
      `${this.endpoint.individual.getByFilter}/?${qs.stringify(this.filter)}`
    );

    response.data.data.data.forEach(individual => {
      this.data.push(individual);
    });

    this.total = response.data.data.total;
    this.hasMore = response.data.data.data.length == 10;
    this.loading = false;
  }

  get individualBusinessFiltered(): Select[] {
    const arr: any[] = [];
    const retVal: Select[] = [];

    this.businessUnities.forEach(businessUnity => {
      this.businessUnitiesIds.some(x => {
        if (x.value === businessUnity.value) {
          retVal.push({
            value: businessUnity.value,
            text: `${businessUnity.text} (${x.quantity})`,
          });
        }
      });
    });

    return retVal;
  }

  async getBusinessUnityIdsAsync(): Promise<void> {
    const response = await this.$http.get(
      `${this.endpoint.individual.getBusinessUnityIds}/?${qs.stringify(
        this.filter
      )}`
    );

    if (response.data.data) this.businessUnitiesIds = response.data.data;
    this.gettingBusinessUnityIds = false;
  }

  async getDepartmentsAsync(): Promise<void> {
    const response = await this.$http.get(
      `${this.endpoint.individual.getDepartments}/?${qs.stringify(this.filter)}`
    );

    if (response.data.data) this.departments = response.data.data;
    this.gettingDepartments = false;
  }

  async getFunctionsAsync(): Promise<void> {
    const response = await this.$http.get(
      `${this.endpoint.individual.getFunctions}/?${qs.stringify(this.filter)}`
    );

    if (response.data.data) this.functions = response.data.data;
    this.gettingFunctions = false;
  }

  async getBusinessUnitiesAsync(): Promise<void> {
    const response = await this.$http.get(
      `${this.endpoint.common.getByTable}/businessunities/id/txshortname`
    );

    this.businessUnities = response.data;
  }

  goToAdd(): void {
    this.$router.push({ name: this.$route.meta?.create });
  }

  goToDetail(item: any): void {
    this.$router.push({
      name: this.$route.meta?.edit,
      params: { id: item.id },
    });
  }

  async downloadData(filtered: boolean): Promise<void> {
    this.generatingXlsx = true;

    const newFilter = Object.assign({}, this.filter);

    newFilter.skip = 0;

    if (filtered) {
      newFilter.take = this.data.length;
    } else {
      newFilter.take = 1000;
    }

    const query = this.$to_qs(newFilter);
    const response = await this.$http.get(
      `${this.endpoint.individual.getByFilter}/?${query}`
    );

    const values = response.data.data.data.map(x => {
      return Object.values(x).slice(1);
    });

    const headers = response.data.data.headers.slice(1);

    values.unshift(headers);

    const wscols: Array<any> = [];

    for (let i = 0; i < headers.length; i++) {
      wscols.push({ wch: headers[i].length + 30 });
    }

    const data = XLSX.utils.json_to_sheet(values, { skipHeader: true });

    data['!cols'] = wscols;

    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, data, 'Individuals');
    XLSX.writeFile(wb, 'individuals.xlsx');

    this.generatingXlsx = false;
  }
}
