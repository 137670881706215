
  import { Component, Ref, Vue } from 'vue-property-decorator';
  import { Getter } from 'vuex-class';

  import Endpoint from '../../utils/endpoint';

  import Entity from '@/src/types/entities/entity';
  import Select from '../../types/app/generic/select';

  @Component({})
  export default class PhoneComponent extends Vue {
    endpoint = new Endpoint();

    @Getter('getOriginalEntity', { namespace: 'entity' }) originalEntity!: Entity;
    @Getter('getEntity', { namespace: 'entity' })
    entity!: Entity;

    typePhones: Select[] = [];

    async created() {
      await this.getPhonesAsync();
    }

    async getPhonesAsync(): Promise<void> {
      const response = await this.$http.get(`${this.endpoint.common.getByTable}/phonetypes/id/txname`);

      if (response.status === 200) {
        this.typePhones = response.data;
      }
    }

    verifyEdition(index: number): void {
      if (
        this.entity.phones !== null &&
        this.originalEntity.phones !== null &&
        this.entity.phones.length === this.originalEntity.phones.length
      ) {
        this.entity.phones[index].typePhoneId !== this.originalEntity.phones[index].typePhoneId;
      }
    }

    addPhone(): void {
      if (this.entity.phones === null) this.entity.phones = [];

      this.entity.phones.push({
        isEdited: false,
        typePhoneId: '',
        number: '',
        extension: '',
        primary: this.entity.phones.length === 0
      });
    }

    removePhone(index: number): void {
      this.entity.phones.splice(index, 1);
    }

    setPhonePrimary(index: number) {
      for (let i = 0; i < this.entity.phones.length; i++) {
        this.entity.phones[i].primary = index === i;
      }
    }
  }
