import { AppState } from './types';

import ConfigForm from '@/src/types/entities/configForm';
import FilterEntity from '@/src/types/entities/filterEntity';
import Entity from '@/src/types/entities/entity';
import LesteEntity from '@/src/types/entities/lesteEntity';
import EntityData from '@/src/types/entities/entityData';
import Office from '@/src/types/entities/office';

export default {
  setOffice(state: AppState, office: Office): void {
    state.office = office;
  },
  setEntityType(state: AppState, entityType: string): void {
    state.entity.entityType = entityType;
  },
  setOriginalEntityType(state: AppState, entityType: string): void {
    state.originalEntity.entityType = entityType;
  },
  setEntityData(state: AppState, entityData: EntityData): void {
    state.entityData = entityData;
  },
  setFilterEntityType(state: AppState, entityType: string): void {
    state.filterEntity.entityType = entityType;
  },
  setConfigForm(state: AppState, configForm: ConfigForm): void {
    state.configForm = configForm;
  },
  setFilter(state: AppState, filter: FilterEntity) {
    state.filterEntity = filter;
  },
  setFilterSkip(state: AppState, skip: number): void {
    state.filterEntity.skip = skip;
  },
  setEntity(state: AppState, entity: Entity): void {
    state.entity = entity;
  },
  setLesteEntity(state: AppState, lesteEntity: LesteEntity): void {
    state.lesteEntity = lesteEntity;
  },
  setOriginalEntity(state: AppState, entity: Entity): void {
    state.originalEntity = entity;
  },
  setOriginalLesteEntity(state: AppState, originalLesteEntity: LesteEntity): void {
    state.originalLesteEntity = originalLesteEntity;
  },

  setEntityEmpty(state: AppState, entity: Entity): void {
    state.entity = entity;
  },
  setLesteEntityEmpty(state: AppState, lesteEntity: LesteEntity): void {
    state.lesteEntity = lesteEntity;
  },

  setOriginalEntityEmpty(state: AppState, entity: Entity): void {
    state.originalEntity = entity;
  },
  setOriginalLesteEntityEmpty(state: AppState, lesteEntity: LesteEntity): void {
    state.originalLesteEntity = lesteEntity;
  }
};
