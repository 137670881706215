
  import { Component, Watch, Prop, Vue, Ref } from 'vue-property-decorator';
  import { Getter, Action } from 'vuex-class';

  import Individual from '@/src/types/individual/individual';
  import SelectNumber from '@/src/types/app/generic/selectNumber';

  @Component({})
  export default class DateComponent extends Vue {
    menu = false;

    @Prop({ type: Array })
    dateRules;

    @Prop({ type: Object })
    dependent;

    @Watch('date')
    private watchDate(val) {
      console.log('data', val);
    }

    get bornDate(): string | null {
      return this.dependent.bornDate
        ? this.formatDate(new Date(this.dependent.bornDate).toISOString().substr(0, 10))
        : null;
    }
    set bornDate(value: string | null) {
      this.dependent.bornDate = this.parseDate(value);
    }

    @Watch('menu')
    private async mnu(val) {
      val && setTimeout(() => ((this.$refs['picker'] as any).activePicker = 'YEAR'));
    }

    formatDate(date): string | null {
      if (!date) return null;

      const [year, month, day] = date.split('-');
      return `${month}/${day}/${year}`;
    }

    parseDate(date): string | null {
      if (!date) return null;

      const [month, day, year] = date.split('/');
      return `${month.padStart(2, '0')}-${day.padStart(2, '0')}-${year}`;
    }
  }
