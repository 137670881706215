import Client from '@/src/types/client/client';
import Employee from '@/src/types/individual/employee';
import Individual from '@/src/types/individual/individual';
import { AppState } from './types';

export default {
  setIndividual(state: AppState, individual: Individual): void {
    state.individual = individual;
  },
  setOriginalIndividual(state: AppState, originalIndividual: Individual): void {
    state.originalIndividual = originalIndividual;
  },
  setEmployee(state: AppState, employee: Employee): void {
    state.employee = employee;
  },
  setOriginalEmployee(state: AppState, originalEmployee: Employee): void {
    state.originalEmployee = originalEmployee;
  },

  setEmployeeEmpty(state: AppState, employee: Employee): void {
    state.employee = employee;
  },
  setIndividualEmpty(state: AppState, individual: Individual): void {
    state.individual = individual;
  },

  setOriginalEmployeeEmpty(state: AppState, employee: Employee): void {
    state.originalEmployee = employee;
  },
  setOriginalIndividualEmpty(state: AppState, individual: Individual): void {
    state.originalIndividual = individual;
  },
  setIndividualType(state: AppState, type: string): void {
    state.type = type;
  },

  setClientEmpty(state: AppState, client: Client): void {
    state.client = client;
  },
  setOriginalClientEmpty(state: AppState, client: Client): void {
    state.originalClient = client;
  },
  setClient(state: AppState, client: Client): void {
    state.client = client;
  },
  setOriginalClient(state: AppState, originalClient: Client): void {
    state.originalClient = originalClient;
  }
};
