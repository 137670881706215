
import { Component, Ref, Vue } from 'vue-property-decorator';

import DataTable from '../../../components/entities/DataTable.vue';
import Endpoint from '../../../utils/endpoint';

@Component({
  components: {
    DataTable: DataTable,
  },
})
export default class IndexCommon extends Vue {
  endpoint = new Endpoint();

  get title(): string {
    return this.$route.meta?.title;
  }

  goToAdd(): void {
    this.$router.push({ name: this.$route.meta?.create });
  }

  goToDetail(item: any): void {
    this.$router.push({
      name: this.$route.meta?.edit,
      params: { id: item.id },
    });
  }
}
