import { RouteConfig } from 'vue-router';
import Required from '../utils/required';

import Jurisdictions from '../views/Jurisdiction/Index.vue';
import Jurisdiction from '../views/Jurisdiction/Form.vue';
import LicenseTypes from '../views/LicenseType/Index.vue';
import LicenseType from '../views/LicenseType/Form.vue';
import EntityTypes from '../views/EntityType/Index.vue';
import EntityType from '../views/EntityType/Form.vue';
import FormOrganizations from '../views/FormOrganization/Index.vue';
import FormOrganization from '../views/FormOrganization/Form.vue';
import TaxElections from '../views/TaxElection/Index.vue';
import TaxElection from '../views/TaxElection/Form.vue';
import TaxTypes from '../views/TaxType/Index.vue';
import TaxType from '../views/TaxType/Form.vue';
import RegulatoryBodies from '../views/RegulatoryBody/Index.vue';
import RegulatoryBody from '../views/RegulatoryBody/Form.vue';
import EntitiesStatus from '../views/EntityStatus/Index.vue';
import EntityStatus from '../views/EntityStatus/Form.vue';
import EntityRelationClassifications from '../views/EntityRelationClassification/Index.vue';
import EntityRelationClassification from '../views/EntityRelationClassification/Form.vue';
import BusinessUnits from '../views/BusinessUnit/Index.vue';
import BusinessUnit from '../views/BusinessUnit/Form.vue';
import Currencys from '../views/Currency/Index.vue';
import Currency from '../views/Currency/Form.vue';

const required = new Required();

const generalRoutes: Array<RouteConfig> = [
  {
    path: '/currencys',
    name: 'IndexCurrency',
    meta: {
      parent: 'General',
      create: 'Currency',
      edit: 'Currency',
      title: 'Currencys',
      type: '',
      external: false
    },
    component: Currencys,
    beforeEnter: required.requireAuth
  },
  {
    path: '/currency/:id?',
    name: 'Currency',
    meta: {
      parent: 'General',
      before: 'IndexCurrency',
      index: 'currencys',
      title: 'Currency',
      type: '',
      external: false
    },
    component: Currency,
    beforeEnter: required.requireAuth
  },
  {
    path: '/businessUnits',
    name: 'IndexBusinessUnit',
    meta: {
      parent: 'General',
      create: 'BusinessUnit',
      edit: 'BusinessUnit',
      title: 'Business Units',
      type: '',
      external: false
    },
    component: BusinessUnits,
    beforeEnter: required.requireAuth
  },
  {
    path: '/businessUnit/:id?',
    name: 'BusinessUnit',
    meta: {
      parent: 'General',
      before: 'IndexBusinessUnit',
      index: 'businessUnits',
      title: 'Business Unit',
      type: '',
      external: false
    },
    component: BusinessUnit,
    beforeEnter: required.requireAuth
  },
  {
    path: '/jurisdictions',
    name: 'IndexJurisdiction',
    meta: {
      parent: 'General',
      create: 'CreateJurisdiction',
      edit: 'DetailJurisdiction',
      title: 'Jurisdictions',
      type: '',
      external: false
    },
    component: Jurisdictions,
    beforeEnter: required.requireAuth
  },
  {
    path: '/jurisdiction/:id?',
    name: 'Jurisdiction',
    meta: {
      parent: 'General',
      before: 'IndexJurisdiction',
      index: 'jurisdictions',
      title: 'Jurisdiction',
      type: '',
      external: false
    },
    component: Jurisdiction,
    beforeEnter: required.requireAuth
  },
  {
    path: '/licensetypes',
    name: 'IndexLicenseType',
    meta: {
      parent: 'General',
      create: 'CreateLicenseType',
      edit: 'DetailLicenseType',
      title: 'License Types',
      type: '',
      external: false
    },
    component: LicenseTypes,
    beforeEnter: required.requireAuth
  },
  {
    path: '/licensetype/:id?',
    name: 'LicenseType',
    meta: {
      parent: 'General',
      before: 'IndexLicenseType',
      index: 'licenseTypes',
      title: 'License Type',
      type: '',
      external: false
    },
    component: LicenseType,
    beforeEnter: required.requireAuth
  },
  {
    path: '/entitytypes',
    name: 'IndexEntityType',
    meta: {
      parent: 'General',
      create: 'CreateEntityType',
      edit: 'DetailEntityType',
      title: 'Entity Types',
      type: '',
      external: false
    },
    component: EntityTypes,
    beforeEnter: required.requireAuth
  },
  {
    path: '/entitytype/:id?',
    name: 'EntityType',
    meta: {
      parent: 'General',
      before: 'IndexEntityType',
      index: 'entitytypes',
      title: 'Entity Type',
      type: '',
      external: false
    },
    component: EntityType,
    beforeEnter: required.requireAuth
  },
  {
    path: '/formorganizations',
    name: 'IndexFormOrganization',
    meta: {
      parent: 'General',
      create: 'CreateFormOrganization',
      edit: 'DetailFormOrganization',
      title: 'Form Organizations',
      type: '',
      external: false
    },
    component: FormOrganizations,
    beforeEnter: required.requireAuth
  },
  {
    path: '/formorganization/:id?',
    name: 'FormOrganization',
    meta: {
      parent: 'General',
      before: 'IndexFormOrganization',
      index: 'formorganizations',
      title: 'Form Organizations',
      type: '',
      external: false
    },
    component: FormOrganization,
    beforeEnter: required.requireAuth
  },
  {
    path: '/taxelections',
    name: 'IndexTaxElection',
    meta: {
      parent: 'General',
      create: 'CreateTaxElection',
      edit: 'DetailTaxElection',
      title: 'Tax Elections',
      type: '',
      external: false
    },
    component: TaxElections,
    beforeEnter: required.requireAuth
  },
  {
    path: '/taxelection/:id?',
    name: 'TaxElection',
    meta: {
      parent: 'General',
      before: 'IndexTaxElection',
      index: 'taxelections',
      title: 'Tax Elections',
      type: '',
      external: false
    },
    component: TaxElection,
    beforeEnter: required.requireAuth
  },
  {
    path: '/taxtypes',
    name: 'IndexTaxType',
    meta: {
      parent: 'General',
      create: 'CreateTaxType',
      edit: 'DetailTaxType',
      title: 'Tax Type',
      type: '',
      external: false
    },
    component: TaxTypes,
    beforeEnter: required.requireAuth
  },
  {
    path: '/taxtype/:id?',
    name: 'TaxType',
    meta: {
      parent: 'General',
      before: 'IndexTaxType',
      index: 'taxtypes',
      title: 'Tax Types',
      type: '',
      external: false
    },
    component: TaxType,
    beforeEnter: required.requireAuth
  },
  {
    path: '/regulatorybodies',
    name: 'IndexRegulatoryBody',
    meta: {
      parent: 'General',
      create: 'CreateRegulatoryBody',
      edit: 'DetailRegulatoryBody',
      title: 'Regulatory Bodies',
      type: '',
      external: false
    },
    component: RegulatoryBodies,
    beforeEnter: required.requireAuth
  },
  {
    path: '/regulatorybody/:id?',
    name: 'RegulatoryBody',
    meta: {
      parent: 'General',
      before: 'IndexRegulatoryBody',
      index: 'regulatorybodies',
      title: 'Regulatory Body',
      type: '',
      external: false
    },
    component: RegulatoryBody,
    beforeEnter: required.requireAuth
  },
  {
    path: '/entitiesstatus',
    name: 'IndexEntityStatus',
    meta: {
      parent: 'General',
      create: 'CreateEntityStatus',
      edit: 'DetailEntityStatus',
      title: 'Entities Status',
      type: '',
      external: false
    },
    component: EntitiesStatus,
    beforeEnter: required.requireAuth
  },
  {
    path: '/entitystatus/:id?',
    name: 'EntityStatus',
    meta: {
      parent: 'General',
      before: 'IndexEntityStatus',
      index: 'entitiesstatus',
      title: 'Entity Status',
      type: '',
      external: false
    },
    component: EntityStatus,
    beforeEnter: required.requireAuth
  },
  {
    path: '/entityrelationclassifications',
    name: 'IndexEntityRelationClassification',
    meta: {
      parent: 'General',
      create: 'CreateEntityRelationClassification',
      edit: 'DetailEntityRelationClassification',
      title: 'Entity Relation Classifications',
      type: '',
      external: false
    },
    component: EntityRelationClassifications,
    beforeEnter: required.requireAuth
  },
  {
    path: '/entityrelationclassification/:id?',
    name: 'EntityRelationClassification',
    meta: {
      parent: 'General',
      before: 'IndexEntityRelationClassification',
      index: 'entityrelationclassifications',
      title: 'Entity Relation Classification',
      type: '',
      external: false
    },
    component: EntityRelationClassification,
    beforeEnter: required.requireAuth
  }
];

export default generalRoutes;
