
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { Getter, Action } from 'vuex-class';

import { AuthService } from '../../../services/authService';
import axios from 'axios';
import Endpoint from '../../../utils/endpoint';
import User from '../../../types/auth/User';

import FormatDateHours from '../../../utils/formatDateHours';

@Component({})
export default class ModalSaveFinished extends Vue {
  @Getter('getModalSubTask', { namespace: 'checklist' })
  subTaskModal!: boolean;
  @Action('setModalSubTask', { namespace: 'checklist' })
  setModalSubTask!: any;

  @Getter('getSubTaskName', { namespace: 'checklist' })
  subTaskName!: boolean;
  @Action('setSubTaskName', { namespace: 'checklist' })
  setSubTaskName!: any;

  @Getter('getChecklistSubTaskKey', { namespace: 'checklist' })
  checklistSubTaskKey!: boolean;
  @Action('setChecklistSubTaskKey', { namespace: 'checklist' })
  setChecklistSubTaskKey!: any;

  @Getter('getUser', { namespace: 'auth' }) user!: User;

  @Getter('getChecklistEntityStageKey', { namespace: 'checklist' })
  checklistEntityStageKey!: string;

  @Prop(Boolean) readonly loading: boolean | undefined;

  @Prop(String) subTaskKey: string | undefined;

  private endpoint = new Endpoint();
  authService = new AuthService();
  instance = axios.create();

  formatDateHours = FormatDateHours;

  tab = null;
  noteSubTask = '';
  linkSubTaskField = '';
  imgUrl = '';
  handleArchiveIsDownloading = '';
  loadingNoteSubtask = false;
  loadingLinkSubTask = false;
  handleBlockDownload = false;
  blockAttachment = false;
  notesSubTask: any[] = [];
  logSubTask: any[] = [];
  linkSubTask: any[] = [];
  attachmentSubTask: Blob = new Blob();
  attachmentFilesSubTask: Blob[] = [];
  filesToDownloadSubTask: any[] = [];

  get rowNoteField() {
    let number = '1';
    if (this.noteSubTask.length >= 70) number = '2';
    if (this.noteSubTask.length >= 140) number = '3';
    return number;
  }

  public async postNoteSubTask() {
    const params = {
      checklistSubtaskKey: this.checklistSubTaskKey,
      note: this.noteSubTask,
      userUpdateLogin: this.user.email,
    };
    try {
      const response = await this.$http.post(
        this.endpoint.subTaskNote.note,
        params
      );
      if (response.data.success) {
        this.noteSubTask = '';
        await this.getNotesSubTask();
      }
    } catch (error) {
      console.log('Error no post do note subtask');
    }
  }

  public async getNotesSubTask() {
    this.loadingNoteSubtask = true;
    try {
      const response = await this.$http.get(
        this.endpoint.subTaskNote.note + `/${this.checklistSubTaskKey}`
      );
      this.notesSubTask = response.data.data;
      if (response.data.success) {
        await this.getActivityLogSubTask();
      }
    } catch (error) {
      console.log('Erro no Note');
    }
    this.noteSubTask = '';
    this.loadingNoteSubtask = false;
  }

  public async postLinkSubTask() {
    const params = {
      checklistSubtaskKey: this.checklistSubTaskKey,
      userUpdateLogin: this.user.email,
      url: this.linkSubTaskField,
    };
    try {
      const response = await this.$http.post(
        this.endpoint.subTaskLink.link,
        params
      );
      if (response.data.success) {
        this.linkSubTaskField = '';
        await this.getLinkSubTask();
      }
    } catch (error) {
      console.log('Error no post do link subtask');
    }
  }

  public async getLinkSubTask() {
    this.loadingLinkSubTask = true;
    try {
      const response = await this.$http.get(
        this.endpoint.subTaskLink.link + `/${this.checklistSubTaskKey}`
      );
      this.linkSubTask = response.data.data;
      if (response.data.success) {
        await this.getActivityLogSubTask();
      }
    } catch (error) {
      console.log('Erro no get do link');
    }
    this.linkSubTaskField = '';
    this.loadingLinkSubTask = false;
  }

  public async deleteLinkSubTask(item: string) {
    try {
      const response = await this.$http.delete(
        this.endpoint.subTaskLink.link + `/${item}/${this.user.email}`
      );
    } catch (error) {
      console.log('Erro no delete attachment');
    }
    await this.getActivityLogSubTask();
  }

  public remove(item: any, task: any) {
    task.splice(task.indexOf(item), 1);
  }

  public tabLinkSubTask(item: string) {
    if (item.includes('https://')) {
      return `${item}`;
    } else {
      return `https://${item}`;
    }
  }

  public newAttachment() {
    this.blockAttachment = true;
    if (this.attachmentSubTask.size === 0) {
      return;
    } else {
      this.attachmentFilesSubTask.push(this.attachmentSubTask);
    }
    this.blockAttachment = false;
  }

  @Watch('attachmentSubTask')
  public async uploadAttachments() {
    this.blockAttachment = true;
    try {
      const formData = new FormData();
      formData.append('file', this.attachmentSubTask);
      const response = await this.$http.post(
        this.endpoint.subTaskAttachment.attachment +
          `/${this.checklistSubTaskKey}/${this.user.email}`,
        formData,
        { headers: { 'Content-Type': 'multipart/form-data' } }
      );
      if (response.data.success) {
        await this.getAttachment();
        await this.getActivityLogSubTask();
      }
    } catch (error) {
      console.log('Erro no post attachments');
    }
    this.blockAttachment = false;
  }

  public async getAttachment() {
    try {
      const response = await this.$http.get(
        this.endpoint.subTaskAttachment.list + `/${this.checklistSubTaskKey}`
      );
      this.filesToDownloadSubTask = response.data.data;
    } catch (error) {
      console.log('Error no get Attachment', error);
    }
  }

  public async downloadAttachmentSubTask(
    downloadKey: string,
    fileName: string
  ) {
    this.handleArchiveIsDownloading = fileName;
    this.handleBlockDownload = true;
    try {
      const response = await this.$http.get(
        this.endpoint.subTaskAttachment.download + `/${downloadKey}`
      );
      const base64 = response.data.data as string;
      const linkSource = 'data:application/*;base64,' + base64;
      const downloadLink = document.createElement('a');
      downloadLink.href = linkSource;
      downloadLink.download = fileName;
      downloadLink.click();
    } catch (error) {
      console.log('Erro no get download');
    }
    this.handleArchiveIsDownloading = '';
    this.handleBlockDownload = false;
  }

  public async deleteAttachment(item: string) {
    try {
      const response = await this.$http.delete(
        this.endpoint.subTaskAttachment.attachment +
          `/${item}/${this.user.email}`
      );
    } catch (error) {
      console.log('Erro no delete attachment');
    }
    await this.getActivityLogSubTask();
  }

  public async removeAttachments(item: any, index: any, subTaskKey: string) {
    this.attachmentFilesSubTask.splice(index, 1);
    this.filesToDownloadSubTask.splice(
      this.filesToDownloadSubTask.indexOf(item),
      1
    );
    await this.deleteAttachment(subTaskKey);
  }

  public async getActivityLogSubTask() {
    try {
      const response = await this.$http.get(
        this.endpoint.subTaskLog.log + `/${this.checklistSubTaskKey}`
      );
      this.logSubTask = response.data.data;
    } catch (error) {
      console.log('Erro no get do activy log');
    }
  }

  @Watch('checklistSubTaskKey')
  public async handleGet() {
    await this.getNotesSubTask();
    await this.getLinkSubTask();
    await this.getAttachment();
    await this.getActivityLogSubTask();
    await this.getGraphToken();
  }

  async getGraphToken(): Promise<void> {
    const graphToken = await this.authService.getTokenGraph();
    if (graphToken != null) {
      try {
        const resp = await this.instance({
          method: 'get',
          url: 'https://graph.microsoft.com/v1.0/me/photos/48x48/$value',
          responseType: 'blob',
          headers: { Authorization: `Bearer ${graphToken}` },
        });

        this.imgUrl = URL.createObjectURL(resp.data);
      } catch (error) {
        this.imgUrl = require('../../../assets/avatar.png');
      }
    } else {
      this.imgUrl = require('../../../assets/avatar.png');
    }
  }

  closeModal() {
    this.setModalSubTask(false);
  }
}
