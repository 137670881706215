
  import { Component, Prop, Vue } from 'vue-property-decorator';
  import { Getter } from 'vuex-class';

  import Endpoint from '../../../utils/endpoint';
  import Select from '../../../types/app/generic/select';
  import LesteEntity from '@/src/types/entities/lesteEntity';

  @Component({})
  export default class JurisdictionComponent extends Vue {
    endpoint = new Endpoint();

    @Getter('getOriginalLesteEntity', { namespace: 'entity' })
    originalLesteEntity!: LesteEntity;

    @Getter('getLesteEntity', { namespace: 'entity' })
    lesteEntity!: LesteEntity;

    @Prop({ type: Array })
    rules;

    jurisdictionss: Array<Select> = [];

    async created() {
      await this.getJurisdictionsAsync();
    }

    async getJurisdictionsAsync(): Promise<void> {
      const response = await this.$http.get(`${this.endpoint.general.getByTable}/jurisdictions/id/txname`);

      if (response.status === 200) {
        this.jurisdictionss = response.data;
      }
    }

    cleanSelect(): void {
      this.lesteEntity.jurisdictionId = null;
    }
  }
