import { AppState } from './types';
import Snackbar from '../../types/app/application/Snackbar';
import Filter from '@/src/types/app/application/filter';

export default {
  setSnackbar(state: AppState, snackbar: Snackbar) {
    state.snackbar = snackbar;
  },
  setFilter(state: AppState, filter: Filter) {
    state.filter = filter;
  },
  setFilterSkip(state: AppState, skip: number): void {
    state.filter.skip = skip;
  },
  setFullScreen(state: AppState, full: boolean): void {
    state.fullScreen = full;
  },
  setOverlay(state: AppState, overlay: boolean): void {
    state.overlay = overlay;
  }
};
