
import { Component, Vue } from 'vue-property-decorator';
import { Getter, Action } from 'vuex-class';

import Header from '../../types/app/headers/header';
import GeneralHeader from '../../types/app/headers/GeneralHeader';
import GenericFilter from '../../components/generics/GenericFilter.vue';

import Endpoint from '../../utils/endpoint';
import { debounce } from 'lodash';
import { Watch } from 'vue-property-decorator';
import qs from 'qs';
import Filter from '@/src/types/app/application/filter';

@Component({
  components: {
    GenericFilter,
  },
})
export default class BuinessUnits extends Vue {
  endpoint = new Endpoint();
  headers: Array<Header> = GeneralHeader.header;

  @Getter('canCreate', { namespace: 'auth' }) canCreate!: boolean;
  @Getter('canRead', { namespace: 'auth' }) canRead!: boolean;
  @Getter('getFilter', { namespace: 'app' }) filter!: Filter;

  @Action('setFilter', { namespace: 'app' }) setFilter!: any;
  @Action('setFilterSkip', { namespace: 'app' }) setFilterSkip!: any;

  debounceSearch: any = null;
  loading = false;
  loadingMore = false;
  hasMore = true;

  busineesUnits: any[] = [];

  created() {
    this.debounceSearch = debounce(this.getBuinessUnits, 1000);
    this.getBuinessUnits();
  }

  @Watch('filter.search')
  private async filtering() {
    console.log('filtering', this.filter);

    if (this.filter.search != null) {
      this.setFilterSkip(0);

      this.busineesUnits = [];
      this.debounceSearch();
    }
  }

  getByFilter() {
    this.setFilterSkip(0);
    this.busineesUnits = [];

    this.getBuinessUnits();
  }

  cleanFilter() {
    this.setFilter({
      skip: 0,
      take: 10,
      search: null,
      active: null,
    });

    this.busineesUnits = [];
    this.getBuinessUnits();
  }

  getBuinessUnits(): void {
    this.loading = true;

    this.$http
      .get(
        `${this.endpoint.businessUnit.getByFilter}?${qs.stringify(this.filter)}`
      )
      .then((res) => {
        res.data.data.forEach((businessUnit) => {
          businessUnit.activeText =
            businessUnit.active == true ? 'Active' : 'Inactive';
          this.busineesUnits.push(businessUnit);
        });

        this.hasMore = res.data.data.length >= 10;
        this.loading = false;
      })
      .catch((err) => {
        this.loading = false;
        console.log('error response', err);
      });
  }

  getMore(): void {
    const skip = (this.filter.skip += 1);

    this.setFilterSkip(skip);
    this.getBuinessUnits();
  }

  goToDetail(item: any): void {
    this.$router.push({ name: 'BusinessUnit', params: { id: item.id } });
  }
}
