
import { Component, Vue, Prop } from 'vue-property-decorator';
import { Getter, Action } from 'vuex-class';
import { AuthService } from '../../../services/authService';
import axios from 'axios';
import { Watch } from 'vue-property-decorator';
import { GroupAvatar } from 'vue-group-avatar';
import User from '../../../types/auth/User';
import Endpoint from '../../../utils/endpoint';
import FormatDateHours from '../../../utils/formatDateHours';
import ChecklistStageAttachmentPayload from '../../../types/checklist/checklistStageAttachmentPayload';
import VueSkeletonLoader from 'skeleton-loader-vue';
import NoteFieldFull from './noteFieldFullModal.vue';
import LinkFieldFull from './linkFieldFullModal.vue';
import ChecklistSubTaskFormModal from './ChecklistSubTaskFormModal.vue';
import ChecklistDeleteSubTask from './checklistDeleteSubTask.vue';
import FeedbackInterface from '../../../types/checklist/feedback';
import Errors from '../../../types/checklist/errors/errors';
import SetErrorMessage from '../../../utils/returnErrorMesage';

import Entities from '../../../types/checklist/entities';
import ChecklistTeams from '@/src/types/checklist/checklistTeams';
import ChecklistSubTask, {
  checklistSubTaskInitialValue,
} from '../../../types/checklist/checklistSubTask';

@Component({
  components: {
    NoteFieldFull: NoteFieldFull,
    LinkFieldFull: LinkFieldFull,
    ChecklistSubTaskFormModal: ChecklistSubTaskFormModal,
    ChecklistDeleteSubTask: ChecklistDeleteSubTask,
    VueSkeletonLoader,
    GroupAvatar,
  },
})
export default class Modal extends Vue {
  @Getter('getModalTasks', { namespace: 'checklist' }) modal!: boolean;
  @Action('setModalTasks', { namespace: 'checklist' }) setModalTasks!: any;

  @Getter('getDeleteSubTaskModal', { namespace: 'checklist' })
  deleteSubTask!: boolean;
  @Action('setDeleteSubTaskModal', { namespace: 'checklist' })
  setDeleteSubTaskModal!: any;

  @Getter('getUser', { namespace: 'auth' }) user!: User;

  @Getter('getChecklistEntityStageKey', { namespace: 'checklist' })
  checklistEntityStageKey!: string;
  @Action('setChecklistEntityStageKey', { namespace: 'checklist' })
  setChecklistEntityStageKey!: any;

  @Getter('getSubTaskKey', { namespace: 'checklist' })
  subTaskKey!: string;
  @Action('setSubTaskKey', { namespace: 'checklist' })
  setSubTaskKey!: any;

  @Getter('getIsEditSubTask', { namespace: 'checklist' })
  isEditSubTask!: boolean;
  @Action('setIsEditSubTask', { namespace: 'checklist' })
  setIsEditSubTask!: any;

  @Getter('getChecklistNameTask', { namespace: 'checklist' })
  checklistNameTask!: string;
  @Action('setChecklistNameTask', { namespace: 'checklist' })
  setChecklistNameTask!: any;

  @Getter('getSubTaskName', { namespace: 'checklist' })
  subTaskName!: boolean;
  @Action('setSubTaskName', { namespace: 'checklist' })
  setSubTaskName!: any;

  @Getter('getNoteFieldFull', { namespace: 'checklist' })
  noteFieldFull!: boolean;
  @Action('setNoteFieldFull', { namespace: 'checklist' })
  setNoteFieldFull!: any;

  @Getter('getNote', { namespace: 'checklist' })
  public note!: string;
  @Action('setNote', { namespace: 'checklist' }) public setNote!: any;

  @Getter('getLinkFieldFull', { namespace: 'checklist' })
  linkFieldFull!: boolean;
  @Action('setLinkFieldFull', { namespace: 'checklist' })
  setLinkFieldFull!: any;

  @Getter('getSubTaskFormModal', { namespace: 'checklist' })
  modalSubTaskForm!: boolean;
  @Action('setSubTaskFormModal', { namespace: 'checklist' })
  setSubTaskFormModal!: any;

  @Getter('getTaskKey', { namespace: 'checklist' })
  taskKey!: string;
  @Action('setTaskKey', { namespace: 'checklist' })
  setTaskKey!: any;

  @Getter('getLink', { namespace: 'checklist' })
  public link!: string;
  @Action('setLink', { namespace: 'checklist' }) public setLink!: any;

  @Getter('getFeedback', { namespace: 'checklist' })
  public feedback!: FeedbackInterface;
  @Action('setFeedback', { namespace: 'checklist' }) public setFeedback: any;

  // for Subtask
  @Getter('setEntityStageKeyForSubTaskReload', { namespace: 'checklist' })
  public entityStageKeyForSubTaskReload: any;
  @Getter('getStageKeyForSubTaskReload', { namespace: 'checklist' })
  public stageKeyForSubTaskReload: any;

  // @Getter('getUserCanInteract', { namespace: 'checklist' })
  // public userCanInteract!: string;
  // @Action('setUserCanInteract', { namespace: 'checklist' })
  // public setUserCanInteract!: string;

  @Prop(String) nameTask: string | undefined;
  @Prop(String) statusTask: string | undefined;
  @Prop(String) checklistKey: string | undefined;

  @Prop(Number) checklistId: number | undefined;

  @Prop(Boolean) readonly loadingNote: boolean | undefined;
  @Prop(Boolean) readonly loadingSubTask!: boolean;
  // @Prop(Boolean) userCanInteract: boolean | undefined;

  @Prop(Array) log: any | undefined;
  @Prop(Array) notes: any | undefined;
  @Prop(Array) teams: ChecklistTeams[] | undefined;
  @Prop(Array) checklistSubTasks: ChecklistSubTask[] | undefined;

  formatDateHours = FormatDateHours;
  setErrorMessage = SetErrorMessage;

  private endpoint = new Endpoint();
  authService = new AuthService();
  instance = axios.create();

  tab = null;
  max = 4;
  one = 1;
  two = 2;
  three = 3;
  item = [];
  entities: Entities = new Entities();
  attachment: Blob = new Blob();
  attachmentFiles: Blob[] = [];
  filesToDownload: ChecklistStageAttachmentPayload[] = [];
  handleBlockDownload = false;
  handleArchiveIsDownloading = '';
  showSkeleton = true;
  imgUrl = '';
  userHasProfileImage = false;
  noProfilePhoto = require('../../../assets/avatar.png');
  linkA: any[] = [];
  linkField = '';
  noteField = '';
  blockAttachment = false;
  loadingLink = false;
  subTaskData = {} as ChecklistSubTask;
  checklistSubTask: ChecklistSubTask = checklistSubTaskInitialValue;
  loading = false;

  public newAttachment() {
    this.blockAttachment = true;
    if (this.attachment.size === 0) {
      return;
    } else {
      this.attachmentFiles.push(this.attachment);
    }
    this.blockAttachment = false;
  }

  @Watch('attachment')
  public async uploadAttachments() {
    this.blockAttachment = true;
    try {
      const formData = new FormData();
      formData.append('file', this.attachment);
      const response = await this.$http.post(
        this.endpoint.attachments.stageAttachments +
          `/${this.checklistEntityStageKey}/${this.user.email}`,
        formData,
        { headers: { 'Content-Type': 'multipart/form-data' } }
      );
      if (response.data.success) {
        await this.getAttachment();
        await this.getActivityLog();
      }
    } catch (error) {
      console.log('Erro no post attachments');
      const responseError = error as Errors;
      this.setFeedback({
        feedback: true,
        color: 'red',
        text: this.setErrorMessage(
          responseError.data.errors,
          false,
          'attachment'
        ),
      });
      setTimeout((): void => {
        this.setFeedback({
          feedback: false,
        });
      }, 5000);
    }
    this.blockAttachment = false;
  }

  public async getAttachment() {
    try {
      const response = await this.$http.get(
        this.endpoint.attachmentList.listAttachment +
          `/${this.checklistEntityStageKey}`
      );
      this.filesToDownload = response.data.data;
    } catch (error) {
      console.log('Error no get Attachment', error);
    }
  }

  public async downloadAttachment(downloadKey: string, fileName: string) {
    this.handleArchiveIsDownloading = fileName;
    this.handleBlockDownload = true;
    this.showSkeleton = true;
    try {
      const response = await this.$http.get(
        this.endpoint.attachmentDownload.download + `/${downloadKey}`
      );
      const base64 = response.data.data as string;
      const linkSource = 'data:application/*;base64,' + base64;
      const downloadLink = document.createElement('a');
      downloadLink.href = linkSource;
      downloadLink.download = fileName;
      downloadLink.click();
    } catch (error) {
      console.log('Erro no get download');
    }
    this.handleArchiveIsDownloading = '';
    this.handleBlockDownload = false;
    this.showSkeleton = false;
  }

  get rowNoteField() {
    let number = '1';
    if (this.noteField.length >= 70) number = '2';
    if (this.noteField.length >= 140) number = '3';
    return number;
  }

  public async postNotes() {
    const params = {
      checklistEntityStageKey: this.checklistEntityStageKey,
      note: this.noteField,
      userUpdateLogin: this.user.email,
    };
    try {
      if (this.noteField === '') return;
      const response = await this.$http.post(
        this.endpoint.note.stageNote,
        params
      );
      if (response.data.success) {
        this.noteField = '';
        await this.getNotes();
      }
      if (this.noteFieldFull === true) this.closeModalNote();
    } catch (error) {
      const responseError = error as Errors;
      this.setFeedback({
        feedback: true,
        color: 'red',
        text: this.setErrorMessage(responseError.data.errors, false, 'note'),
      });
      setTimeout((): void => {
        this.setFeedback({
          feedback: false,
        });
      }, 5000);
    }
    await this.getActivityLog();
  }

  public closeModalNote() {
    this.noteField = '';
    this.setNoteFieldFull(false);
  }
  public closeModalLink() {
    this.linkField = '';
    this.setLinkFieldFull(false);
  }

  public async getNotes() {
    this.$emit('getNotes');
  }

  public async getActivityLog() {
    this.$emit('getActivityLog');
  }

  @Watch('checklistEntityStageKey')
  public async handleGetNote() {
    await this.getNotes();
    await this.getAttachment();
    await this.getLink();
    await this.handleGetSubTask();
    await this.getGraphToken();
    await this.getActivityLog();
  }

  @Watch('postNotes')
  public handleGetEntityNote() {
    this.getNotes();
  }

  public tabLink(item: string) {
    if (item.includes('https://')) {
      return `${item}`;
    } else {
      return `https://${item}`;
    }
  }

  public async deleteAttachment(item: string) {
    try {
      const response = await this.$http.delete(
        this.endpoint.deleteAttachment.delete + `/${item}/${this.user.email}`
      );
    } catch (error) {
      console.log('Erro no delete attachment');
    }
    await this.getActivityLog();
  }

  public removeAttachments(item: ChecklistStageAttachmentPayload) {
    // console.log(index);
    // this.attachmentFiles.splice(index, 1);
    this.filesToDownload.splice(this.filesToDownload.indexOf(item), 1);
  }

  public remove(item: any, task: any) {
    task.splice(task.indexOf(item), 1);
  }

  public async getLink() {
    try {
      const response = await this.$http.get(
        this.endpoint.linkSend.link + `/${this.checklistEntityStageKey}`
      );
      this.linkA = response.data.data;
    } catch (error) {
      console.log('Error no get Attachment', error);
    }
  }

  //aqui
  public async handleGetSubTask() {
    this.$emit('getSubTask');
  }

  //aqui
  async reloadSubtaskData() {
    await this.handleGetSubTask();
  }

  public setEntitiesLength(item: any) {
    const response = item;
    if (response.length <= 1) {
      return `${response.length} company`;
    } else {
      return `${response.length} companies`;
    }
  }

  public async postLink() {
    this.loadingLink = true;
    const params = {
      checklistEntityStageKey: this.checklistEntityStageKey,
      userUpdateLogin: this.user.email,
      url: this.linkField,
    };
    try {
      const response = await this.$http.post(
        this.endpoint.linkSend.link,
        params
      );
      if (response.data.success) {
        await this.getLink();
        await this.getActivityLog();
      }
      if (this.linkFieldFull === true) this.closeModalLink();
    } catch (error) {
      console.log('Erro no post link');
      const responseError = error as Errors;
      this.setFeedback({
        feedback: true,
        color: 'red',
        text: this.setErrorMessage(responseError.data.errors, false, 'link'),
      });
      setTimeout((): void => {
        this.setFeedback({
          feedback: false,
        });
      }, 5000);
    } finally {
      this.linkField = '';
      this.loadingLink = false;
    }
  }

  public async deleteLink(item: string) {
    try {
      const response = await this.$http.delete(
        this.endpoint.linkSend.link + `/${item}/${this.user.email}`
      );
    } catch (error) {
      console.log('Erro no delete attachment');
      const responseError = error as Errors;
      this.setFeedback({
        feedback: true,
        color: 'red',
        text: this.setErrorMessage(
          responseError.data.errors,
          false,
          'deleteLink'
        ),
      });
      setTimeout((): void => {
        this.setFeedback({
          feedback: false,
        });
      }, 5000);
    }
    await this.getActivityLog();
  }

  closeModal() {
    if (this.noteField !== '') {
      this.noteField;
      this.setNoteFieldFull(true);
    }
    if (this.linkField !== '') {
      this.linkField;
      this.setLinkFieldFull(true);
    }
    this.tab = null;
    this.setModalTasks(false);
  }

  public assertChecklistSubTask(item?: string) {
    this.setModalTasks(false);
    this.setSubTaskFormModal(true);
    if (item === undefined || this.checklistSubTasks === undefined) return;
    const checklistSubTask = this.checklistSubTasks.find(
      (checklist) => checklist.checklistSubtaskKey === item
    );
    if (!checklistSubTask) return;
    this.checklistSubTask = checklistSubTask;
  }

  public handleSubTaskModal(
    isEditSubTaskModal: boolean,
    checklistSubTaskKey?: string
  ) {
    this.setIsEditSubTask(isEditSubTaskModal);
    this.setSubTaskKey(checklistSubTaskKey);
    this.assertChecklistSubTask(checklistSubTaskKey);
  }

  showDeleteSubTaskModal(item: string, name: string) {
    this.setModalTasks(false);
    this.setSubTaskKey(item);
    this.setSubTaskName(name);
    this.setDeleteSubTaskModal(true);
  }

  public async saveSubTask(params: ChecklistSubTask[]) {
    this.loading = true;
    try {
      await this.$http.post(this.endpoint.subTask.create, params);
      this.setFeedback({
        feedback: true,
        color: 'green',
        text: 'SubTask successfully created',
      });
      setTimeout((): void => {
        this.setFeedback({
          feedback: false,
        });
      }, 5000);
    } catch (error) {
      const responseError = error as Errors;
      console.log('Error no post do subtask');
      this.setFeedback({
        feedback: true,
        color: 'red',
        text: this.setErrorMessage(responseError.data.errors, false, 'subtask'),
      });
      setTimeout((): void => {
        this.setFeedback({
          feedback: false,
        });
      }, 5000);
    }
    this.loading = false;
  }

  public async saveEditSubTask(params: ChecklistSubTask[]) {
    this.loading = true;
    try {
      await this.$http.put(this.endpoint.subTask.create, params);
      this.setFeedback({
        feedback: true,
        color: 'green',
        text: 'SubTask successfully edited',
      });
      setTimeout((): void => {
        this.setFeedback({
          feedback: false,
        });
      }, 5000);
    } catch (error) {
      const responseError = error as Errors;
      console.log('Error no post do subtask');
      this.setFeedback({
        feedback: true,
        color: 'red',
        text: this.setErrorMessage(responseError.data.errors, true, 'subtask'),
      });
      setTimeout((): void => {
        this.setFeedback({
          feedback: false,
        });
      }, 5000);
    }
    this.loading = false;
  }

  public async putDeleteSubTaskModal() {
    this.loadingLink = true;
    try {
      await this.$http.delete(
        this.endpoint.subTask.delete + `/${this.subTaskKey}/${this.user.email}`
      );
      this.setFeedback({
        feedback: true,
        color: 'red',
        text: 'SubTask successfully removed',
      });
      setTimeout((): void => {
        this.setFeedback({
          feedback: false,
        });
      }, 5000);
    } catch (error) {
      const responseError = error as Errors;
      console.log('Error ao deletar subtask');
      this.setFeedback({
        feedback: true,
        color: 'red',
        text: this.setErrorMessage(
          responseError.data.errors,
          false,
          'deleteSubtask'
        ),
      });
      setTimeout((): void => {
        this.setFeedback({
          feedback: false,
        });
      }, 5000);
    }
    this.loadingLink = false;
    this.closeModalDelete();
  }

  closeModalDelete() {
    this.setDeleteSubTaskModal(false);
    this.setModalTasks(true);
  }

  handleSetTaskPosition(item: any): string {
    const sequenceIndex = (item.indexOf(item) + 1).toString();
    return sequenceIndex;
  }

  public returnProfilePhoto(item: string) {
    if (this.userHasProfileImage && item === this.user.email) {
      return this.imgUrl;
    } else {
      return this.noProfilePhoto;
    }
  }

  async getGraphToken(): Promise<void> {
    const graphToken = await this.authService.getTokenGraph();
    if (graphToken != null) {
      try {
        const resp = await this.instance({
          method: 'get',
          url: 'https://graph.microsoft.com/v1.0/me/photos/48x48/$value',
          responseType: 'blob',
          headers: { Authorization: `Bearer ${graphToken}` },
        });
        this.userHasProfileImage = true;
        this.imgUrl = URL.createObjectURL(resp.data);
      } catch (error) {
        this.userHasProfileImage = false;
      }
    } else {
      this.userHasProfileImage = false;
    }
  }
}
