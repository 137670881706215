
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { Getter, Action } from 'vuex-class';

import Endpoint from '../../utils/endpoint';
import FormatDate from '../../utils/formatDate';
import formatDateDeadlineStart from '../../utils/formatDateDeadline';
import SetDate from '../../utils/setDate';
import SetErrorMessage from '../../utils/returnErrorMesage';

import { MapTriggers } from '../../types/checklist/triggers/checklistTriggerPayload';

import Checklist from '../../types/checklist/checklist';
import ChecklistPayload, {
  checklistPayLoadInitialValue,
} from '../../types/checklist/checklistPayload';
import User from '../../types/auth/User';
import ChecklistStage from '@/src/types/checklist/checklistStage';
import ChecklistStageTask from '@/src/types/checklist/checklistStageTask';
import ChecklistTrigger from '@/src/types/checklist/checklistTrigger';
import ChecklistSaveModal from '../../components/checklist/Modais/ChecklistSaveModal.vue';
import ChecklistSaveDraftModal from '../../components/checklist/Modais/ChecklistSaveDraftModal.vue';
import ChecklistEntitiesModal from '../../components/checklist/Modais/ChecklistEntitiesModal.vue';
import FeedbackInterface from '../../types/checklist/feedback';
import Header from '../../types/app/headers/header';
import ChecklistStageHeader from '../../types/app/headers/ChecklistStageHeader';
import ChecklistTask from '../../types/checklist/checklistTask';
import ChecklistStageTaskHeader from '../../types/app/headers/ChecklistStageTaskHeader';
import ChecklistTeams from '../../types/checklist/checklistTeams';
import ChecklistOwners from '../../types/checklist/checklistOwners';
import Predecessor from '../../types/checklist/predecessor';
import Errors from '../../types/checklist/errors/errors';

import TriggerPayload from '../../types/checklist/triggers/checklistTriggerPayload';
import TriggerType from '../../types/checklist/triggerType';
import ChecklistStageEntities from '../../types/checklist/checklistStageEntities';
import PredecessorSelected from '@/src/types/checklist/stages/predecessorSelected';

import vueDebounce, { PluginConfig, debounce } from 'vue-debounce';

const ACTIVE = 1;
const DISABLED = 2;
const DRAFT = 3;
const LATE = 4;
const ON_TIME = 5;
const ALERT = 6;

async function checkIfHasCompanies(hasCompanies: () => Promise<boolean>) {
  if (!(await hasCompanies())) return;
}

export type VForm = Vue & { validate: () => boolean } & {
  resetValidation: () => boolean;
};

@Component({
  components: {
    ChecklistEntitiesModal: ChecklistEntitiesModal,
    ChecklistSaveModal: ChecklistSaveModal,
    ChecklistSaveDraftModal: ChecklistSaveDraftModal,
  },
})
export default class CreateChecklist extends Vue {
  endpoint = new Endpoint();
  headers: Array<Header> = ChecklistStageHeader.indexHeader;
  taskHeaders: Array<Header> = ChecklistStageTaskHeader.indexHeader;

  formatDate = FormatDate;
  formatDateDeadline = formatDateDeadlineStart;
  setDate = SetDate;
  setErrorMessage = SetErrorMessage;

  @Getter('getChecklist', { namespace: 'checklist' }) checklist!: Checklist;
  @Getter('getChecklistStage', { namespace: 'checklist' })
  checklistStage!: ChecklistStage;
  @Getter('getChecklistTrigger', { namespace: 'checklist' })
  checklistTrigger!: ChecklistTrigger;
  @Getter('getChecklistStageTask', { namespace: 'checklist' })
  checklistStageTask!: ChecklistStageTask;
  @Getter('getUser', { namespace: 'auth' }) user!: User;

  @Action('setChecklist', { namespace: 'checklist' }) setChecklist!: any;
  @Action('setChecklistStage', { namespace: 'checklist' })
  setChecklistStage!: any;
  @Action('setSnackbar', { namespace: 'app' }) setSnackbar!: any;

  @Getter('getSaveModal', { namespace: 'checklist' })
  saveChecklistModal!: boolean;
  @Action('setSaveModal', { namespace: 'checklist' }) setSaveModal!: any;

  @Getter('getSaveDraftModal', { namespace: 'checklist' })
  saveDraftModal!: boolean;
  @Action('setSaveDraftModal', { namespace: 'checklist' })
  setSaveDraftModal!: any;

  @Getter('getEntitiesModal', { namespace: 'checklist' })
  entitiesChecklistModal!: boolean;
  @Action('setEntitiesModal', { namespace: 'checklist' })
  setEntitiesModal!: any;

  @Action('setChecklistData', { namespace: 'checklist' })
  public setChecklistData: any;

  @Getter('getFeedback', { namespace: 'checklist' })
  public feedback!: FeedbackInterface;
  @Action('setFeedback', { namespace: 'checklist' }) public setFeedback: any;

  @Prop(String) readonly id: string | undefined;

  requiredRules: Array<any> = [
    (v: any): boolean | string => {
      if (v) {
        if (typeof v === 'string' && v.trim().length === 0)
          return 'Required field';
        return true;
      }
      if (v === false) return true;
      if (v === 0) return true;

      return 'Required Field';
    },
  ];

  requiredRulesEvidenceAndTriggers: Array<any> = [
    (v: any): boolean | string => {
      if (v) {
        if (typeof v === 'string' && v.trim().length === 0) return '';
        return true;
      }
      if (v === false) return true;
      if (v === 0) return true;

      return '';
    },
  ];

  loading = false;
  loadingSave = false;

  checklistItems: ChecklistPayload = checklistPayLoadInitialValue;

  checklistName = '';

  checklistStages: any = [];
  checklistStageTasks: any = [];
  triggers = [];

  triggersChecklist: TriggerPayload[] = [];
  entityTriggerSelected: any[] = [];
  entityTypeTriggerSelected: any[] = [];
  formOrganizationTriggerSelected: any[] = [];
  regulatoryLicenseTriggerSelected: any[] = [];
  jurisdictionsTriggerSelected: any[] = [];
  openCloseTriggerSelected: any[] = [];
  entityBrasilTriggerSelected: any[] = [];

  triggersPayload: TriggerPayload[] = [];
  triggersForSetPayload: TriggerPayload[] = [];
  mapTriggers = {};

  entityTriggers = {};
  entityTypeTriggers = {};
  formOrganizationTriggers = {};
  regulatoryLicenseTriggers = {};
  jurisdictionsTriggers = {};
  openCloseTriggers = {};
  entityBrasilTriggers = {};

  entities: ChecklistStageEntities[] = [];
  entitiesSelected: ChecklistStageEntities[] = [];
  ignoredEntities: ChecklistStageEntities[] = [];
  gettedIgnoredEntities: string[] = [];
  loadingTrigger = false;
  totalRecords = '';

  tasks: ChecklistTask[] = [];
  interval = '';
  anotherInterval = '';
  comment = '';
  estimatedDays = 0;
  statusId = 1;
  saveStatusId = 0;

  otherCheck = true;
  startAt = new Date().toString();
  startAtDate = this.formatDateDeadline(this.startAt);

  deadline = null;
  deadlineDate = '';
  realDeadLine = null;
  deadlineDateTask = '';

  evidenceTrue = true;
  evidenceFalse = false;

  isEdited = false;
  loadingEdit = false;

  owners: ChecklistOwners[] = [];
  ownerSelected = { name: '', email: '', department: '' };
  loadingOwners = false;

  teams: ChecklistTeams[] = [];
  teamSelected: ChecklistTeams = { name: '' };

  predecessor: Predecessor[] = [];
  predecessorSelected: Predecessor = { position: 0, name: '' };
  auxPredecessor: any[] = [];

  gettedStages: ChecklistStage[] = [];

  saveModalText = '';

  handleModalError = debounce(async (hasCompanies: () => Promise<boolean>) => {
    const companiesStorage = await hasCompanies();
    if (companiesStorage) {
      sessionStorage.setItem('triggersSelectedHasCompany', 'true');
    } else {
      sessionStorage.setItem('triggersSelectedHasCompany', 'false');
    }
  }, '2000ms');

  get form(): VForm {
    return this.$refs.form as VForm;
  }

  async getTriggersForList() {
    try {
      const triggers = await this.$http.get(this.endpoint.triggers.list);
      this.triggersChecklist = triggers.data.data.triggers;

      this.entityTriggers = this.triggersChecklist[0];
      this.entityTypeTriggers = this.triggersChecklist[1];
      this.formOrganizationTriggers = this.triggersChecklist[2];
      this.regulatoryLicenseTriggers = this.triggersChecklist[3];
      this.jurisdictionsTriggers = this.triggersChecklist[4];
      this.openCloseTriggers = this.triggersChecklist[5];
      this.entityBrasilTriggers = this.triggersChecklist[6];
    } catch (error) {
      console.log('Ocorreu um erro ao listar Triggers');
    }
  }

  async saveDraft() {
    this.loadingSave = true;
    const params = {
      name: this.checklistName,
      teamKey: this.teamSelected.name,
      ownerKey: this.ownerSelected.email,
      interval: this.interval,
      active: true,
      // startDate: this.setDate(this.startAtDate),
      realDeadLine: this.setDate(this.deadlineDate),
      statusId: this.statusId,
      comment: this.comment,
      userUpdatedLogin: this.user.email,
      checklistTriggers: this.triggersPayload,
      checklistStages: this.tasks.map((item) => ({
        id: item.id,
        checklistStageKey: item.checklistStageKey,
        checklistKey: item.checklistKey,
        name: item.name,
        teamKey: item.teamSelected.name,
        sequence: item.sequence,
        isEvidence: this.setEvidenceValue(item.isEvidence.toString()),
        estimatedDays: parseInt(item.estimatedDays),
        comment: item.comment,
        predecessorChecklistStage: item.predecessorSelected?.name,
        userUpdatedLogin: this.user.email,
        estimateDeadLine: this.setDate(item.deadlineDate),
      })),
      ignoredEntities: this.ignoredEntities,
    };
    try {
      await this.$http.post(this.endpoint.save.draft, params);
      this.closeModal();
      this.setFeedback({
        feedback: true,
        color: 'green',
        text: this.isEdited
          ? 'Checklist successfully edited'
          : 'Checklist successfully created',
      });
      setTimeout((): void => {
        this.setFeedback({
          feedback: false,
        });
      }, 6000);
      this.goToIndex();
    } catch (error) {
      const errorData = error as Errors;
      console.log(errorData);
      console.log('Ocorreu um erro no Post');
      this.closeModal();
      this.setFeedback({
        feedback: true,
        color: 'red',
        text: this.setErrorMessage(
          errorData.data.errors,
          this.isEdited,
          'checklist'
        ),
      });
      setTimeout((): void => {
        this.setFeedback({
          feedback: false,
        });
      }, 6000);
    } finally {
      this.loadingSave = false;
    }
  }

  closeSaveChecklist() {
    this.saveStatusId = 0;
    this.setSaveDraftModal(false);
    this.setSaveModal(false);
  }

  async enableChecklist() {
    this.saveStatusId = 1;
    await this.showSaveModal();
  }

  async saveChecklist() {
    // const hasCompaniesAssociated =
    //   await this.triggersSelectedsHasCompaniesAssociated();
    // if (!hasCompaniesAssociated) {
    //   this.setFeedback({
    //     feedback: true,
    //     color: 'red',
    //     text: 'The selected triggers has none companies associated.',
    //   });
    //   setTimeout((): void => {
    //     this.setFeedback({
    //       feedback: false,
    //     });
    //   }, 6000);

    //   this.closeModal();
    //   return;
    // }

    this.loadingSave = true;
    if (this.saveStatusId !== 0) this.statusId = 1;

    const params = {
      name: this.checklistName,
      teamKey: this.teamSelected.name,
      ownerKey: this.ownerSelected.email,
      interval: this.interval,
      active: true,
      // startDate: this.setDate(this.startAtDate),
      realDeadLine: this.setDate(this.deadlineDate),
      statusId: this.statusId,
      comment: this.comment,
      userUpdatedLogin: this.user.email,
      checklistTriggers: this.triggersPayload,
      checklistStages: this.tasks.map((item) => ({
        id: item.id,
        checklistStageKey: item.checklistStageKey,
        checklistKey: item.checklistKey,
        name: item.name,
        teamKey: item.teamSelected.name,
        sequence: item.sequence,
        isEvidence: this.setEvidenceValue(item.isEvidence.toString()),
        estimatedDays: parseInt(item.estimatedDays),
        comment: item.comment,
        predecessorChecklistStage: item.predecessorSelected?.name,
        userUpdatedLogin: this.user.email,
        estimateDeadLine: this.setDate(item.deadlineDate),
      })),
      ignoredEntities: this.ignoredEntities,
    };
    try {
      if (this.isEdited) {
        const items = await this.$http.put(this.endpoint.checklist.list, {
          ...params,
          checklistKey: this.id,
        });
      } else {
        await this.$http.post(this.endpoint.checklist.list, params);
      }
      this.closeModal();
      this.setFeedback({
        feedback: true,
        color: 'green',
        text: this.isEdited
          ? 'Checklist successfully edited'
          : 'Checklist successfully created',
      });
      setTimeout((): void => {
        this.setFeedback({
          feedback: false,
        });
      }, 6000);
      this.goToIndex();
    } catch (error) {
      const errorData = error as Errors;

      console.log('Ocorreu um erro no Post');
      if (this.saveStatusId !== 0) {
        this.statusId = 3;
        this.saveStatusId = 0;
      }
      this.closeModal();
      this.setFeedback({
        feedback: true,
        color: 'red',
        text: this.setErrorMessage(
          errorData.data.errors,
          this.isEdited,
          'checklist'
        ),
      });
      setTimeout((): void => {
        this.setFeedback({
          feedback: false,
        });
      }, 6000);
    } finally {
      this.loadingSave = false;
    }
  }

  //Entities
  public setIgnoredEntities(entitiesSelectedOnModal: ChecklistStageEntities[]) {
    if (this.isEdited) {
      this.gettedIgnoredEntities = [];
    }
    this.ignoredEntities = [];
    this.entitiesSelected = entitiesSelectedOnModal;
    if (this.entities.length !== entitiesSelectedOnModal.length) {
      this.ignoredEntities = this.entities.filter(
        (x) => !entitiesSelectedOnModal.includes(x)
      );
    }
    this.closeModalEntities();
  }

  public emptyVariablesEntities() {
    this.entities = [];
    this.entitiesSelected = [];
    this.totalRecords = '';
  }

  @Watch('getTriggersForList')
  public handleTriggers() {
    this.totalRecords;
  }

  closeModalEntities() {
    this.setEntitiesModal(false);
  }

  closeModal() {
    this.setSaveModal(false);
    this.setSaveDraftModal(false);
  }

  async getTeams() {
    try {
      const response = await this.$http.get(this.endpoint.teams.list);
      const items = response.data.data;
      this.teams = items;
    } catch (error) {
      console.log('Erro em buscar o teams');
    }
  }

  @Watch('teamSelected')
  async handleTeam() {
    this.loading = true;
    this.ownerSelected = { name: '', email: '', department: '' };
    await this.getOwners();
    this.loading = false;
  }

  async getOwners() {
    this.loadingOwners = true;
    try {
      const response = await this.$http.get(
        this.endpoint.owners.list + `/${this.teamSelected.name}`
      );
      const items = response.data.data;
      this.owners = items;
    } catch (error) {
      console.log('Erro em buscar os owners');
    }
    this.loadingOwners = false;
  }

  setSequencePosition(item: ChecklistTask): string {
    const index = this.tasks.indexOf(item);
    const sequenceIndex = (this.tasks.indexOf(item) + 1).toString();

    this.tasks[index].sequence = sequenceIndex;

    return sequenceIndex;
  }

  handleSetTaskPosition(item: ChecklistTask): string {
    const sequenceIndex = (this.tasks.indexOf(item) + 1).toString();
    return sequenceIndex;
  }

  back() {
    this.$router.push({
      path: '/checklist',
    });
  }

  goToIndex() {
    this.$router.push({
      path: '/checklist',
    });
  }

  isTriggerType(value: TriggerType | string): value is TriggerType {
    return typeof value === 'object';
  }

  public triggersSeparateds(
    item: TriggerPayload,
    referenceId: (TriggerType | string)[]
  ): TriggerPayload[] {
    return referenceId.map((reference) => ({
      id: 0,
      checklistTriggerKey: '',
      checklistKey: '',
      triggerTypeId: item.triggerTypeId,
      triggerReferenceId: this.isTriggerType(reference)
        ? reference.id
        : reference,
      userUpdatedLogin: this.user.email,
      isDeleted: false,
    }));
  }

  setTriggersForRequest(
    item: TriggerPayload,
    referenceId: TriggerType[] | string[]
  ) {
    const triggersSelecteds: TriggerPayload[] = this.triggersSeparateds(
      item,
      referenceId
    );

    this.mapTriggers[item.triggerTypeId] = triggersSelecteds;
    this.triggersPayload = Object.keys(this.mapTriggers)
      .map((key) => this.mapTriggers[key])
      .flat();
  }

  @Watch('triggersPayload')
  handleGetError() {
    this.handleModalError(this.triggersSelectedsHasCompaniesAssociated);
  }

  public getLastElement<TypeElement>(items: TypeElement[]): TypeElement {
    return items[items.length - 1];
  }

  public setEvidenceValue(evidence: string) {
    return evidence === 'true' ? true : false;
  }

  public async getChecklistById(): Promise<void> {
    this.loadingEdit = true;
    const params = {
      ChecklistKey: this.id,
      LoggedUserEmail: this.user.email,
    };

    const url = this.isEdited ? this.endpoint.checklist.getById : this.endpoint.checklist.duplicateWithId;
    const items = await this.$http.get(url, {
      params,
    });
    this.checklistItems = items.data.data;
    await this.setChecklistItems(this.checklistItems);
    this.loadingEdit = false;
  }

  async setChecklistItems(item: ChecklistPayload) {
    this.statusId = item.statusId;
    this.checklistName = item.name;
    this.teamSelected = this.teams.find(
      (x) => x.name === item.teamKey
    ) as ChecklistTeams;
    await this.getOwners();
    this.ownerSelected = this.owners.find(
      (x) => x.email === item.ownerKey
    ) as ChecklistOwners;
    this.interval = item.interval;
    this.deadlineDate = item.realDeadLine;
    this.comment = item.comment;
    // this.startAtDate = this.formatDate(item.startDate);
    item.checklistTriggers.forEach((checklistTrigger) => {
      const trigger: TriggerPayload =
        this.checklistTriggerToTriggerPayload(checklistTrigger);
      this.setChecklistTriggers(trigger);
      this.addTriggerToMapTrigger(trigger, this.mapTriggers);
    });
    this.gettedStages = item.checklistStages;
    item.checklistStages.forEach((item) => {
      this.tasks.push(this.mapChecklistStage(item));
    });
    if (item.checklistIgnoredEntities.length > 0) {
      item.checklistIgnoredEntities.forEach((x) =>
        this.gettedIgnoredEntities.push(x.entityKey)
      );
    }
    this.createListOfPredecessors();
    this.setObtainedPredecessors();
  }

  //Entities
  async getEntitiesTriggerModal(entitiesTriggers: any) {
    this.loadingTrigger = true;
    try {
      const response = await this.$http.post(
        this.endpoint.entitiesModal.list,
        entitiesTriggers
      );
      if (this.isEdited) {
        this.setEditIgnoredEntities(response.data.data.entities);
      } else {
        this.setValueForModalEntities(response.data.data.entities);
      }
      if (this.entities.length > 0) {
        const records = response.data.data.totalRecords;
        this.totalRecords = '(' + records + ')';
      } else {
        this.totalRecords = '';
      }
    } catch (error) {
      console.log('Ocorreu um erro no Post');
    }
    this.loadingTrigger = false;
  }
  //Entities
  public setEditIgnoredEntities(entities: ChecklistStageEntities[]) {
    if (entities.length === 0) {
      this.gettedIgnoredEntities = [];
      this.entities = [];
      this.entitiesSelected = [];
      this.ignoredEntities = [];
    } else if (JSON.stringify(this.entities) !== JSON.stringify(entities)) {
      this.entities = [];
      if (this.gettedIgnoredEntities.length === 0) {
        this.entities = entities;
        this.ignoredEntities = entities;
      } else {
        for (let i = 0; i < entities.length; i++) {
          this.entities.push(entities[i]);
          if (this.gettedIgnoredEntities.find((x) => x === entities[i].id)) {
            this.ignoredEntities.push(entities[i]);
          } else {
            this.entitiesSelected.push(entities[i]);
          }
        }
      }
    }
  }
  //Entities
  public setValueForModalEntities(entities: ChecklistStageEntities[]) {
    if (entities.length === 0) {
      this.gettedIgnoredEntities = [];
      this.entities = [];
      this.entitiesSelected = [];
      this.ignoredEntities = [];
    }
    if (
      entities.length > 0 &&
      JSON.stringify(this.entities) !== JSON.stringify(entities)
    ) {
      this.entities = entities;
      this.entitiesSelected = entities;
    }
  }

  public async triggersSelectedsHasCompaniesAssociated(): Promise<boolean> {
    const triggerForGetEntities = this.triggersPayload.map((item) => ({
      triggerReferenceId: item.triggerReferenceId,
      triggerTypeId: item.triggerTypeId,
    }));

    await this.getEntitiesTriggerModal(triggerForGetEntities);

    return this.entities.length > 0;
  }

  mapChecklistStage(item: ChecklistStage) {
    const task = new ChecklistTask();
    task.id = item.id;
    task.checklistStageKey = item.checklistStageKey;
    task.checklistKey = item.checklistKey.toString();
    task.name = item.name;
    task.teamSelected = this.teams.find(
      (x) => x.name === item.teamKey
    ) as ChecklistTeams;
    task.isEvidence = item.isEvidence.toString();
    task.estimatedDays = item.estimatedDays.toString();
    task.comment = item.comment;
    const predecessor = this.gettedStages.find(
      (x) => x.checklistStageKey === item.predecessorChecklistStageKey
    ) as ChecklistStage;
    if (predecessor !== null && predecessor !== undefined) {
      task.predecessorName = predecessor.name;
    }
    task.userUpdatedLogin = this.user.email;
    // task.deadlineDate = item.realDeadLine.toString();
    return task;
  }

  setObtainedPredecessors() {
    const predecessor = this.tasks.forEach(
      (x) =>
        (x.predecessorSelected = this.predecessor.find(
          (y) => y.name === x.predecessorName
        ) as Predecessor)
    );
  }

  checklistTriggerToTriggerPayload(item: ChecklistTrigger): TriggerPayload {
    return {
      id: item.id,
      checklistTriggerKey: item.checklistTriggerKey,
      checklistKey: item.checklistKey,
      triggerTypeId: item.triggerTypeId,
      triggerReferenceId: item.triggerReferenceId,
      userUpdatedLogin: this.user.email,
      isDeleted: false,
    };
  }

  addTriggerToMapTrigger(trigger: TriggerPayload, mapTriggers: MapTriggers) {
    mapTriggers[trigger.triggerTypeId] = mapTriggers[trigger.triggerTypeId]
      ? [...mapTriggers[trigger.triggerTypeId], trigger]
      : [trigger];
  }

  setChecklistTriggers(trigger: TriggerPayload) {
    if (trigger.triggerTypeId === 1) {
      this.entityTriggerSelected.push(trigger.triggerReferenceId);
    }
    if (trigger.triggerTypeId === 2) {
      this.entityTypeTriggerSelected.push(trigger.triggerReferenceId);
    }
    if (trigger.triggerTypeId === 3) {
      this.formOrganizationTriggerSelected.push(trigger.triggerReferenceId);
    }
    if (trigger.triggerTypeId === 4) {
      this.regulatoryLicenseTriggerSelected.push(trigger.triggerReferenceId);
    }
    if (trigger.triggerTypeId === 5) {
      this.jurisdictionsTriggerSelected.push(trigger.triggerReferenceId);
    }
    if (trigger.triggerTypeId === 6) {
      this.openCloseTriggerSelected.push(trigger.triggerReferenceId);
    }
    if (trigger.triggerTypeId === 7) {
      this.entityBrasilTriggerSelected.push(trigger.triggerReferenceId);
    }

    this.triggersPayload.push(trigger);
  }

  //Ao adicionar uma tarefa
  addTask() {
    this.tasks.push(new ChecklistTask());
    if (this.tasks.length > 1) {
      this.createListOfPredecessors();
    }
  }

  //Ao modificar o nome de uma tarefa
  remakeListOfPredecessors() {
    this.predecessor = [];
    this.createListOfPredecessors();
  }

  //Ao remover uma tarefa
  removeTask(task: ChecklistTask) {
    this.tasks.splice(this.tasks.indexOf(task), 1);
    this.remakeListOfPredecessors();
  }

  createListOfPredecessors() {
    const localPredecessor: Predecessor[] = [];

    for (let i = 0; i < this.tasks.length; i++) {
      if (this.tasks[i].name !== '') {
        const predecessor = this.createPredecessor(i, this.tasks[i].name);
        localPredecessor.push(predecessor);
      }
    }

    localPredecessor.pop();
    this.predecessor = localPredecessor;
  }

  createPredecessor(position: number, name: string) {
    const predecessor = new Predecessor();
    predecessor.position = position;
    predecessor.name = name;

    return predecessor;
  }

  beforeMount() {
    this.closeModal();
  }

  async mounted() {
    await this.getTriggersForList();
    await this.getTeams();
    if (this.$route.name === 'EditChecklist' || this.$route.name === 'DuplicateChecklist') {
      await this.getChecklistById();
      this.isEdited = this.$route.name === 'EditChecklist';
    } else {
      this.addTask();
    }
  }

  public intervalAnother() {
    const calendar = this.tasks.map((item) => item.estimatedDays);
    const calendarNumber = calendar.map((item) => parseInt(item));
    const total = calendarNumber.reduce(
      (total, currentElement) => total + currentElement
    );
    if (this.interval === 'Monthly' && total > 30) {
      this.setFeedback({
        feedback: true,
        color: 'red',
        text: 'The sum of task days can`t ultrapass the selected interval.',
      });
      setTimeout((): void => {
        this.setFeedback({
          feedback: false,
        });
      }, 6000);
      return;
    } else if (this.interval === 'Quarterly' && total > 90) {
      this.setFeedback({
        feedback: true,
        color: 'red',
        text: 'The sum of task days can`t ultrapass the selected interval.',
      });
      setTimeout((): void => {
        this.setFeedback({
          feedback: false,
        });
      }, 6000);
    } else if (this.interval === 'Semester' && total > 180) {
      this.setFeedback({
        feedback: true,
        color: 'red',
        text: 'The sum of task days can`t ultrapass the selected interval.',
      });
      setTimeout((): void => {
        this.setFeedback({
          feedback: false,
        });
      }, 6000);
    } else if (this.interval === 'Yearly' && total > 360) {
      this.setFeedback({
        feedback: true,
        color: 'red',
        text: 'The sum of task days can`t ultrapass the selected interval.',
      });
      setTimeout((): void => {
        this.setFeedback({
          feedback: false,
        });
      }, 6000);
    } else {
      return true;
    }
  }

  public async showSaveModal() {
    if (!this.form.validate()) {
      this.setFeedback({
        feedback: true,
        color: 'red',
        text: 'Fulfill all the required fields.',
      });
      setTimeout((): void => {
        this.setFeedback({
          feedback: false,
        });
      }, 6000);

      this.saveStatusId = 0;
      return;
    }
    this.intervalAnother();
    if (!this.intervalAnother()) {
      this.saveStatusId = 0;
      return;
    }

    this.setSaveModal(true);
  }

  public showSaveDraftModal() {
    if (!this.form.validate()) {
      this.setFeedback({
        feedback: true,
        color: 'red',
        text: 'Fulfill all the required fields.',
      });
      setTimeout((): void => {
        this.setFeedback({
          feedback: false,
        });
      }, 6000);
      return;
    }
    this.intervalAnother();
    if (!this.intervalAnother()) {
      return;
    }

    this.setSaveDraftModal(true);
  }

  public showEntitiesModal() {
    this.setEntitiesModal(true);
  }
}
